<template>
  <v-carousel v-model="active" :height="height">
    <v-carousel-item v-for="(slide, i) in slides" :key="`carousel-${i}`">
      <v-sheet v-if="slide.type === 'img'" height="100%">
        <v-img :src="slide.src" width="100%" height="100%"></v-img>
      </v-sheet>
      <v-sheet v-if="slide.type === 'video'" height="100%">
        <iframe
          width="100%"
          height="100%"
          :src="formatUrl(slide.src)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { formatEmbedUrl } from "../modules/helpers";

export default {
  name: "FlightCardCarousel",
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    formatUrl(url) {
      return formatEmbedUrl(url);
    },
  },
};
</script>

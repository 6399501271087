const getters = {
  /**
   * Returns all the attribute weights, for both directions, based on the current set of user preferences weights.
   *
   * @since v0.2
   *
   */
  parsedAttributeWeights: (state, rootGetters) => {
    let parsed = {};

    if (!state.profile || !state.profile.user) {
      return state.attributeWeights;
    }
    const userProfiles = rootGetters["profile/currentProfiles"];
    const ratingGroups = rootGetters["profile/ratingProfileGroups"];

    Object.entries(state.attributeWeights).forEach((i) => {
      let [direction, weights] = i;

      if (userProfiles && typeof userProfiles[direction] !== "undefined") {
        const currentProfileIndex = ratingGroups.findIndex(
          (i) => typeof i.id !== "undefined" && i.id === userProfiles[direction]
        );

        const currentProfile =
          userProfiles[direction] && state.profile.user && currentProfileIndex
            ? ratingGroups[currentProfileIndex]
            : null;

        Object.keys(weights).forEach((key) => {
          if (
            currentProfile &&
            typeof currentProfile.weights[key] !== "undefined" &&
            currentProfile.weights[key] !== -1
          ) {
            weights[key] = currentProfile.weights[key];
          } else {
            weights[key] = state.attributeWeights[direction][key];
          }
        });
      }

      parsed[direction] = weights;
    });

    return parsed;
  },
};

export default getters;

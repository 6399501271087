// import api from "../../api";
import api from "../api";
import {
  getFlightRating,
  getCabinRating,
  getOfferCabinRating,
} from "../../helpers";

const state = {
  origins: [
    {
      iata: "MIA",
      name: "Miami",
    },
    {
      iata: "NYC",
      name: "New York City",
    },
    {
      iata: "EWR",
      name: "Newark",
    },
  ],
  destinations: [
    {
      iata: "LON",
      name: "London",
    },
    {
      iata: "PAR",
      name: "Paris",
    },
  ],
  airlines: [
    {
      name: "American Airlines",
      iata: "AA",
    },
    {
      name: "British Airways",
      iata: "BA",
    },
    {
      name: "Virgin Atlantic",
      iata: "VS",
    },
    {
      name: "Finnair",
      iata: "AY",
    },
  ],
  classesOfService: [
    {
      name: "Economy",
      value: "economy",
    },
    {
      name: "Premium Economy",
      value: "premium_economy",
    },
    {
      name: "Business Class",
      value: "business",
    },
    {
      name: "First Class",
      value: "first",
    },
  ],
  flights: {
    departure: [],
    return: [],
  },
  baseline: {
    outbound: null,
    inbound: null,
  },
  loading: false,
  error: null,
};

const mutations = {
  SET_FLIGHTS_LOADING(state, val) {
    state.loading = val;
  },
  SET_FLIGHTS(state, payload) {
    state.flights[payload.step] = payload.flights;
  },
  SET_DEPARTURE_FLIGHT(state, flight) {
    state.baseline.outbound = flight;
  },
  SET_RETURN_FLIGHT(state, flight) {
    state.baseline.inbound = flight;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const getters = {
  currentStep: (state, getters, rootState) => {
    return rootState.route.query.departureFlight ? "return" : "departure";
  },
  /**
   * Returns list of flights for one of the route directions.
   *
   * @since v0.1
   *
   */
  currentFlights: (state, getters) => {
    return state.flights[getters.currentStep];
  },
  /**
   *
   * Returns all the flights for the current route direction, with system and user ratings.
   *
   * @since v0.2
   *
   */
  flightsWithRatings: (state, getters, rootState, rootGetters) => {
    if (
      !getters.currentFlights ||
      !rootState.origin ||
      !rootState.destination ||
      !rootState.departureDate ||
      !rootState.returnDate ||
      !rootState.flightClass
    ) {
      return [];
    }

    return getters.currentFlights.map((f) => {
      const ratings = getFlightRating(
        f,
        rootGetters.parsedAttributeWeights[getters.currentStep]
      );

      f.aircraft.cabin.rating = ratings.system;
      f.aircraft.cabin.userRating = ratings.user;

      // cabin rating
      f.aircraft.cabin.cabinRating = getCabinRating(
        f.aircraft.cabin.cabinClass
      );

      return f;
    });
  },
  /**
   * Given a departure and return flight selected previously, returns an object to simulate the current offer - or baseline Trip.
   *
   * @since v0.1
   *
   */
  baselineTrip: (state, getters, rootState, rootGetters) => {
    if (
      !state.baseline.outbound ||
      !state.baseline.inbound ||
      typeof state.baseline.outbound !== "object" ||
      typeof state.baseline.inbound !== "object"
    ) {
      return null;
    }

    const outbound = { ...state.baseline.outbound };
    const inbound = { ...state.baseline.inbound };
    // const departureClass = outbound.cabinClass;
    // const returnClass = inbound.cabinClass;
    const outboundRatings = getFlightRating(
      outbound,
      rootGetters.parsedAttributeWeights.departure
    );
    const inboundRatings = getFlightRating(
      inbound,
      rootGetters.parsedAttributeWeights.return
    );

    outbound.aircraft.cabin.rating = outboundRatings.system;
    outbound.aircraft.cabin.userRating = outboundRatings.user;

    inbound.aircraft.cabin.rating = inboundRatings.system;
    inbound.aircraft.cabin.userRating = inboundRatings.user;

    // cabin rating
    outbound.aircraft.cabin.cabinRating = getCabinRating(
      outbound.aircraft.cabin.cabinClass
    );
    inbound.aircraft.cabin.cabinRating = getCabinRating(
      inbound.aircraft.cabin.cabinClass
    );

    let cost;

    // if (outbound.cost) {
    //   cost =
    //     Number(outbound.cost.replace(/,/g, "")) +
    //     Number(inbound.cost.replace(/,/g, ""));
    // } else {
    //   cost = Math.random() * (5000 - 780) + 780;
    // }

    return {
      id: `${outbound.id}-${inbound.id}`,
      outbound: outbound,
      inbound: inbound,
      rating: Number(
        (
          (outbound.aircraft.cabin.rating + inbound.aircraft.cabin.rating) /
          2
        ).toFixed(2)
      ),
      userRating: Number(
        (
          (outbound.aircraft.cabin.userRating +
            inbound.aircraft.cabin.userRating) /
          2
        ).toFixed(2)
      ),
      cabinRating: getOfferCabinRating(
        outbound.aircraft.cabin.cabinClass,
        inbound.aircraft.cabin.cabinClass
      ),
      cost: outbound.cost + inbound.cost,
    };
  },

  /**
   * Returns current departure flight ID.
   *
   * @since v0.3
   *
   */
  outbound: (state, getters, rootState) => {
    if (rootState.route.query.outbound) {
      return rootState.route.query.outbound;
    } else {
      if (state.baseline.outbound !== "null") {
        return state.baseline.outbound.id;
      }
    }

    return null;
  },

  /**
   * Returns current return flight ID.
   *
   * @since v0.3
   *
   */
  inbound: (state, getters, rootState) => {
    if (rootState.route.query.inbound) {
      return rootState.route.query.inbound;
    } else {
      if (state.baseline.inbound !== "null") {
        return state.baseline.inbound.id;
      }
    }

    return null;
  },
};

const actions = {
  /**
   * Get departure or return flights and set the state.
   *
   * @since v0.1
   */
  getFlights({ commit, getters, rootState, rootGetters, dispatch }) {
    let data = {};
    commit("SET_FLIGHTS_LOADING", true);
    commit("SET_ERROR", null);

    // abort all current promises
    api.abortAll("flights");

    const originMap = {
      NYC: "JFK",
      MIA: "MIA",
      EWR: "EWR",
    };
    const destinationMap = {
      LON: "LHR",
      PAR: "CDG",
    };

    if (getters.currentStep === "departure") {
      data = {
        origin: originMap[rootState.origin],
        destination: destinationMap[rootState.destination],
        // destination: rootState.destination,
        departureDate: rootState.departureDate,
        returnDate: rootState.returnDate,
        airline: rootState.airline,
        flightClass: rootState.flightClass,
      };
    } else {
      data = {
        origin: destinationMap[rootState.destination],
        // origin: rootState.destination,
        destination: rootState.origin,
        departureDate: rootState.returnDate,
        outboundDate: rootState.departureDate,
        airline: rootState.airline,
        flightClass: rootState.flightClass,
      };
    }

    // this._vm.$gtm.trackEvent({
    //   event: "flightSearch",
    //   label: `Flight Search - ${getters.currentStep}`,
    //   ...data,
    //   direction: getters.currentStep
    // });

    data.userContext = rootGetters["profile/encodedContext"];

    // const endpoint = `/flights/${data.origin}/${data.destination}/${data.departureDate}/${data.airline}/${data.flightClass}`;

    api
      .getFlights(data)
      .then((response) => {
        console.log(response.data);
        dispatch(
          "logs/setLog",
          {
            type: "response",
            origin: "/flights",
            payload: {
              request: response.config.url,
              version: response.headers
                ? response.headers["ue-code-version"] || null
                : null,
              response: response.data,
              status: response.status,
            },
          },
          {
            root: true,
          }
        );
        commit("SET_FLIGHTS", {
          step: getters.currentStep,
          flights: response.data,
        });

        if (response.data.length === 0) {
          commit("SET_ERROR", {
            message: "Flights Not Found",
            description: "Change your search criteria and try again.",
          });
        } else {
          commit("SET_ERROR", null);
        }
      })
      .catch((error) => {
        console.log(error);
        commit("SET_ERROR", {
          code: error.response.status,
          message: error.response.data.message,
          description: "Change your search criteria and try again.",
        });
      })
      .then(() => {
        commit("SET_FLIGHTS_LOADING", false);
      });
  },
  /**
   * Get individual or multiple flight details.
   *
   * @param {Array} payload array of items containing uid and the expected mutation.
   */
  async getFlight({ commit, rootState, rootGetters, dispatch }, payload) {
    // console.log("getFlight", payload);
    const uids = payload.map((p) => p.uid);

    try {
      const response = await api.getFlight(
        uids,
        rootGetters["profile/encodedContext"]
      );

      // const classMap = {
      //   ECONOMY: "Y",
      //   ECONOMY_PLUS: "y+",
      //   PREMIUM_ECONOMY: "PE",
      //   BUSINESS: "J",
      //   FIRST: "F",
      // };

      // const amenitiesMap = {
      //   seatWidth: {
      //     name: "Seat Width",
      //     unit: "inches",
      //     value: null,
      //     rating: 0,
      //     key: "seatWidth",
      //   },
      //   seatPitch: {
      //     name: "Seat Legroom",
      //     unit: "inches",
      //     value: null,
      //     rating: 0,
      //     key: "seatPitch",
      //   },
      //   seatRecline: {
      //     name: "Seat Recline",
      //     value: null,
      //     rating: 0,
      //     key: "seatType",
      //   },
      //   seatPrivacy: {
      //     name: "Seat Privacy",
      //     value: null,
      //     rating: 0,
      //     key: "seatPrivacy",
      //   },
      // };

      dispatch(
        "logs/setLog",
        {
          type: "response",
          origin: "/flight",
          payload: {
            request: response.config.url,
            version: response.headers
              ? response.headers["ue-code-version"] || null
              : null,
            response: response.data,
            status: response.status,
          },
        },
        {
          root: true,
        }
      );

      // const seats = rootState.seats.seats;
      let parsed = response.data;

      // if (seats && seats.length > 0) {
      //   parsed = response.data.map((f) => {
      //     if (
      //       typeof f.aircraft !== "undefined" &&
      //       f.aircraft.cabin.amenities.length === 0
      //     ) {
      //       const flightClass = f.aircraft.cabin.cabinClass;
      //       const code =
      //         f.operating && f.operating.code
      //           ? f.operating.code
      //           : f.carrier.code;
      //       // const identifier = `${f.carrier.code}-${f.aircraft.code}-1-${classMap[flightClass]}`;
      //       // const identifier = "BA-777-1-Y";
      //       const relatedSeats = seats.filter((s) => {
      //         // console.log(s.id, code, classMap[flightClass]);
      //         return (
      //           s.id.indexOf(code) !== -1 &&
      //           s.id.indexOf(classMap[flightClass]) !== -1
      //         );
      //       });

      //       // console.log("relatedSeats", relatedSeats);

      //       if (relatedSeats.length > 0) {
      //         const seat = relatedSeats[0];

      //         f.aircraft.cabin.amenities = Object.keys(
      //           seat.ratings.perAttribute
      //         ).map((k) => {
      //           let base = amenitiesMap[k];
      //           const values = seat.ratings.perAttribute[k];
      //           base.value = values.value;
      //           base.rating = values.rating;

      //           return base;
      //         });
      //         f.aircraft.cabin.rating = seat.ratings.system;
      //         f.aircraft.cabin.pictures = seat.media.pictures || [
      //           "https://dl.airtable.com/.attachmentThumbnails/090d74b56f1d85e180beae7970dcdb03/622508af",
      //         ];
      //         f.aircraft.cabin.video = seat.media.video || null;

      //         f.aircraft.cabin.isPlaceholder = true;
      //       }
      //     }
      //     // f.cost =
      //     //   f.cost ||
      //     //   (
      //     //     Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000
      //     //   ).toLocaleString();

      //     return f;
      //   });
      // }

      payload.forEach((p) => {
        // console.log(p);
        commit(
          p.mutation,
          Array.isArray(parsed) ? parsed.find((d) => d.id === p.uid) : parsed
        );
      });
    } catch (error) {
      dispatch(
        "logs/setLog",
        {
          type: "response",
          origin: "/offers",
          version: error.headers
            ? error.headers["ue-code-version"] || null
            : null,
          payload: error.response,
        },
        {
          root: true,
        }
      );

      console.log(error);
    }
  },
  /**
   * Select a flight for the baseline departure or return.
   *
   * @param {Object} payload flight ID and direction.
   */
  selectFlight({ dispatch }, payload) {
    // const flight = getters.currentFlights.find((f) => f.id === payload.id);

    // const mutation =
    //   payload.direction === "departure"
    //     ? "SET_DEPARTURE_FLIGHT"
    //     : "SET_RETURN_FLIGHT";

    const flightPayload = [
      {
        uid: payload.id,
        mutation: payload.mutation,
      },
    ];

    dispatch("getFlight", flightPayload);

    // commit(mutation, flight);

    // this._vm.$gtm.trackEvent({
    //   event: "selectedFlight",
    //   label: `Flight Selected - ${getters.currentStep}`,
    //   origin: flight.departure.code,
    //   destination: flight.arrival.code,
    //   departureDate: flight.departure.at,
    //   flightNumber: flight.number,
    //   carrier: flight.carrier.code,
    //   operatingCarrier: flight.operating.code,
    //   flightCost: parseFloat(flight.cost.price),
    //   flightRating: parseFloat(flight.cost.rating),
    //   direction: getters.currentStep,
    // });
  },
  setoutbound({ commit }, payload) {
    commit("SET_DEPARTURE_FLIGHT", payload);
  },
  setinbound({ commit }, payload) {
    commit("SET_DEPARTURE_FLIGHT", payload);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};

// import api from "../api";
import ueApi from "./api";
// import router from "../router";

const actions = {
  setAttributeWeight({ commit }, payload) {
    commit("SET_ATTRIBUTE_WEIGHT", payload);
  },

  async setRatingPresets({ commit }) {
    const profiles = await ueApi.getRatingProfiles();

    if (profiles) {
      commit("SET_RATING_PRESETS", profiles.data);
    }
  },

  setMenuView({ commit }, view) {
    commit("SET_MENU_VIEW", view);
  },

  /**
   * Sync state variables based on router.
   *
   * @since v0.2.2
   *
   */
  async syncCurrentState({ commit, state, rootState }) {
    const query = rootState.route.query;

    // initial variables to get baseline
    const stateKeys = {
      origin: "SET_ORIGIN",
      destination: "SET_DESTINATION",
      departureDate: "SET_DEPARTURE_DATE",
      airline: "SET_AIRLINE",
      returnDate: "SET_RETURN_DATE",
      flightClass: "SET_FLIGHT_CLASS",
      departureFlight: "SET_DEPARTURE_FLIGHT",
      returnFlight: "SET_RETURN_FLIGHT",
      // quadrant: "SET_QUADRANT",
      offers: "offers/SET_SHORTLIST",
      currentSelectedOffer: "offers/SET_SELECTED_OFFER",
      leverValues: "offers/SET_LEVERS_VALUES",
    };

    const keyMap = {
      departureFlight: "outbound",
      returnFlight: "inbound",
    };

    let partialState = {
      selectedFlights: [],
      levers: { ...state.leversValues },
    };

    Object.entries(stateKeys).forEach(([key, mutation]) => {
      if (state[key] === query[key] && key !== "leverValues") {
        return;
      }

      if (
        key === "offers" &&
        rootState.offers.shortlist.length === 0 &&
        !query[key]
      ) {
        return;
      }

      if (key === "currentSelectedOffer" && !query.viewOffer) {
        commit("SET_OFFER_DIALOG", false);
      }

      if (["departureFlight", "returnFlight"].includes(key)) {
        if (rootState.flights.baseline[keyMap[key]] === null) {
          partialState.selectedFlights.push({
            uid: query[key],
            mutation: mutation,
          });
          commit(`flights/${mutation}`, query[key]);
        }
      } else if (key === "leverValues") {
        const updates = [];
        Object.keys(partialState.levers).forEach((k) => {
          let values = query[k];
          if (partialState.levers[k].type === "boolean") {
            values = values === "true";
          }

          if (partialState.levers[k].type === "number") {
            values = parseInt(values);
          }

          if (
            typeof query[k] === "undefined" ||
            partialState.levers[k].value === values
          ) {
            return;
          }

          updates.push({ key: k, value: values });
        });
        if (updates.length > 0) {
          commit(mutation, updates);
        }
      } else if (key === "offers") {
        const values = Array.isArray(query[key]) ? query[key] : [query[key]];
        commit(mutation, values);
      } else {
        commit(mutation, query[key]);
      }
    });

    return partialState;
  },

  async setCurrentState({
    commit,
    state,
    rootState,
    getters,
    rootGetters,
    dispatch,
  }) {
    const query = rootState.route.query,
      currentName = rootState.route.name;

    const partialState = await dispatch("syncCurrentState");

    // if current route is the home, check if step is still looking for a departure flight or not
    if (currentName === "home") {
      // if current route is the home, and all search parameters are set, then get flights for current direction
      if (
        state.origin &&
        state.destination &&
        state.departureDate &&
        state.returnDate &&
        state.airline &&
        state.flightClass
      ) {
        if (!query.departureFlight) {
          commit("SET_STEP", "departure");
        } else if (query.departureFlight && !query.returnFlight) {
          commit("SET_STEP", "return");
        }
        dispatch("flights/getFlights");
        commit("offers/SET_OFFERS", []);
        commit("filters/SET_UPGRADE_COSTS", null);
      }
    }

    // if we have some flights ids already set, then let's get the data for them
    if (
      partialState.selectedFlights &&
      partialState.selectedFlights.length > 0
    ) {
      if (!getters["flights/baselineTrip"]) {
        await dispatch("seats/getSeats");
        await dispatch("flights/getFlight", partialState.selectedFlights);
      }
    }

    // console.log("currentName", currentName);

    if (
      ["ratings", "offers"].includes(currentName) &&
      (rootState.filters.airlines.options.length === 0 ||
        rootState.filters.alliances.options.length === 0 ||
        rootState.filters.destinations.options.length === 0)
    ) {
      await dispatch("filters/getAllFilters");
    }

    // if we are on the offers route, set current step, get offers, and populate shortlist if we have it
    if (["ratings", "offers"].includes(currentName)) {
      commit("SET_STEP", "offers");

      if (rootState.offers.offers.length === 0) {
        dispatch("offers/getOffers").then(() => {
          if (
            query.offers &&
            query.offers.length > 0 &&
            rootState.offers.shortlist.length === 0
          ) {
            const shortlist = rootGetters[
              "offers/offersWithRatings"
            ].filter((o) => rootState.offers.shortlist.includes(o.id));
            commit(
              "SET_SHORTLIST",
              shortlist.map((o) => o.id)
            );
          }
          // dispatch("getLevers");
        });
      }
    }

    // if we are on a shortlist comparison route, set the step to shortlist, get all offers, and set the selected offers
    if (currentName === "shortlist" && state.step !== "shortlist") {
      commit("SET_STEP", "shortlist");

      if (rootState.offers.offers.length === 0) {
        dispatch("offers/getOffers").then(() => {
          if (
            query.offers &&
            query.offers.length > 0 &&
            rootState.offers.shortlist.length === 0
          ) {
            const shortlist = rootGetters[
              "offers/offersWithRatings"
            ].filter((o) => rootState.offers.shortlist.includes(o.id));
            commit(
              "SET_SHORTLIST",
              shortlist.map((o) => o.id)
            );
          }
        });
      }
      if (
        !rootState.offers.selectedOffers ||
        rootState.offers.selectedOffers.length === 0
      ) {
        dispatch("offers/getSelectedOffers");
      }
    }

    if (!rootState.offers.currentSelectedOffer && query.viewOffer) {
      dispatch("offers/viewOfferDetails", query.viewOffer);
    }
  },
};

export default actions;

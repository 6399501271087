<template>
  <div class="radar-chart-container">
    <svg
      v-if="dataset"
      ref="chart"
      class="radar-chart"
      :width="width"
      :height="height"
    >
      <g
        class="group"
        :transform="`translate(${w / 2 + cfg.margin.left},${
          h / 2 + cfg.margin.top
        })`"
      >
        <g class="axisWrapper">
          <circle
            v-for="(circle, i) in circles"
            :key="`circle-${i}`"
            class="grid-circle"
            :r="circle.r"
            fill="transparent"
            :stroke="circle.stroke"
          ></circle>
        </g>
        <g class="bg-axis">
          <rect
            v-for="(axis, i) in circleAxis"
            :key="`bg-axis-${i}`"
            :class="`legend-box legend-box-${i}`"
            fill="#E6E6E6"
          ></rect>
        </g>
        <g v-for="(axis, i) in circleAxis" :key="`axis-${i}`" class="axis">
          <line
            :x1="axis.line.x1"
            :x2="axis.line.x2"
            :y1="axis.line.y1"
            :y2="axis.line.y2"
            class="line"
            stroke-width="1"
            stroke-dasharray="4"
            stroke="#000"
          ></line>
          <text
            v-if="showLabels"
            :class="`legend legend-${i}`"
            font-size="11px"
            text-anchor="middle"
            :x="axis.legend.x"
            :y="axis.legend.y"
          >
            {{ axis.legend.text }}
          </text>
        </g>
        <g class="polygonWrapper">
          <path
            v-for="(path, i) in polygonPaths"
            :key="`polygon-${i}`"
            :d="path.path"
            :fill="path.fill"
            :stroke="path.fill"
            stroke-width="2"
            fill-opacity=".2"
          ></path>
          <circle
            v-for="(circle, i) in polygonCircles"
            :key="`polygon-circle-${i}`"
            class="polygonCircle"
            :class="`circle-${circle.id}`"
            :cx="circle.cx"
            :cy="circle.cy"
            :r="circle.r"
            :fill="circle.fill"
            @mouseover="displayTooltip(circle)"
            @mouseleave="hideTooltip"
          ></circle>
        </g>
      </g>
    </svg>
    <div v-if="showTooltip" ref="tooltip" class="radar-tooltip">
      <v-sheet
        v-if="currentHover"
        elevation="4"
        color="grey darken-1"
        class="pa-1"
        dark
      >
        <strong class="text-caption">{{ currentHover.label }}</strong>
        <p class="text-small mb-0">{{ currentHover.value }}</p>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { generateHslaColors } from "../modules/helpers";
import { createPopper } from "@popperjs/core";

export default {
  name: "OfferRadarChart",
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
    w: {
      type: Number,
      default: 200,
    },
    h: {
      type: Number,
      default: 200,
    },
    margin: {
      type: Number,
      default: 80,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      config: {
        margin: {
          top: this.margin,
          right: this.margin,
          bottom: this.margin,
          left: this.margin,
        }, //The margins of the SVG
        levels: 5, //How many levels or inner circles should there be drawn
        maxValue: 5, //What is the value that the biggest circle will represent
        labelFactor: 1.4, //How much farther than the radius of the outer circle should the labels be placed
        wrapWidth: 40, //The number of pixels after which a label needs to be given a new line
        wrapPadding: 4, // The amount of padding on text wraps
        opacityArea: 0.35, //The opacity of the area of the blob
        dotRadius: 4, //The size of the colored circles of each blog
        opacityCircles: 0.1, //The opacity of the circles of each blob
        strokeWidth: 2, //The width of the stroke around each blob
        roundStrokes: false, //If true the area and stroke will follow a round path (cardinal-closed)
        // color: d3.scale.category10(), //Color function
      },
      currentHover: null,
    };
  },
  computed: {
    width() {
      return this.w + this.cfg.margin.left + this.cfg.margin.right;
    },
    height() {
      return this.h + this.cfg.margin.top + this.cfg.margin.bottom;
    },
    cfg() {
      return this.config;
      // //Put all of the options into a variable called cfg
      // if ("undefined" !== typeof options) {
      //   for (var i in options) {
      //     if ("undefined" !== typeof options[i]) {
      //       cfg[i] = options[i];
      //     }
      //   } //for i
      // } //if
    },
    //If the supplied maxValue is smaller than the actual one, replace by the max in the data
    maxValue() {
      if (!this.dataset || this.dataset.length == 0) {
        return this.cfg.maxValue;
      }
      return Math.max(
        this.cfg.maxValue,
        d3.max(this.dataset, (i) => {
          return d3.max(i.map((o) => o.value));
        })
      );
    },
    colors() {
      if (!this.dataset) {
        return null;
      }

      return generateHslaColors(40, 50, 1, this.dataset.length);
    },
    allAxis() {
      if (!this.dataset) {
        return null;
      }
      return this.dataset[0].map((i) => i.label);
    },
    totalAxis() {
      if (!this.dataset) {
        return null;
      }

      return this.allAxis.length;
    },
    radius() {
      return Math.min(this.w / 2, this.h / 2);
    },
    format() {
      return d3.format("%");
    },
    angleSlice() {
      if (!this.totalAxis) {
        return null;
      }

      return (Math.PI * 2) / this.totalAxis;
    },
    rScale() {
      if (!this.maxValue) {
        return null;
      }
      return d3.scaleLinear().range([0, this.radius]).domain([0, 5]);
    },
    circles() {
      const levels = d3.range(1, this.cfg.levels + 1).reverse();

      return levels.map((d) => {
        return {
          r: (this.radius / this.cfg.levels) * d,
          fill: "#CDCDCD",
          stroke: "#CDCDCD",
          fillOpacity: this.cfg.opacityCircles,
        };
      });
    },
    circleAxis() {
      if (!this.allAxis) {
        return null;
      }
      return this.allAxis.map((a, i) => {
        return {
          line: {
            x1: 0,
            y1: 0,
            x2:
              this.rScale(this.maxValue * 1.1) *
              Math.cos(this.angleSlice * i - Math.PI / 2),
            y2:
              this.rScale(this.maxValue * 1.1) *
              Math.sin(this.angleSlice * i - Math.PI / 2),
          },
          legend: {
            x:
              this.rScale(this.maxValue * this.cfg.labelFactor) *
              Math.cos(this.angleSlice * i - Math.PI / 2),
            y:
              this.rScale(this.maxValue * this.cfg.labelFactor) *
              Math.sin(this.angleSlice * i - Math.PI / 2),
            dy: "0.35em",
            text: a,
          },
        };
      });
    },
    radarLines() {
      const line = d3
        .lineRadial()
        .radius((d) => {
          return this.rScale(d.value);
        })
        .angle((d, i) => {
          return i * this.angleSlice;
        });

      line.curve(d3.curveLinearClosed);
      return line;
    },
    polygonPaths() {
      return this.dataset.map((d, i) => {
        return {
          path: this.radarLines(d),
          fill: d[0].color || this.colors[i],
        };
      });
    },
    polygonCircles() {
      const dots = [];

      this.dataset.forEach((t, i) => {
        Object.keys(t).forEach((k) => {
          const dot = {
            id: `${k}-${i}`,
            value: t[k].value,
            label: t[k].label,
            color: t[k].color || this.colors[i],
          };

          dots.push(dot);
        });
      });

      return dots.map((d, i) => {
        return {
          r: this.cfg.dotRadius,
          cx:
            this.rScale(d.value) * Math.cos(this.angleSlice * i - Math.PI / 2),
          cy:
            this.rScale(d.value) * Math.sin(this.angleSlice * i - Math.PI / 2),
          fill: d.color,
          label: d.label,
          value: d.value,
          id: d.id,
        };
      });
    },
  },
  watch: {
    circleAxis() {
      if (this.circleAxis) {
        this.$nextTick(() => {
          this.wrap();
        });
      }
    },
  },
  mounted() {
    if (this.showLabels) {
      this.$nextTick(() => {
        this.wrap();
      });
    }
  },
  methods: {
    displayTooltip(d) {
      if (this.displayTooltip) {
        this.currentHover = d;
        this.setPopover();
      }
    },
    hideTooltip() {
      this.currentHover = null;
    },
    setPopover() {
      if (this.popper) {
        this.popper.destroy();
      }
      this.$nextTick(() => {
        const dot = document.getElementsByClassName(
          `circle-${this.currentHover.id}`
        );
        this.popper = createPopper(dot[0], this.$refs.tooltip, {
          placement: "top",
          strategy: "fixed",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 12],
              },
            },
          ],
        });
      });
    },
    wrap() {
      if (!this.circleAxis) {
        return null;
      }
      const padding = this.cfg.wrapPadding,
        width = this.cfg.wrapWidth;

      this.circleAxis.forEach((c, i) => {
        const text = d3.select(`.legend-${i}`);
        let words = c.legend.text
            .split(/\s+/)
            .filter((t) => t !== "")
            .reverse(),
          line = [],
          lineNumber = 0,
          // lineHeight = 1, // ems
          y = text.attr("y"),
          x = text.attr("x"),
          dy = 1,
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");

        words.forEach((word, i) => {
          // console.log("word", word, i);
          if (word === "") {
            return;
          }
          line.push(word);
          tspan.text(line.join(" "));
          console.log("line", line);

          const dist = dy * lineNumber;
          // console.log("size", tspan.node().getComputedTextLength(), width);
          if (tspan.node().getComputedTextLength() > width && i > 0) {
            line.pop();
            // console.log("need to break", line, lineNumber, dist);
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", x)
              .attr("y", y)
              .attr("dy", dist + "em")
              .text(word);
            lineNumber++;
          }
        });

        text.selectAll("tspan").attr("x", x);
        const box = text.node().getBBox();
        d3.select(`.legend-box-${i}`)
          .attr("width", box.width + padding * 2)
          .attr("height", box.height + padding * 2)
          .attr("y", y)
          .attr("x", x)
          .attr("transform", function () {
            return `translate(-${
              box.width / 2 + padding
            }, -${box.height / 2 + padding / 2})`;
          });
      });
    },
  },
};
</script>

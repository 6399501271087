<template>
  <div class="d-flex flex-column">
    <v-list>
      <v-list-item>
        <v-list-item-title class="title">Rating Profiles</v-list-item-title>
        <v-btn transparent icon @click="$emit('close')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <rating-profiles></rating-profiles>
  </div>
</template>

<script>
import RatingProfiles from "./RatingProfiles";
export default {
  name: "RatingProfilesMenu",
  components: {
    RatingProfiles
  },
  data() {
    return {
      showMenu: false
    };
  }
};
</script>

import api from "../api";
import emitter from "../../emitter";

const state = {
  isKbOpen: false,
  currentArticle: null,
  list: [],
  articleCache: {},
  error: null,
  loading: false
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_KB_STATE: (state, isOpen) => {
    state.isKbOpen = isOpen;
  },
  SET_CURRENT_ARTICLE: (state, article) => {
    state.currentArticle = article;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_ARTICLE_CACHE: (state, article) => {
    if (typeof state.articleCache[article.id] === "undefined") {
      state.articleCache[article.id] = article;
    }
  }
};

const actions = {
  async getArchive({ state, commit }) {
    commit("SET_LOADING", true);
    try {
      const archive = await api.getKbArchive();
      commit("SET_LIST", archive.data);
      commit("SET_LOADING", false);
    } catch (err) {
      commit("SET_ERROR", err);
      commit("SET_LOADING", false);
    }
  },
  async getArticle({ state, commit }, id) {
    commit("SET_LOADING", true);
    try {
      if (typeof state.articleCache[id] === "undefined") {
        const article = await api.getKbArticle(id);
        commit("SET_ARTICLE_CACHE", article.data);
      }

      commit("SET_CURRENT_ARTICLE", state.articleCache[id]);
      commit("SET_LOADING", false);
    } catch (err) {
      commit("SET_ERROR", err);
      commit("SET_LOADING", false);
    }
  },
  toggleOpen({ commit }, isOpen) {
    commit("SET_KB_STATE", isOpen);
  },
  clearCurrentArticle({ commit }) {
    commit("SET_CURRENT_ARTICLE", null);
  }
};

window.ueGetKbArticle = function(id) {
  emitter.emit("kb/getArticle", id);
};

export default {
  state,
  mutations,
  actions
};

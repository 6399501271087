<template>
  <div>
    <v-range-slider
      v-model="currentLever"
      :min="currentLeverDetails.range.min"
      :max="currentLeverDetails.range.max"
      :thumb-size="40"
      thumb-label
      hide-details
      dense
    >
      <template #thumb-label="{ value }"> ${{ Math.round(value) }} </template>
    </v-range-slider>
    <v-row class="d-flex flex-nowrap no-gutters" style="margin-top: -16px">
      <v-col class="text-left">
        <span class="text-caption">{{
          getCostString(currentLeverDetails.range.min)
        }}</span>
      </v-col>
      <v-col class="text-right">
        <span class="text-caption">{{
          getCostString(currentLeverDetails.range.max)
        }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "FilterRange",
  props: {
    leverKey: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentLeverDetails() {
      if (!this.filtersWithCosts) {
        return null;
      }
      return this.filtersWithCosts[this.leverKey];
    },
    currentLever: {
      get() {
        if (!this.currentLeverDetails) {
          return null;
        }
        return this.currentLeverDetails.selected;
      },
      set(range) {
        console.log("range", range);

        this.setFilter([
          {
            key: this.leverKey,
            value: range,
          },
        ]);
      },
    },
    ...mapState("flags", ["hideSignals"]),
    ...mapGetters("filters", ["filtersWithCosts"]),
  },
  methods: {
    ...mapActions("filters", ["setFilter"]),
    getTickers(lever) {
      return lever.options.map((o) => o.label);
    },
    getCostString(cost) {
      return cost < 0
        ? `-$${Math.floor(Math.abs(cost))}`
        : `$${Math.ceil(cost)}`;
    },
    getAlignClass(index) {
      if (index === 0) {
        return "text-left";
      }
      if (index > 0 && index < this.currentLeverDetails.options.length - 1) {
        return "text-center";
      }
      if (index === this.currentLeverDetails.options.length - 1) {
        return "text-right";
      }
    },
  },
};
</script>

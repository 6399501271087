import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./components/App";
import router from "./modules/router";
import store from "./modules/store";
// import VueGtm from "vue-gtm";
import { sync } from "vuex-router-sync";
import Sticky from "vue-sticky-directive";
Vue.use(Sticky);

// Vue.use(VueGtm, {
//   id: "GTM-572HRZC",
//   // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
//   //   gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
//   //   gtm_preview:'env-4',
//   //   gtm_cookies_win:'x'
//   // },
//   defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: false, // Whether or not display console logs debugs (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
//   trackOnNextTick: true // Whether or not call trackView in Vue.nextTick
// });

const unsync = sync(store, router);

new Vue({ el: "#app", vuetify, router, store, render: (h) => h(App) });

<template>
  <div class="d-flex flex-column">
    <v-list>
      <v-list-item>
        <v-list-item-title class="title">Knowledge Base</v-list-item-title>
        <v-btn transparent icon @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-row
      v-if="loading"
      class="d-flex align-center justify-center"
      no-gutters
      style="height: calc(100vh - 65px); overflow-y: auto"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
    </v-row>
    <v-list v-if="!currentArticle && !loading">
      <v-list-group v-for="(cat, i) in list" :key="`kb-cat-${i}`" :value="true">
        <template #activator>
          <v-list-item-title>{{ cat.name }}</v-list-item-title>
        </template>
        <v-list-item
          v-for="article in cat.articles"
          :key="`kb-article-${article.id}`"
          link
          @click="getArticle(article.id)"
        >
          <v-list-item-title>{{ article.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-sheet
      v-if="currentArticle && !loading"
      style="height: calc(100vh - 65px); overflow-y: auto"
    >
      <v-row class="full-height" no-gutters>
        <v-col class="pa-6">
          <v-btn plain elevation="0" @click="clearCurrentArticle"
            ><v-icon>mdi-arrow-left</v-icon> Back</v-btn
          >
          <h3 class="mb-6 mt-6 text-h5" v-html="currentArticle.title"></h3>
          <div
            ref="articleContent"
            style="font-size: 14px"
            class="mb-6"
            v-html="currentArticle.content[0]"
          ></div>
          <v-divider></v-divider>
          <div
            v-if="currentArticle.related && currentArticle.related.length > 0"
            class="pt-4"
          >
            <h4 class="text-h6 mb-4">Related:</h4>
            <ul>
              <li
                v-for="article in currentArticle.related"
                :key="`related-${article.id}`"
              >
                <a href="javascript:void(0)" @click="getArticle(article.id)">{{
                  article.title
                }}</a>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "KnowledgeBase",
  emits: ["close"],
  computed: {
    ...mapState("kb", ["list", "isKbOpen", "currentArticle", "loading"]),
  },
  created() {
    this.getArchive();

    document.body.addEventListener("click", (event) => {
      if (
        typeof event.target.dataset !== "undefined" &&
        typeof event.target.dataset.article !== "undefined" &&
        event.target.tagName.toLowerCase() === "a"
      ) {
        this.getArticle(Number(event.target.dataset.article));
      }
    });
  },
  methods: {
    ...mapActions("kb", [
      "toggleOpen",
      "getArticle",
      "clearCurrentArticle",
      "getArchive",
    ]),
    close() {
      this.clearCurrentArticle();
      this.$emit("close");
    },
  },
};
</script>

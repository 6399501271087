<template>
  <div>
    <v-navigation-drawer v-model="drawer" app width="240px">
      <v-list>
        <v-list-item class="gray-lighten-4">
          <button
            @click="goToHome"
            class="d-block black--text text-left"
            style="text-decoration: none; width: 100%"
          >
            <div class="title black--text">Upgrade Engine</div>
            <v-chip small color="black" dark>V{{ appVersion }}</v-chip>
          </button>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list v-if="user" dense>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <img :src="user.picture" :alt="user.name" />
          </v-list-item-avatar>
          <v-list-item-content v-if="userSubscription">
            <v-list-item-title class="text-subtitle-1">{{
              userFullName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            <div>
              <strong v-if="userSubscription" class="text-overline">{{
                userRole
              }}</strong>
              <v-chip
                v-if="userSubscription && userSubscription.state"
                small
                badge
                color="primary"
                >{{ userSubscription.state }}</v-chip
              >
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item v-if="user" to="/profile">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group :value="true" prepend-icon="mdi-airplane" no-action>
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>Upgrade</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(step, key) in parsedSteps"
            :key="step.label"
            :to="{
              path: step.path,
              query: step.query,
            }"
            :disabled="step.isDisabled"
          >
            <v-list-item-content>
              <v-list-item-title
                >{{ key + 1 }}. {{ step.label }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="
            isAuthenticated &&
            ['summary', 'offers', 'shortlist'].includes(pathName)
          "
          link
          @click="setMenuView('ratings')"
        >
          <v-list-item-icon>
            <v-icon>mdi-seat</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Rating Profiles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/seats">
          <v-list-item-icon>
            <v-icon>mdi-seat</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Seats Archive</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="setMenuView('kb')">
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Knowledge Base</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template #append>
        <div class="pa-4">
          <v-btn
            v-if="!isAuthenticated"
            color="gray-darken-4"
            block
            dark
            @click="login"
          >
            Log in
          </v-btn>
          <v-btn
            v-if="isAuthenticated"
            color="gray-darken-4"
            dark
            block
            @click="logoutUser"
          >
            Log out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="currentMenuView"
      permanent
      fixed
      style="left: 240px; width: 350px; z-index: 20"
    >
      <knowledge-base
        v-if="currentMenuView === 'kb'"
        @close="setMenuView(null)"
      ></knowledge-base>
      <rating-profiles-menu
        v-if="currentMenuView === 'ratings'"
        @close="setMenuView(null)"
      ></rating-profiles-menu>
    </v-navigation-drawer>
  </div>
</template>

<script>
import RatingProfilesMenu from "./RatingProfilesMenu";
import KnowledgeBase from "./KnowledgeBase";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "AppBar",
  components: {
    RatingProfilesMenu,
    KnowledgeBase,
  },
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    ...mapState(["currentMenuView", "steps", "appVersion"]),
    ...mapState("route", {
      pathName: (state) => state.name,
      query: (state) => state.query,
      path: (state) => state.path,
    }),
    ...mapState("session", ["isAuthenticated", "authLoading", "user"]),
    ...mapState("profile", ["user"]),
    ...mapGetters("profile", ["userFullName", "userSubscription", "userRole"]),
    stepKeys() {
      return this.steps.map((s) => s.path);
    },
    currentStepIndex() {
      return this.stepKeys.indexOf(this.path);
    },
    parsedSteps() {
      const queryKeys = Object.keys(this.query);
      return this.steps.map((step) => {
        let parsed = { ...step };
        const stepParams =
          typeof step.allowedParams !== "undefined"
            ? step.allowedParams
            : step.requiredParams;

        const missingParams = step.requiredParams.reduce((prev, current) => {
          if (!queryKeys.includes(current)) {
            prev.push(current);
          }
          return prev;
        }, []);

        const allowed = stepParams.reduce((prev, current) => {
          if (queryKeys.includes(current)) {
            prev[current] = this.query[current];
          }
          return prev;
        }, {});

        parsed.isDisabled =
          this.stepKeys.indexOf(parsed.path) > this.currentStepIndex &&
          missingParams.length > 0;

        parsed.query = allowed;

        return parsed;
      });
    },
  },
  methods: {
    ...mapActions(["setMenuView"]),
    ...mapActions("session", ["initializeAuth", "loginWithPopup", "logout"]),
    login() {
      this.loginWithPopup();
    },
    logoutUser() {
      this.logout({
        returnTo: window.location.origin,
      });
      this.$router.push("/");
    },
    goToHome() {
      this.$router.push("/search");
      this.$store.commit("flights/SET_FLIGHTS", {
        step: "departure",
        flights: [],
      });
      this.$store.commit("flights/SET_FLIGHTS", {
        step: "return",
        flights: [],
      });
      this.$store.commit("flights/SET_DEPARTURE_FLIGHT", null);
      this.$store.commit("flights/SET_RETURN_FLIGHT", null);
      this.$store.commit("offers/SET_OFFERS", []);
    },
  },
  // created() {
  //   this.initializeAuth();
  // }
};
</script>

// import auth0 from "auth0-js";
import createAuth0Client from "@auth0/auth0-spa-js";

// Import the Auth0 configuration
import { domain, clientId, audience } from "./auth_config.json";

const redirectUri = `${window.location.origin}/auth`;

let instance;

const auth0Client = {
  async init() {
    if (instance) return instance;

    instance = await createAuth0Client({
      domain: domain,
      client_id: clientId,
      audience: audience,
      redirect_uri: redirectUri,
      // scope: "openid email",
      response_type: "token id_token",
      cacheLocation: "localstorage",
    });

    return instance;
  },

  async login(isSignup) {
    let params = {
      redirect_uri: window.location.origin,
      audience: audience,
      // scope: "openid email",
      popupOptions: {
        width: 400,
        height: 720,
      },
    };

    if (isSignup) {
      params.prompt = "login";
      params.screen_hint = "signup";
    }

    return instance.loginWithPopup(params);
  },

  async logout() {
    instance.logout({
      returnTo: window.location.origin,
    });
  },

  async handleCallback() {
    const { appState } = await instance.handleRedirectCallback();
    return appState;
  },

  async isAuthenticated() {
    return await instance.isAuthenticated();
  },

  async getApiToken() {
    if (!(await instance.isAuthenticated())) {
      const token = import.meta.env.VITE_UE_API_PUBLIC_TOKEN;
      return token;
    }

    const opts = {
      audience: audience,
      // scope: "openid email edit:rating_profile",
      redirect_uri: window.location.origin,
    };
    try {
      const token = await instance.getTokenSilently(opts);
      // console.log(token);
      return token;
    } catch (e) {
      console.log(e);
    }
  },
};

export default auth0Client;

import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../components/LandingPage";
import Login from "../components/Login";
import Home from "../components/Home";
import RatingProfilesStep from "../components/RatingProfilesStep";
// import UpgradeSummary from "../components/UpgradeSummary";
import OffersList from "../components/OffersList";
import CompareOffers from "../components/CompareOffers";
import Profile from "../components/Profile";
import AuthRedirect from "../components/AuthRedirect";
import SeatsArchive from "../components/Seats/SeatsArchive";
// import Onboarding from "../components/Onboarding";
import store from "./store";
import qs from "qs";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "lp",
    component: Login,
  },
  {
    path: "/landingPage",
    beforeEnter : guardMyroute,
    name: "lp",
    component: LandingPage,
  },
  {
    path: "/search",
    beforeEnter : guardMyroute,
    name: "home",
    component: Home,
  },
  // {
  //   path: "/onboarding",
  //   name: "onboarding",
  //   component: Onboarding,
  //   meta: { requiresLogin: true }
  // },
  {
    path: "/auth",
    beforeEnter : guardMyroute,
    name: "auth",
    component: AuthRedirect,
    // meta: { requiresLogin: true }
  },
  {
    path: "/ratings",
    name: "ratings",
    beforeEnter : guardMyroute,
    component: RatingProfilesStep,
    // meta: { requiresLogin: true }
  },
  // {
  //   path: "/summary",
  //   name: "summary",
  //   component: UpgradeSummary,
  //   meta: { requiresLogin: true }
  // },
  {
    path: "/results",
    name: "offers",
    beforeEnter : guardMyroute,
    component: OffersList,
    // meta: { requiresLogin: true }
  },
  {
    path: "/shortlist",
    name: "shortlist",
    component: CompareOffers,
    // meta: { requiresLogin: true }
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { requiresLogin: true },
  },
  {
    path: "/seats/",
    name: "seats",
    component: SeatsArchive,
  },
  {
    path: "/seats/:id",
    name: "seatDetails",
    component: SeatsArchive,
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
  parseQuery(query) {
    return qs.parse(query, { comma: true });
  },
  stringifyQuery(query) {
    let flags;
    if (typeof query.flags !== "undefined") {
      flags = query.flags;
      delete query.flags;
    }
    const result = qs.stringify(query, { arrayFormat: "comma", encode: false });
    let string = "";

    if (result) {
      string = `?${result}`;
    }

    if (flags) {
      string += `&flags=${flags}`;
    }

    return string;
  },
});
function guardMyroute(to, from, next)
{
 var isAuthenticated= false;

if(localStorage.getItem('sessionExists'))
  isAuthenticated = true;
 else
  isAuthenticated= false;
 if(isAuthenticated)
 {
  next();
 }
 else
 {
  next('/');
 }
}
//guard clause
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresLogin) &&
    store.state.session.isAuthenticated == false
  ) {
    next("/");
  } else {
    next();
  }
});

// router.afterEach(route => {
//   console.log(route.query);
// });

export default router;

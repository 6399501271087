<template>
  <v-row align="stretch">
    <v-col
      v-for="(plan, k) in plans"
      :key="`plan-${plan.id}`"
      :md="12 / plans.length"
    >
      <v-card class="d-flex flex-column pa-0" style="height: 100%">
        <v-card-title class="pa-4">
          <h3 class="display-1 text--primary">
            {{ plan.name }}
          </h3>
        </v-card-title>
        <v-card-text style="height: 100%" class="pa-0">
          <v-list dense>
            <template v-for="(feature, i) in plan.features">
              <v-list-item :key="`plan-${k}-feat-${i}`">
                <p>{{ feature }}</p>
              </v-list-item>
              <v-divider :key="`plan-${k}-feat-${i}-divider`"></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions class="mt-a pa-4">
          <v-btn color="primary" block @click="$emit('selected', plan.id)">
            Sign up
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MembershipPlans",
  data() {
    return {
      plans: [
        {
          id: "free",
          name: "Free",
          features: ["Customize and save your own rating profiles"],
          cost: 0
        },
        {
          id: "silver",
          name: "Silver",
          features: [
            "Customize and save your own rating profiles",
            "Get results for mixed cabin offers"
          ],
          cost: 59
        },
        {
          id: "gold",
          name: "Gold",
          features: [
            "Customize and save your own rating profiles",
            "Get results for mixed cabin offers",
            "Get results for offers with First Class cabins"
          ],
          cost: 99
        }
      ]
    };
  }
};
</script>

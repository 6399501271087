<template>
  <div>
    <v-row v-if="formErrors">
      <v-col>
        <v-alert
          v-for="(error, k) in formErrors"
          :key="`error-${k}`"
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
          v-html="error"
        >
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="errors">
      <v-col>
        <v-alert
          v-for="(error, k) in errors"
          :key="`error-${k}`"
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
          v-html="error"
        >
        </v-alert>
      </v-col>
    </v-row>
    <form ref="recurlyForm" @submit.prevent="tokenize">
      <v-row>
        <v-col>
          <v-text-field
            disabled
            label="First Name"
            outlined
            :value="firstName"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            disabled
            outlined
            label="Last Name"
            :value="lastName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            disabled
            outlined
            label="Email"
            :value="email"
          ></v-text-field>
        </v-col>
        <v-col>
          <div
            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined"
          >
            <div class="v-input__control">
              <div class="v-input__slot">
                <fieldset aria-hidden="true">
                  <legend style="width: 64.5px;"><span>​</span></legend>
                </fieldset>
                <div class="v-text-field__slot">
                  <label
                    for="input-168"
                    class="v-label v-label--active v-label--is-disabled theme--light"
                    style="left: 0px; right: auto; position: absolute;"
                    >Zip Code</label
                  >
                  <input
                    v-model="zipCode"
                    type="text"
                    data-recurly="postal_code"
                    @change="setMeta('zip_code', $event)"
                  />
                </div>
              </div>
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper"></div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label for="recurly-elements">Credit Card</label>
          <div id="recurly-elements"></div>
          <input v-model="firstName" type="hidden" data-recurly="first_name" />
          <input v-model="lastName" type="hidden" data-recurly="last_name" />
          <input type="hidden" name="recurly-token" data-recurly="token" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block type="submit" color="primary" large>Buy</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert
            v-if="status"
            text
            prominent
            type="info"
            icon="mdi-cloud-alert"
            v-html="status"
          >
          </v-alert>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "JoinForm",
  props: {
    plan: {
      default: null
    }
  },
  data() {
    return {
      elements: null,
      formErrors: null,
      zipCode:
        this.user && this.user.user_metadata.zipCode
          ? this.user.user_metadata.zipCode
          : null
    };
  },
  computed: {
    ...mapState("profile", ["user"]),
    ...mapState("billing", ["status", "errors"]),
    firstName: {
      get() {
        return this.user && this.user.user_metadata.first_name
          ? this.user.user_metadata.first_name
          : null;
      },
      set(val) {
        this.$store.commit("profile/SET_USER_META", {
          first_name: val
        });
      }
    },
    lastName: {
      get() {
        return this.user && this.user.user_metadata.last_name
          ? this.user.user_metadata.last_name
          : null;
      },
      set(val) {
        this.$store.commit("profile/SET_USER_META", {
          last_name: val
        });
      }
    },
    email: {
      get() {
        return this.user && this.user.email ? this.user.email : null;
      }
      // set(val) {
      //   this.$store.commit("profile/SET_USER_META", {
      //     last_name: val
      //   });
      // }
    }
    // zipCode: {
    //   get() {
    //     console.log(this.user, this.user.user_metadata.zip_code);
    //     return this.user && this.user.user_metadata.zip_code
    //       ? this.user.user_metadata.zip_code
    //       : null;
    //   },
    //   set(val) {
    //     console.log("set", val);
    //     this.$store.commit("profile/SET_USER_META", {
    //       zip_code: val
    //     });
    //   }
    // }
  },
  methods: {
    setMeta(key, evt) {
      let obj = {};
      obj[key] = evt.target.value;
      this.$store.commit("profile/SET_USER_META", obj);
    },
    tokenize() {
      recurly.token(
        this.elements,
        this.$refs.recurlyForm,
        async (err, token) => {
          console.log(err, token);
          if (err) {
            if (err.code === "validation") {
              this.formErrors = err.details.map(
                e => `Credit Card ${e.field} ${e.messages.join(", and")}`
              );
            }
            if (err.code === "invalid-parameter") {
              this.formErrors = [err.message];
            }
          } else {
            this.$store.commit("billing/SET_TEMP_TOKEN", token);
            await this.purchase(this.plan);

            if (!this.errors) {
              this.$emit("success");
            }
          }
        }
      );
    },
    ...mapActions("billing", ["purchase"])
  },
  mounted() {
    recurly.configure("ewr1-w6dXn4Bv3tYUcVUkj6sOOT");
    this.elements = recurly.Elements();
    const cardElement = this.elements.CardElement({
      style: {
        fontSize: "1em",
        padding: "1em",
        fontFamily: "Roboto",
        placeholder: {
          color: "gray !important",
          content: {
            number: "Card number",
            cvv: "CVC"
          }
        },
        invalid: {
          fontColor: "red"
        }
      }
    });
    cardElement.attach("#recurly-elements");
  }
};
</script>

<style lang="scss">
body .recurly-element {
  height: 56px;
  width: 100%;
  border-color: rgba(0, 0, 0, 0.38);
}
</style>

import api from "../api";
import router from "../../router";

import {
  getFlightRating,
  getCabinRating,
  getOfferFlightRating,
  getOfferRating,
  getOfferCabinRating,
  getCostToUpgrade,
  compareToQuadrant,
  filterByQuadrant,
  getOfferQuadrant,
} from "../../helpers";

import { format, differenceInMinutes } from "date-fns";

const state = {
  viewType: "chart",
  // shouldApplyFilters: true,
  // groupCodesharedOffers: true,
  offers: [],
  offersEarnings: [],
  shortlist: [],
  selectedOffers: [],
  offerPricePaid: localStorage.getItem("UEOfferPricePaid") || null,
  offersCache: {},
  currentBaseline: null,
  currentSelectedOffer: null,
  leversLoading: false,
  offersLoading: false,
  offerLoading: false,
  offerDialog: false,
  offerDialogView: "details.formatted",
  offersError: null,
  offerError: null,
  quadrantsBy: "userRating",
};

const mutations = {
  SET_OFFER_PRICE_PAID(state, val) {
    if (val === null) {
      state.offerPricePaid = null;
      localStorage.removeItem("UEOfferPricePaid");
      return;
    }
    state.offerPricePaid = Number(val);
    localStorage.setItem("UEOfferPricePaid", state.offerPricePaid);
  },
  SET_OFFERS(state, val) {
    state.offers = val;
  },
  INCREMENT_OFFERS(state, val) {
    state.offers = [...new Set(...[state.offers.concat(val)])];
  },
  SET_CURRENT_BASELINE(state, val) {
    state.currentBaseline = { ...val };
  },
  SET_OFFERS_LOADING(state, val) {
    state.offersLoading = val;
  },
  SET_OFFERS_ERROR(state, error) {
    state.offersError = error;
  },
  SET_OFFER_ERROR(state, error) {
    state.offerError = error;
  },
  SET_OFFERS_CACHE(state, payload) {
    payload.forEach((p) => {
      state.offersCache[p.id] = p;
    });
  },
  SET_OFFER_LOADING(state, val) {
    state.offerLoading = val;
  },
  SET_OFFERS_EARNINGS(state, data) {
    state.offersEarnings = data;
  },
  SET_SELECTED_OFFER(state, offer) {
    state.currentSelectedOffer = offer;
  },
  SET_OFFER_DIALOG(state, open) {
    state.offerDialog = open;
  },
  SET_OFFER_DIALOG_VIEW(state, val) {
    state.offerDialogView = val;
  },
  SET_SHORTLIST(state, list) {
    state.shortlist = list ? list : [];
  },
  SET_SELECTED_OFFERS(state, offers) {
    state.selectedOffers = offers;
  },
  // SET_APPLY_FILTERS(state, shouldApply) {
  //   state.shouldApplyFilters = shouldApply;
  // },
  SET_VIEW_TYPE(state, type) {
    state.viewType = type;
  },
  SET_QUADRANTS_BY(state, value) {
    state.quadrantsBy = value;
  },
};

const getters = {
  baselineOffer: (state, getters, rootState, rootGetters) => {
    if (!state.offers) {
      return null;
    }
    if (state.currentBaseline) {
      const baseline = JSON.parse(JSON.stringify(state.currentBaseline));
      const offerRatings = getOfferRating(baseline);
      const cabinRating = getOfferCabinRating(
        baseline.outbound.cabinClass,
        baseline.outbound.cabinClass
      );

      baseline.rating = offerRatings.system;
      baseline.userRating = offerRatings.user;
      baseline.cabinRating = cabinRating;

      if (state.offerPricePaid) {
        baseline.cost.amount = state.offerPricePaid;
      }
      return baseline;
    }

    const baseline = rootGetters["flights/baselineTrip"];
    // console.log("baseline", baseline);
    if (!baseline && state.offers.length > 0) {
      if (typeof state.offers[0].cost !== "undefined" && state.offerPricePaid) {
        const baseline = JSON.parse(JSON.stringify(state.offers[0]));
        baseline.cost.amount = state.offerPricePaid;
        const offerRatings = getOfferRating(baseline);
        const cabinRating = getOfferCabinRating(
          baseline.outbound.cabinClass,
          baseline.outbound.cabinClass
        );

        baseline.rating = offerRatings.system;
        baseline.userRating = offerRatings.user;
        baseline.cabinRating = cabinRating;
      }
      return baseline;
    }

    const baselineOffer = state.offers.find((offer) => {
      // sometimes, flight schedule time has a small discrepancy between OAG and Amadeus.
      // So let's compare the amount of time delay between the times
      // and accept the comparisons which time difference is less than an hour.

      const departureDiff = Math.abs(
        differenceInMinutes(
          new Date(baseline.outbound.departure.at),
          new Date(offer.outbound.departure.at)
        )
      );
      const returnDiff = Math.abs(
        differenceInMinutes(
          new Date(baseline.inbound.departure.at),
          new Date(offer.inbound.departure.at)
        )
      );

      // console.log("departureDiff", departureDiff);
      // console.log("returnDiff", returnDiff);
      // console.groupEnd();

      return (
        baseline.outbound.departure.code === offer.outbound.departure.code &&
        // baseline.outbound.departure.at === offer.outbound.departure.at &&
        departureDiff < 60 &&
        baseline.inbound.departure.code === offer.inbound.departure.code &&
        // baseline.inbound.departure.at === offer.inbound.departure.at &&
        returnDiff < 60 &&
        baseline.outbound.carrier.code === offer.outbound.carrier.code &&
        baseline.inbound.carrier.code === offer.inbound.carrier.code &&
        baseline.outbound.number == offer.outbound.flight &&
        baseline.inbound.number == offer.inbound.flight
      );
    });

    if (
      baselineOffer &&
      typeof baselineOffer.cost !== "undefined" &&
      state.offerPricePaid
    ) {
      const baseline = JSON.parse(JSON.stringify(baselineOffer));
      baseline.cost.amount = state.offerPricePaid;
      const offerRatings = getOfferRating(baseline);
      const cabinRating = getOfferCabinRating(
        baseline.outbound.cabinClass,
        baseline.outbound.cabinClass
      );

      baseline.rating = offerRatings.system;
      baseline.userRating = offerRatings.user;
      baseline.cabinRating = cabinRating;

      return baseline;
    }

    return baselineOffer;

    // console.log("baselineOffer", baselineOffer);
  },

  /**
   * Getter to verify if current baseline is already booked.
   *
   * @since v0.8.57
   *
   * @param {*} state
   * @returns
   */
  isCurrentOfferAlreadyBooked: (state, getter) => {
    return (
      getter.baselineOffer !== null &&
      state.currentSelectedOffer !== null &&
      getter.baselineOffer.id === state.currentSelectedOffer.id &&
      state.offerPricePaid !== null
    );
  },

  /**
   * Returns all the date range combinations based on selected departure and return dates.
   *
   * @since v0.8.20
   *
   */
  allDatesAllowed: (state, getters, rootState) => {
    const dateFlexibility = rootState.filters.dateFlexibility;

    // ensures that we have offers only for the allowed date ranges, and at the same time,
    // with the same travel duration
    let datesAllowed = [];
    const dateRange = dateFlexibility.options.map((o) => o.value);

    dateRange.forEach((n) => {
      const departureDate = new Date(
        `${rootState.route.query.departureDate}T00:00:00`
      );
      const returnDate = new Date(
        `${rootState.route.query.returnDate}T00:00:00`
      );

      departureDate.setDate(departureDate.getDate() + n);
      returnDate.setDate(returnDate.getDate() + n);

      datesAllowed.push(
        `${format(departureDate, "Y-MM-dd")}-${format(returnDate, "Y-MM-dd")}`
      );
    });

    return datesAllowed;
  },

  /**
   * Filter offers list by parameters like airlines, cabin classes, etc.
   *
   * @since v0.8.x
   *
   */
  filteredOffers: (state, getters, rootState, rootGetters) => {
    // if (getters.offersWithRatings.length === 0 || !state.shouldApplyFilters) {
    //   return getters.offersWithRatings;
    // }
    const filters = rootGetters["filters/selectedOptions"];
    // const baseline = getters.baselineOffer;
    // const filterKeys = Object.keys(filters).filter((k) => {
    //   return ["dateFlexibility"].indexOf(k) === -1;
    // });

    // if (filterKeys.length === 0) {
    //   return getters.offersWithRatings;
    // }

    let datesAllowed = [];

    if (typeof filters.dateFlexibility !== "undefined") {
      filters.dateFlexibility.forEach((n) => {
        const departureDate = new Date(
          `${rootState.route.query.departureDate}T00:00:00`
        );
        const returnDate = new Date(
          `${rootState.route.query.returnDate}T00:00:00`
        );

        departureDate.setDate(departureDate.getDate() + n);
        returnDate.setDate(returnDate.getDate() + n);

        datesAllowed.push(
          `${format(departureDate, "Y-MM-dd")}-${format(returnDate, "Y-MM-dd")}`
        );
      });
    } else {
      datesAllowed = [...getters.allDatesAllowed];
    }

    // helper function that contains the logic to filter out an offer or not.
    function shouldIncludeOffer(offer) {
      let matches = [];
      // we want to filter offers by quadrant anyways, but other filters
      // should only be applied if we want.
      if (typeof filters.price !== "undefined") {
        matches.push(
          offer.cost.amount >= filters.price[0] &&
            offer.cost.amount <= filters.price[1]
        );
      }

      if (typeof filters.airlines !== "undefined") {
        matches.push(
          filters.airlines.includes(offer.outbound.carrier.code) ||
            filters.airlines.includes(offer.inbound.carrier.code)
        );
      }

      if (typeof filters.destinations !== "undefined") {
        matches.push(
          filters.destinations.includes(offer.outbound.departure.code) ||
            filters.destinations.includes(offer.inbound.departure.code)
        );
      }

      if (datesAllowed) {
        const dateKey = `${format(
          new Date(offer.outbound.departure.at),
          "Y-MM-dd"
        )}-${format(new Date(offer.inbound.departure.at), "Y-MM-dd")}`;
        matches.push(datesAllowed.includes(dateKey));
      }

      if (!filters.mixedCabin) {
        matches.push(offer.outbound.class === offer.inbound.class);
      }

      if (typeof filters.cabins !== "undefined") {
        const cabinCombo = `${offer.outbound.class[0]}${offer.inbound.class[0]}`;
        matches.push(filters.cabins.includes(cabinCombo));
      }

      return !matches.includes(false);
    }

    const offers = [...getters.offersWithRatings]
      .filter((offer) => {
        if (offer.isBaseline) {
          return true;
        }
        let matches = [];

        // if (typeof filters.quadrants !== "undefined") {
        //   if (["overview", "summary"].includes(filters.quadrants)) {
        //     matches.push(true);
        //   } else {
        //     matches.push(compareToQuadrant(baseline, offer, filters.quadrants));
        //   }
        // }

        if (rootState.flags.shouldApplyFilters) {
          matches.push(shouldIncludeOffer(offer));
        }

        return !matches.includes(false);
      })
      .map((o) => {
        if (!rootState.flags.shouldApplyFilters) {
          o.shouldHide = !shouldIncludeOffer(o);
        } else {
          o.shouldHide = false;
        }
        return o;
      });

    return offers.sort((o) => {
      if (o.isBaseline) {
        return -1;
      }
      return 0;
    });
  },
  filteredOffersOnQuadrant: (state, getters, rootState, rootGetters) => {
    const baseline = getters.baselineOffer;
    const filters = rootGetters["filters/selectedOptions"];
    if (
      typeof filters.quadrants === "undefined" ||
      ["overview", "summary"].includes(filters.quadrants)
    ) {
      return getters.filteredOffers;
    }

    return getters.filteredOffers.filter((o) => {
      if (o.isBaseline) {
        return true;
      }
      return compareToQuadrant(
        baseline,
        o,
        filters.quadrants,
        state.quadrantsBy
      );
    });
  },
  /**
   * Return list of offers with system and user ratings.
   *
   * @since v0.2
   *
   */
  offersWithRatings: (state, getters, rootState, rootGetters) => {
    const baseline = getters.baselineOffer;

    if (!baseline) {
      return [];
    }

    const allOffers = [
      baseline,
      ...state.offers.filter((o) => {
        const dateKey = `${format(
          new Date(o.outbound.departure.at),
          "Y-MM-dd"
        )}-${format(new Date(o.inbound.departure.at), "Y-MM-dd")}`;

        return (
          o.id !== baseline.id &&
          getters.allDatesAllowed.includes(dateKey) &&
          o.outbound.carrier.code === o.inbound.carrier.code // filter out all mixed-airline offers
        );
      }),
    ];

    const offers = allOffers.map((o) => {
      const departureflightRatings = getOfferFlightRating(
        o.outbound,
        rootGetters.parsedAttributeWeights.departure
      );

      const returnFlightRatings = getOfferFlightRating(
        o.inbound,
        rootGetters.parsedAttributeWeights.return
      );

      o.outbound.rating = departureflightRatings.system;
      o.outbound.userRating = departureflightRatings.user;
      o.outbound.cabinRating = getCabinRating(o.outbound.class);
      o.inbound.rating = returnFlightRatings.system;
      o.inbound.userRating = returnFlightRatings.user;
      o.inbound.cabinRating = getCabinRating(o.inbound.class);

      const offerRatings = getOfferRating(o);
      const cabinRating = getOfferCabinRating(
        o.outbound.class,
        o.inbound.class
      );

      o.rating = offerRatings.system;
      o.userRating = offerRatings.user;
      o.cabinRating = cabinRating;

      o.offerValue = o.cost.amount / o.userRating;
      // check if this is the baseline trip or not
      o.isBaseline = baseline ? o.id === baseline.id : false;

      // add cost to upgrade
      o.costPerHour = getCostToUpgrade(
        o.outbound.duration,
        o.inbound.duration,
        o.cost.amount
      );

      o.upgradeCost = state.currentBaseline
        ? o.cost.amount - state.currentBaseline.cost.amount
        : null;

      o.upgradeBenefit = state.currentBaseline
        ? {
            total: o.userRating - baseline.userRating,
            percent:
              100 *
              Math.abs(
                (Number(o.userRating) - Number(baseline.userRating)) /
                  ((Number(o.userRating) + Number(baseline.userRating)) / 2)
              ),
          }
        : null;

      o.quadrant = getOfferQuadrant(baseline, o);

      // if we have mileage earnings for this offer, include
      if (state.offersEarnings) {
        const earnings = state.offersEarnings.find((e) => e.id === o.id);

        if (earnings && earnings.programs) {
          o.earnings = earnings.programs;
        }
      }

      return o;
    });

    return offers.sort((o) => {
      if (o.isBaseline) {
        return -1;
      }
      return 0;
    });
  },

  /**
   * Group offers that are selling tickets for the same flight.
   *
   * @since v0.8.5
   *
   */
  groupedOffers: (state, getters, rootState) => {
    if (!rootState.flags.groupCodesharedOffers) {
      return getters.filteredOffersOnQuadrant;
    }

    let grouped = {};

    getters.filteredOffersOnQuadrant.forEach((offer) => {
      const identifier = [
        offer.outbound.operating.code,
        offer.outbound.departure.at,
        offer.outbound.arrival.at,
        offer.inbound.operating.code,
        offer.inbound.departure.at,
        offer.inbound.arrival.at,
        offer.outbound.class,
        offer.inbound.class,
      ].join("-");

      // console.log(identifier);

      if (typeof grouped[identifier] === "undefined") {
        grouped[identifier] = offer;
        return;
      }

      if (typeof grouped[identifier].codeshared === "undefined") {
        grouped[identifier].codeshared = {};
      }

      if (
        typeof grouped[identifier].codeshared[offer.outbound.carrier.code] ===
        "undefined"
      ) {
        grouped[identifier].codeshared[offer.outbound.carrier.code] = {
          iata: offer.outbound.carrier.code,
          name: offer.outbound.carrier.name,
          diff: offer.cost.amount - grouped[identifier].cost.amount,
        };
      }
      if (
        typeof grouped[identifier].codeshared[offer.inbound.carrier.code] ===
        "undefined"
      ) {
        grouped[identifier].codeshared[offer.inbound.carrier.code] = {
          iata: offer.inbound.carrier.code,
          name: offer.inbound.carrier.name,
          diff: offer.cost.amount - grouped[identifier].cost.amount,
        };
      }
    });

    return Object.values(grouped).map((o) => {
      if (typeof o.codeshared !== "undefined") {
        o.codeshared = Object.values(o.codeshared);
      }
      return o;
    });
  },

  offersPerQuadrant: (state, getters, rootState) => {
    if (
      !getters.offersWithRatings ||
      getters.offersWithRatings.length === 0 ||
      !getters.baselineOffer
    ) {
      return [];
    }
    const quadrants = rootState.filters.quadrants.options.map((o) => o.value);

    let countPerQuadrant = {};

    quadrants.forEach((k) => {
      countPerQuadrant[k] = ["overview", "summary"].includes(k)
        ? getters.offersWithRatings.length
        : filterByQuadrant(
            getters.baselineOffer,
            getters.filteredOffers,
            k,
            state.quadrantsBy
          ).length;
    });

    return countPerQuadrant;
  },

  top10Upgrades: (state, getters) => {
    if (state.offers.length === 0) {
      return [];
    }

    // const upgrades = state.shouldApplyFilters
    //   ? getters.filteredOffers
    //   : getters.offersWithRatings;

    // console.log("shouldApplyFilters", state.shouldApplyFilters);

    const baseline = getters.filteredOffers.find((u) => u.isBaseline);

    const sorted = getters.filteredOffers
      .filter((u) => !u.isBaseline)
      .sort((a, b) => {
        const currentValue = b.userRating / b.cost.amount;
        const prevValue = a.userRating / a.cost.amount;

        return currentValue < prevValue ? -1 : 1;
      })
      .slice(0, 10);

    return [baseline, ...sorted];
  },

  /**
   * Returns currently selected offer with detailed info, along with system and user ratings.
   *
   * @since v0.2
   *
   */
  selectedOfferWithRatings: (state, getters, rootState, rootGetters) => {
    if (!state.currentSelectedOffer) {
      return null;
    }
    let o = { ...state.currentSelectedOffer };

    if (
      state.offerPricePaid &&
      state.currentBaseline &&
      o.id === state.currentBaseline.id
    ) {
      o.cost = state.offerPricePaid;
    }

    // console.log("selectedOffersWithRatings", o);

    const departureflightRatings = getFlightRating(
      o.outbound,
      rootGetters.parsedAttributeWeights.departure
    );
    const returnFlightRatings = getFlightRating(
      o.inbound,
      rootGetters.parsedAttributeWeights.return
    );

    o.outbound.aircraft.cabin.rating = departureflightRatings.system;
    o.outbound.aircraft.cabin.userRating = departureflightRatings.user;
    o.outbound.aircraft.cabin.cabinRating = getCabinRating(
      o.outbound.aircraft.cabin.cabinClass
    );

    o.inbound.aircraft.cabin.rating = returnFlightRatings.system;
    o.inbound.aircraft.cabin.userRating = returnFlightRatings.user;
    o.inbound.aircraft.cabin.cabinRating = getCabinRating(
      o.inbound.aircraft.cabin.cabinClass
    );

    o.rating = Number(
      (
        (o.outbound.aircraft.cabin.rating + o.inbound.aircraft.cabin.rating) /
        2
      ).toFixed(2)
    );

    o.userRating = Number(
      (
        (o.outbound.aircraft.cabin.userRating +
          o.inbound.aircraft.cabin.userRating) /
        2
      ).toFixed(2)
    );

    o.cabinRating = Number(
      (
        (o.outbound.aircraft.cabin.cabinRating +
          o.inbound.aircraft.cabin.cabinRating) /
        2
      ).toFixed(2)
    );

    // if we have mileage earnings for this offer, include
    if (state.offersEarnings) {
      const earnings = state.offersEarnings.find((e) => e.id === o.id);

      if (earnings && earnings.programs) {
        o.earnings = earnings.programs;
      }
    }

    return o;
  },

  /**
   * Returns list of offers with detailed info, along with system and user ratings.
   *
   * @since v0.2
   *
   */

  selectedOffersWithRatings: (state, getters, rootState, rootGetters) => {
    if (!state.selectedOffers || state.selectedOffers.length === 0) {
      return [];
    }

    return state.selectedOffers.map((offer) => {
      const o = { ...offer };

      const departureflightRatings = getFlightRating(
        o.outbound,
        rootGetters.parsedAttributeWeights.departure
      );
      const returnFlightRatings = getFlightRating(
        o.inbound,
        rootGetters.parsedAttributeWeights.return
      );

      o.outbound.aircraft.cabin.rating = departureflightRatings.system;
      o.outbound.aircraft.cabin.userRating = departureflightRatings.user;
      o.outbound.aircraft.cabin.cabinRating = getCabinRating(
        o.outbound.aircraft.cabin.cabinClass
      );

      o.inbound.aircraft.cabin.rating = returnFlightRatings.system;
      o.inbound.aircraft.cabin.userRating = returnFlightRatings.user;
      o.inbound.aircraft.cabin.cabinRating = getCabinRating(
        o.inbound.aircraft.cabin.cabinClass
      );

      o.rating = Number(
        (
          (o.outbound.aircraft.cabin.rating + o.inbound.aircraft.cabin.rating) /
          2
        ).toFixed(2)
      );

      o.userRating = Number(
        (
          (o.outbound.aircraft.cabin.userRating +
            o.inbound.aircraft.cabin.userRating) /
          2
        ).toFixed(2)
      );

      o.cabinRating = Number(
        (
          (o.outbound.aircraft.cabin.cabinRating +
            o.inbound.aircraft.cabin.cabinRating) /
          2
        ).toFixed(2)
      );

      o.isBaseline = o.id === state.currentBaseline.id;

      return o;
    });
  },

  /**
   * Base parameters to be used when calling /offers and /signals APIs.
   *
   * @since v0.10
   *
   */
  baseParams: (state, getters, rootState) => {
    const baseParams = {
      DepartureDate: rootState.route.query.departureDate || null,
      ArrivalDate: rootState.route.query.returnDate || null,
      // DepartureLocation: outbound.departure.code,
      DepatureLocation: rootState.route.query.origin || null,
      ArrivalLocation: rootState.route.query.destination || null,
      // MixedCabin: filters.mixedCabin || false,
      // Cabins: filters.cabins.join(",") || "EE",
      Cabins:
        // filters.cabins.join(",") ||
        rootState.filters.cabins.options.map((o) => o.value).join(","),
      PaxAdult: 1,
      DateFlexibility: 3,
    };

    return baseParams;
  },
};

const actions = {
  /**
   * Changes the offers view type (list, chart, top 10, etc)
   *
   * @since v0.8.5
   *
   */
  setViewType({ commit }, type) {
    commit("SET_VIEW_TYPE", type);
  },

  // /**
  //  * Group codeshared offers.
  //  *
  //  * @since v0.8.5
  //  *
  //  */
  // groupOffers({ commit }, type) {
  //   commit("SET_GROUPED_OFFERS", type);
  // },

  /**
   * Retrieves a list of offers and set the list on the state.
   *
   * @since v0.5
   *
   */
  async getOffers({
    state,
    rootState,
    commit,
    getters,
    rootGetters,
    dispatch,
  }) {
    // abort all current promises
    api.abortAll("offers");
    api.abortAll("offersSignals");

    console.log("getOffers");

    commit("SET_OFFERS", []);
    commit("SET_OFFERS_ERROR", null);
    commit("SET_OFFERS_LOADING", true);

    const baseParams = getters.baseParams;

    // const destinations =
    //   filters.destinations.length > 0
    //     ? filters.destinations
    //     : [rootState.route.query.destination];

    const destinations = rootState.filters.destinations.options.map(
      (o) => o.value
    );

    const all = [];

    destinations.forEach((destination) => {
      let params = {
        ...baseParams,
        ArrivalLocation: destination,
        context: rootGetters["profile/encodedContext"],
      };

      all.push(
        new Promise((resolve, reject) => {
          api
            .getOffers(params)
            .then((offers) => {
              dispatch(
                "logs/setLog",
                {
                  type: "response",
                  origin: "/offers",
                  payload: {
                    request: offers.config.url,
                    version: offers.headers
                      ? offers.headers["ue-code-version"] || null
                      : null,
                    response: offers.data,
                    status: offers.status,
                  },
                },
                {
                  root: true,
                }
              );
              if (offers.status !== 204) {
                commit("INCREMENT_OFFERS", offers.data);
                console.log("resolved");

                resolve(offers.data);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              dispatch(
                "logs/setLog",
                {
                  type: "response",
                  origin: "/offers",
                  version: error.headers
                    ? error.headers["ue-code-version"] || null
                    : null,
                  payload: error.response,
                },
                {
                  root: true,
                }
              );

              if (!api.isAborted(error)) {
                commit("SET_OFFERS_ERROR", {
                  code: error.response.status,
                  message: error.response.message,
                  description: "Change your baseline flights and try again.",
                });
              }
              console.log("rejected");
              reject(error);
            });
        })
      );
    });

    Promise.allSettled(all).then((results) => {
      commit("SET_OFFERS_LOADING", false);

      console.log("allSettled", results);

      // store first offer from first /offers api call,
      // so we can persit it just in case we never found
      // the baseline offer comparing with the baseline flights
      if (!state.currentBaseline) {
        const cabinLetter = rootState.route.query.flightClass
          .toUpperCase()
          .substr(0, 1);
        const selectedCabinCombo = `${cabinLetter}${cabinLetter}`;
        const selectedOrigin = rootState.route.query.origin;
        const selectedDestination = rootState.route.query.destination;
        let selectedDestinations = [selectedDestination];

        if (
          typeof rootState.filters.cityMapping[selectedDestination] !==
          "undefined"
        ) {
          selectedDestinations = selectedDestinations.concat(
            rootState.filters.cityMapping[selectedDestination]
          );
        }

        const filtered = state.offers.filter((o) => {
          const departureDate = format(
            new Date(o.outbound.departure.at),
            "Y-MM-dd"
          );
          const returnDate = format(
            new Date(o.inbound.departure.at),
            "Y-MM-dd"
          );

          const offerOutboundCabin = o.outbound.class
            .toUpperCase()
            .substr(0, 1);
          const offerInboundCabin = o.inbound.class.toUpperCase().substr(0, 1);
          const offerCabinCombo = `${offerOutboundCabin}${offerInboundCabin}`;
          const offerOrigin = o.outbound.departure.code;
          const offerDestination = o.inbound.departure.code;

          return (
            rootState.route.query.departureDate === departureDate &&
            rootState.route.query.returnDate === returnDate &&
            selectedCabinCombo === offerCabinCombo &&
            selectedOrigin === offerOrigin &&
            selectedDestinations.includes(offerDestination)
          );
        });

        commit("SET_CURRENT_BASELINE", filtered[0]);
      }

      dispatch("filters/setPriceRange", getters.offersWithRatings, {
        root: true,
      });

      // only call Signals API once
      if (!rootState.filters.upgradeCosts) {
        const signalParams = {
          ...baseParams,
          // DateFlexibility: 3,
          Context: rootGetters["profile/encodedContext"],
          BaselineOffer: getters.baselineOffer.id,
        };

        dispatch("filters/getSignals", signalParams, {
          root: true,
        });
      }

      //get miles earned per offer
      dispatch("getMilesEarned");
    });
  },

  /**
   * Changes the selected baseline. For integration and testing purposes.
   *
   * @since v0.10
   *
   * @param {*} offer the selected offer.
   */
  setCurrentBaselineOffer({ commit, dispatch, getters, rootGetters }, offer) {
    commit("SET_CURRENT_BASELINE", offer);

    const baseParams = getters.baseParams;
    const signalParams = {
      ...baseParams,
      Context: rootGetters["profile/encodedContext"],
      BaselineOffer: offer.id,
    };

    dispatch("filters/getSignals", signalParams, {
      root: true,
    });
  },

  /**
   * updates the shortlisted offers state.
   *
   * @since v0.5
   *
   * @param {Array} val The list of offers.
   */
  setShortlist({ commit, state, rootState }, val) {
    commit("SET_SHORTLIST", val);
    router.push({
      path: "/results",
      query: {
        ...rootState.route.query,
        offers: state.shortlist,
      },
    });
  },
  /**
   * Get details about the selected offers.
   *
   */
  getSelectedOffers({ commit, state, dispatch, rootGetters }) {
    const params = {
      uid: state.shortlist,
      // class: state.flightClass
    };

    if (!params.uid.includes(state.currentBaseline.id)) {
      params.uid.push(state.currentBaseline.id);
    }

    commit("SET_OFFERS_LOADING", true);

    api
      .getOffer(state.shortlist, rootGetters["profile/encodedContext"])
      .then((response) => {
        console.log(response);
        if (response.status == 204) {
          commit("SET_OFFER_ERROR", {
            code: 204,
            message: "Offer response is empty.",
          });
        } else {
          dispatch(
            "logs/setLog",
            {
              type: "response",
              origin: "/offer",
              payload: {
                request: response.config.url,
                version: response.headers
                  ? response.headers["ue-code-version"] || null
                  : null,
                response: response.data,
                status: response.status,
              },
            },
            {
              root: true,
            }
          );

          commit("SET_SELECTED_OFFERS", response.data);
          commit("SET_OFFERS_CACHE", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        commit("SET_OFFER_LOADING", false);
      })
      .then(() => {
        commit("SET_OFFERS_LOADING", false);
      });
  },

  /**
   * Gets the details about an offer and stores in a cache if it's not there yet.
   * Also, it opens the Offer details dialog.
   *
   * @since v0.5
   *
   * @param {*} id the offer id.
   */
  viewOfferDetails({ commit, state, dispatch, rootState, rootGetters }, id) {
    commit("SET_OFFER_DIALOG", true);
    commit("SET_SELECTED_OFFER", null);
    commit("SET_OFFER_ERROR", null);

    if (!rootState.route.query.viewOffer) {
      router.push({
        path: rootState.route.path,
        query: {
          ...rootState.route.query,
          viewOffer: id,
        },
      });
    }

    // if (getters.baselineOffer && id === getters.baselineOffer.id) {
    //   commit("SET_SELECTED_OFFER", getters.baselineOffer);
    //   return;
    // }

    if (typeof state.offersCache[id] !== "undefined") {
      commit("SET_SELECTED_OFFER", state.offersCache[id]);
      return;
    }

    // const offer = getters.offersWithRatings.find(o => o.id === id);

    commit("SET_OFFER_LOADING", true);

    api
      .getOffer(id, rootGetters["profile/encodedContext"])
      .then((response) => {
        console.log(response);
        if (response.status == 204) {
          commit("SET_OFFER_ERROR", {
            code: 204,
            message: "Offer response is empty.",
          });
        } else {
          dispatch(
            "logs/setLog",
            {
              type: "response",
              origin: "/offer",
              payload: {
                request: response.config.url,
                version: response.headers
                  ? response.headers["ue-code-version"] || null
                  : null,
                response: response.data,
                status: response.status,
              },
            },
            {
              root: true,
            }
          );

          commit("SET_SELECTED_OFFER", response.data[0]);
          commit("SET_OFFERS_CACHE", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        commit("SET_OFFER_ERROR", {
          code: error.response.status,
          message: error.response.message,
        });
        commit("SET_OFFER_LOADING", false);
      })
      .then(() => {
        commit("SET_OFFER_LOADING", false);
      });
  },

  async getCurrentOfferBookingLinks({ commit, state }) {
    // if( state.currentSelectedOffer && state.currentSelectedOffer.booking ) {
    //   return;
    // }

    let offer = { ...state.currentSelectedOffer };

    if (!offer) {
      return;
    }

    const params = {
      origin: offer.outbound.departure.code,
      destination: offer.inbound.departure.code,
      departureDate: offer.outbound.departure.at,
      returnDate: offer.inbound.arrival.at,
      airline: offer.outbound.carrier.code,
      cabinClass: offer.outbound.aircraft.cabin.cabinClass,
    };

    try {
      const booking = await api.getOfferBookingLink(params);
      offer.booking = booking.data;
      commit("SET_SELECTED_OFFER", offer);
    } catch (e) {
      commit("SET_OFFERS_ERROR", e);
    }
  },
  /**
   * Closes the offer dialog.
   *
   * @since v0.5
   *
   */
  closeDialog({ commit, rootState }) {
    commit("SET_OFFER_DIALOG", false);

    let query = { ...rootState.route.query };
    delete query.viewOffer;

    router.push({
      path: rootState.route.path,
      query: query,
    });
  },

  // /**
  //  * Helper function to apply or not the filters / levers on the top 10 upgrades list.
  //  *
  //  * @since v0.9
  //  *
  //  * @param {Boolean} shouldApply a boolean indicating if we should apply filters or not.
  //  */
  // toggleOfferFilters({ commit }, shouldApply) {
  //   commit("SET_APPLY_FILTERS", shouldApply);

  //   // let query = { ...rootState.route.query };
  //   // delete query.viewOffer;

  //   // router.push({
  //   //   path: rootState.route.path,
  //   //   query: query
  //   // });
  // },
  /**
   * Set the price the user paid for the offer. Used on the post-booking scenario.
   *
   * @since v0.8.45
   *
   * @param {Number} paidPrice the price the user paid for the offer.
   */
  setOfferPricePaid({ commit }, paidPrice) {
    commit("SET_OFFER_PRICE_PAID", paidPrice);
  },

  /**
   * Set global state of offer details popup.
   *
   * @since3 v0.8.57
   *
   * @param {String} view the selected view.
   */
  setOfferDetailsView({ commit }, view) {
    commit("SET_OFFER_DIALOG_VIEW", view);
  },

  /**
   * For all offers, get mileage earnings and store the data on the state.
   *
   * @since v0.8.63
   *
   */
  async getMilesEarned({ commit, getters, dispatch }) {
    if (!getters.offersWithRatings) {
      return;
    }
    const classMap = {
      ECONOMY: "Y",
      "ECONOMY+": "W",
      PREMIUM_ECONOMY: "P",
      BUSINESS: "J",
      FIRST: "F",
    };
    const segments = getters.offersWithRatings.map((o) => {
      return {
        id: o.id,
        baseFareUSD: o.cost.amount,
        segments: [
          {
            origin: o.outbound.departure.code,
            destination: o.outbound.arrival.code,
            departure: o.outbound.departure.at,
            carrier: o.outbound.carrier.code,
            bookingClass:
              typeof o.outbound.fareBasis !== "undefined"
                ? o.outbound.fareBasis.class
                : classMap[o.outbound.class],
            operatingCarrier: o.outbound.operating.code,
            flightNumber: Number(o.outbound.flight),
          },
          {
            origin: o.inbound.departure.code,
            destination: o.inbound.arrival.code,
            departure: o.inbound.departure.at,
            carrier: o.inbound.carrier.code,
            bookingClass:
              typeof o.inbound.fareBasis !== "undefined"
                ? o.inbound.fareBasis.class
                : classMap[o.inbound.class],
            operatingCarrier: o.inbound.operating.code,
            flightNumber: Number(o.inbound.flight),
          },
        ],
      };
    });

    try {
      const response = await api.getMilesEarned(segments);

      dispatch(
        "logs/setLog",
        {
          type: "response",
          origin: "/offers/earnings",
          payload: {
            request: response.config.url,
            version: response.headers
              ? response.headers["ue-code-version"] || null
              : null,
            response: response.data,
            status: response.status,
          },
        },
        {
          root: true,
        }
      );

      commit("SET_OFFERS_EARNINGS", response.data);
    } catch (err) {
      dispatch(
        "logs/setLog",
        {
          type: "response",
          origin: "/offers/earnings",
          payload: {
            request: err.config ? err.config.url : null,
            version: err.headers
              ? err.headers["ue-code-version"] || null
              : null,
            status: err.response.status,
            message: err.response.message,
          },
        },
        {
          root: true,
        }
      );
    }
  },
  /**
   * Set how offers quadrants should be defined (comfort rating or cabin class rating, for example);
   *
   * @since v0.9.12
   *
   * @param {String} value the identifier of the offer variable that will define how we set quadrants.
   */
  setQuadrantsBy({ commit }, value) {
    commit("SET_QUADRANTS_BY", value);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};

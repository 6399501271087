import auth0Client from "../authClient";
import jwt_decode from "jwt-decode";
import router from "../../../modules/router";

// Session / State
const state = {
  authLoading: true,
  isAuthenticated: !!localStorage.getItem("access_token"),
  accessToken: localStorage.getItem("access_token"),
  idToken: localStorage.getItem("id_token"),
  expiresAt: localStorage.getItem("expires_at"),
  appTokens: {
    isAuthenticated: !!localStorage.getItem("ue_access_token"),
    accessToken: localStorage.getItem("ue_access_token"),
    idToken: localStorage.getItem("ue_id_token"),
    expiresAt: localStorage.getItem("ue_expires_at"),
  },
  popupOpen: false,
  auth0Client: null,
  error: null,
  popup: null,
};

// Session / Mutations
const mutations = {
  SET_POPUP_OPEN: (state, isOpen) => {
    state.popupOpen = isOpen;
  },
  SET_AUTH_LOADING: (state, loading) => {
    state.authLoading = loading;
  },
  SET_AUTHENTICATED: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated;
  },
  SET_AUTH_CLIENT: (state, client) => {
    state.auth0Client = client;
  },
  SET_AUTH_RESULT: (state, authData) => {
    state.isAuthenticated = true;
    state.accessToken = authData.accessToken;
    state.idToken = authData.idToken;
    state.expiresAt = authData.expiresIn * 1000 + new Date().getTime();

    localStorage.setItem("access_token", state.accessToken);
    localStorage.setItem("id_token", state.idToken);
    localStorage.setItem("expires_at", state.expiresAt);
  },
  SET_APP_AUTH_RESULT: (state, authData) => {
    state.appTokens.isAuthenticated = true;
    state.appTokens.accessToken = authData.accessToken;
    state.appTokens.idToken = authData.idToken;
    state.appTokens.expiresAt =
      authData.expiresIn * 1000 + new Date().getTime();

    localStorage.setItem("ue_access_token", state.appTokens.accessToken);
    localStorage.setItem("ue_id_token", state.appTokens.idToken);
    localStorage.setItem("ue_expires_at", state.appTokens.expiresAt);
  },
  SET_ERROR: (state, e) => {
    state.error = e;
  },
  SET_LOGOUT: (state) => {
    state.isAuthenticated = false;
    state.accessToken = null;
    state.idToken = false;

    state.appTokens.isAuthenticated = false;
    state.appTokens.accessToken = null;
    state.appTokens.idToken = false;

    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("ue_access_token");
    localStorage.removeItem("ue_id_token");
    localStorage.removeItem("ue_expires_at");
  },
};

const getters = {
  // decode ID Token to grab user info
  userPermissions: (state) => {
    if (!state.appTokens.accessToken) {
      return null;
    }

    return jwt_decode(state.appTokens.accessToken).permissions;
  },
  // decode ID Token to grab user info
  idTokenDecoded: (state) => {
    if (!state.idToken) {
      return null;
    }

    return jwt_decode(state.idToken);
  },
};

// Session / Actions
const actions = {
  /**
   * Authenticates the user using a popup window.
   *
   * @since v0.2
   */
  async loginWithPopup({ rootState, commit, dispatch }, isSignup) {
    commit("SET_POPUP_OPEN", true);

    try {
      await auth0Client.login(isSignup);
      // console.log("login", login);
      commit("SET_AUTHENTICATED", await auth0Client.isAuthenticated());
      await dispatch("profile/getUser", null, { root: true });
      // if (!rootState.profile.isUserOnboarded) {
      //   router.push({ name: "onboarding" });
      // }
    } catch (err) {
      commit("SET_ERROR", err);
    }
    // commit("SET_AUTH_RESULT", result);

    // state.auth0Client.popup.authorize(params, (err, result) => {
    //   if (err) {
    //     commit("SET_ERROR", err);
    //   } else {
    //     commit("SET_AUTH_LOADING", true);
    //     commit("SET_AUTH_RESULT", result);
    //     dispatch("getAppTokens");
    //     api.setToken(result.accessToken);
    //     dispatch("profile/getUser", null, { root: true });
    //     if (!rootState.profile.isUserOnboarded) {
    //       router.push({ name: "onboarding" });
    //     }
    //     commit("SET_AUTH_LOADING", false);
    //   }
    // });
  },

  /**
   * Logs the user out and removes their session on the authorization server.
   *
   * @since v0.2
   */
  async logout({ commit }) {
    commit("SET_LOGOUT");
    auth0Client.logout();
  },

  /**
   * Checks for current session on page load. If callback parameters from authorization endpoint are found, triggers a popup callback.
   * If not, check current session and access tokens.
   *
   * @since v0.2
   */
  async initializeAuth({ state, commit, dispatch }) {
    const auth0 = await auth0Client.init();
    commit("SET_AUTH_CLIENT", auth0);

    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        // handle the redirect and retrieve tokens
        const result = await auth0Client.handleCallback();
        console.log(result);

        commit("SET_ERROR", null);

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        // onRedirectCallback(appState);
      }
    } catch (e) {
      commit("SET_ERROR", e);
    } finally {
      // Initialize our internal authentication state
      commit("SET_AUTHENTICATED", await auth0Client.isAuthenticated());

      // await dispatch("profile/setManager", null, { root: true });
      // await dispatch("billing/setup", null, { root: true });
      if (state.isAuthenticated) {
        await dispatch("profile/getUser", null, { root: true });
      }
    }

    // try {
    //   // If the user is returning to the app after authentication..
    //   if (
    //     window.location.hash.indexOf("state") !== -1 &&
    //     window.location.hash.indexOf("access_token") !== -1
    //   ) {
    //     state.auth0Client.popup.callback();
    //   }
    // } catch (e) {
    //   commit("SET_ERROR", e);
    // } finally {
    //   dispatch("maybeUpdateTokens");
    // }
  },

  // /**
  //  * Checks current access token expiration. If expired, gets new access token.
  //  * On all cases, after the token is validated, calls profile/getUser to set current user metadata.
  //  *
  //  * @since v0.2
  //  * @uses  profile/getUser
  //  */
  // async maybeUpdateTokens({ state, commit, getters, dispatch }) {
  //   if (
  //     new Date().getTime() > state.expiresAt ||
  //     new Date().getTime() > getters.idTokenDecoded.exp ||
  //     !state.accessToken
  //   ) {
  //     commit("SET_AUTH_LOADING", true);
  //     await dispatch("getAuthTokens");
  //     commit("SET_AUTH_LOADING", false);
  //     // await dispatch("profile/getUser", null, { root: true });
  //   }

  //   if (
  //     new Date().getTime() > state.appTokens.expiresAt ||
  //     !state.appTokens.accessToken
  //   ) {
  //     await dispatch("getAppTokens");
  //   }

  //   if (state.accessToken) {
  //     api.setToken(state.accessToken);
  //     await dispatch("profile/getUser", null, { root: true });
  //   }
  // },

  // /**
  //  * Helper function to set authenticated status for current user.
  //  *
  //  * @since v0.2
  //  */
  // setAuthenticated({ commit }) {
  //   commit("SET_AUTHENTICATED", true);
  // },

  // /**
  //  * Isolated function to get Auth0 tokens if necessary.
  //  *
  //  * @since v0.8
  //  *
  //  */
  // async getAuthTokens({ state, commit, dispatch }) {
  //   if (!state.auth0Client) {
  //     return;
  //   }
  //   console.log("getAuthTokens");
  //   state.auth0Client.checkSession({}, (err, result) => {
  //     if (err) {
  //       if (err.code === "login_required") {
  //         // dispatch("logout");
  //         commit("SET_LOGOUT");
  //       }
  //       commit("SET_ERROR", err);
  //     } else {
  //       commit("SET_AUTH_LOADING", true);
  //       console.log("result", result);
  //       commit("SET_AUTH_RESULT", result);
  //       commit("SET_AUTH_LOADING", false);
  //     }
  //   });
  // },

  /**
   * Since custom permissions for Upgrade Engine API are isolated from Auth0 Management
   * API scope, we need to get access tokens for this API as well. This function
   * takes care of getting the right tokens if necessary.
   *
   * @since v0.8
   *
   */
  // async getAppTokens({ state, commit }) {
  //   if (!state.auth0Client) {
  //     return;
  //   }
  //   console.log("getAppTokens");
  //   state.auth0Client.checkSession(
  //     {
  //       audience: `https://upgradeengine.com`,
  //       scope: "edit:rating_profile",
  //       responseType: "token"
  //     },
  //     (err, authResult) => {
  //       if (err) {
  //         console.log(err);
  //         commit("SET_ERROR", err);
  //       } else {
  //         console.log("result", authResult);
  //         commit("SET_APP_AUTH_RESULT", authResult);
  //       }
  //     }
  //   );
  // }
};

export default {
  state,
  mutations,
  getters,
  actions,
};

<template>
  <v-container style="max-width: 1140px">
    <header class="mt-2 mb-2 d-flex align-center">
      <div class="text-h5">Upgrade Engine</div>
      <v-spacer></v-spacer>
      <!-- <v-btn @click="login" color="primary" large>Log In</v-btn> -->
    </header>
    <v-row class="pt-16">
      <v-col></v-col>
      <v-col md="7" class="text-center">
        <h1 class="text-h2 mb-4" style="line-height: 1.3">
          The Upgrade Visualization Platform.
        </h1>
        <p class="text-body-1 grey--text text--darken-1">
          You select your flights, and we find all the hidden opportunities to
          get a better travel experience - sometimes for a lot less than you
          expected to pay.
        </p>
      </v-col>
      <v-col></v-col>
    </v-row>
    <h3 class="mt-16 mb-8 text-h5 text-center">I'd like to Upgrade...</h3>
    <v-row>
      <v-col>
        <v-card class="pa-4 d-flex flex-column" height="100%">
          <v-card-text>
            <h3 class="text-h5 mb-4 black--text">
              A Set of Flights I'm About to Book
            </h3>
            <p>
              You're about to book, but want to know if there are better
              opportunities out there
            </p>
          </v-card-text>
          <v-card-actions class="mt-auto">
            <v-btn color="primary" to="/search">Upgrade Flights</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-4 d-flex flex-column" height="100%">
          <v-card-text>
            <h3 class="text-h5 mb-4 black--text">
              A Ticket I've Already Purchased
            </h3>
            <p>
              You got your ticket, but is willing to cancel it or change if
              there are better opportunities out there
            </p>
          </v-card-text>
          <v-card-actions class="mt-auto">
            <v-btn color="primary" disabled>Upgrade Ticket</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-4 d-flex flex-column" height="100%">
          <v-card-text>
            <h3 class="text-h5 mb-4 black--text">Future Trip Ideas</h3>
            <p>
              You want to travel, but haven't decided yet where to go, and is
              willing to go where you can get the most bang for the buck
            </p>
          </v-card-text>
          <v-card-actions class="mt-auto">
            <v-btn color="primary" disabled>Upgrade Trip Ideas</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LandingPage",
  methods: {
    ...mapActions("session", ["loginWithPopup"]),
    async login() {
      await this.loginWithPopup();
      this.$router.push("/search");
    },
  },
};
</script>

export const servers = {
  mock: {
    name: "Postman Server",
    url: "https://17ed1f44-15d6-479a-93e1-8f0f58b9f653.mock.pstmn.io/v2/",
  },
  test: {
    name: "Test Server",
    url: import.meta.env.VITE_UE_OFFERS_TEST_URL,
  },
  staging: {
    name: "Staging Server",
    url: import.meta.env.VITE_UE_OFFERS_PROD_URL,
  },
  production: {
    name: "Production Server",
    url: import.meta.env.VITE_UE_OFFERS_PROD_URL,
  },
};

const baseUrl = window.origin;

function isLocal() {
  return baseUrl.indexOf("localhost") !== -1;
}

function isRegression() {
  return baseUrl.indexOf("regression") !== -1;
}

function isConnections() {
  return baseUrl.indexOf("connections") !== -1;
}

function isStaging() {
  return baseUrl.indexOf("staging") !== -1;
}

export const getDefaultServer = () => {
  if (isConnections()) return "mock";
  if (isLocal() || isRegression()) return "test";
  if (isStaging()) return "staging";

  return "production";
};

export const getServerUrl = (server) => {
  if (!server) {
    const flags = JSON.parse(localStorage.getItem("UEfeatFlags")) || {},
      source = flags.apiSource || getDefaultServer();

    return servers[source].url;
  }
  return servers[server].url;
};

export const getCurrentServer = () => {
  const flags = JSON.parse(localStorage.getItem("UEfeatFlags")),
    source = flags.apiSource || getDefaultServer();

  return source;
};

import api from "../api";
import uniqBy from "lodash.uniqby";

const state = {
  seats: [],
  loading: false,
  error: null,
  filterBy: {
    airlines: [],
    cabinClasses: [],
    aircraft: [],
    rating: null,
  },
};

const mutations = {
  SET_SEATS(state, data) {
    state.seats = data;
  },
  SET_SEATS_ERROR(state, error) {
    state.error = error;
  },
  SET_SEATS_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_FILTER(state, payload) {
    state.filterBy[payload.key] = payload.value;
  },
};

const getters = {
  filters: (state) => {
    if (!state.seats) {
      return null;
    }

    const cabinsOrder = ["Y", "Y+", "PE", "J", "F"];

    const filters = {
      airlines: uniqBy(
        state.seats.map((s) => s.airline),
        "iata"
      ).map((s) => {
        return {
          label: s.name,
          value: s.iata,
        };
      }),
      aircraft: uniqBy(
        state.seats.map((s) => s.aircraft),
        "code"
      )
        .map((s) => {
          return {
            label: s.name,
            value: s.code,
          };
        })
        .sort((a, b) => a.label - b.label),
      cabinClasses: uniqBy(
        state.seats.map((s) => s.class),
        "code"
      )
        .map((s) => {
          return {
            label: s.name,
            value: s.code,
          };
        })
        .sort((a, b) => {
          return cabinsOrder.indexOf(a.value) - cabinsOrder.indexOf(b.value);
        }),
    };

    return filters;
  },
  filteredSeats: (state) => {
    if (
      state.filterBy.airlines.length === 0 &&
      state.filterBy.cabinClasses.length === 0 &&
      state.filterBy.aircraft.length === 0
    ) {
      return state.seats;
    }

    console.log("have filters");

    return state.seats.filter((s) => {
      let matches = [];
      if (state.filterBy.airlines.length > 0) {
        matches.push(state.filterBy.airlines.includes(s.airline.iata));
      }
      if (state.filterBy.cabinClasses.length > 0) {
        matches.push(state.filterBy.cabinClasses.includes(s.class.code));
      }
      if (state.filterBy.aircraft.length > 0) {
        matches.push(state.filterBy.aircraft.includes(s.aircraft.code));
      }

      return !matches.includes(false);
    });
  },
  groupedSeats: (state, getters) => {
    if (!getters.filteredSeats) {
      return null;
    }

    let groups = {};

    getters.filteredSeats.forEach((seat) => {
      if (typeof groups[seat.airline.iata] === "undefined") {
        groups[seat.airline.iata] = {
          label: seat.airline.name,
          cabinClasses: {},
        };
      }
      if (
        typeof groups[seat.airline.iata].cabinClasses[seat.class.code] ===
        "undefined"
      ) {
        groups[seat.airline.iata].cabinClasses[seat.class.code] = {
          label: seat.class.name,
          seats: [],
        };
      }

      groups[seat.airline.iata].cabinClasses[seat.class.code].seats.push(seat);
    });

    return groups;
  },
};

const actions = {
  async getSeats({ state, commit }) {
    if (state.seats.length > 0) {
      return;
    }
    commit("SET_SEATS_LOADING", true);
    try {
      const seats = await api.getSeats();
      commit("SET_SEATS", seats.data);
      commit("SET_SEATS_LOADING", false);
    } catch (err) {
      console.log(err);
      commit("SET_SEATS_LOADING", false);
      commit("SET_SEATS_ERROR", err);
    }
  },
  setSeatFilter({ commit }, payload) {
    commit("SET_FILTER", payload);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};

import { version } from "../../../package.json";

const state = {
  appVersion: version || "0",
  step: "departure",
  steps: [
    {
      path: "/search",
      label: "Define Baseline Trip",
      requiredParams: [],
      allowedParams: [
        "origin",
        "destination",
        "departureDate",
        "returnDate",
        "airline",
        "flightClass",
      ],
    },
    {
      path: "/ratings",
      label: "Your Rating Profiles",
      requiredParams: [
        "origin",
        "destination",
        "departureDate",
        "returnDate",
        "airline",
        "flightClass",
        "departureFlight",
        "returnFlight",
      ],
    },
    {
      path: "/results",
      label: "Choose an Offer",
      requiredParams: [
        "origin",
        "destination",
        "departureDate",
        "returnDate",
        "airline",
        "flightClass",
        "departureFlight",
        "returnFlight",
      ],
    },
    {
      path: "/shortlist",
      label: "Compare Offers",
      requiredParams: [
        "origin",
        "destination",
        "departureDate",
        "returnDate",
        "airline",
        "flightClass",
        "departureFlight",
        "returnFlight",
        "offers",
      ],
    },
  ],
  origin: null,
  destination: null,
  departureDate: null,
  returnDate: null,
  airline: null,
  flightClass: null,
  attributeLabels: {
    seatWidth: "Seat Width",
    seatPitch: "Seat Legroom",
    seatRecline: "Seat Recline",
    seatPrivacy: "Seat Privacy",
    // videoType: "Video Type",
    // laptopPower: "Laptop Power",
    // wiFi: "Wi-Fi"
  },
  attributeWeights: {
    departure: {
      seatWidth: 5,
      seatPitch: 5,
      seatRecline: 5,
      seatPrivacy: 5,
      // videoType: 5,
      // laptopPower: 5,
      // wiFi: 5
    },
    return: {
      seatWidth: 5,
      seatPitch: 5,
      seatRecline: 5,
      seatPrivacy: 5,
      // videoType: 5,
      // laptopPower: 5,
      // wiFi: 5
    },
  },
  ratingPresets: [],
  currentMenuView: null,
  seats: [],
};

export default state;

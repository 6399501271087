<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(item, i) in parsedLogs" :key="i">
      <v-expansion-panel-header>
        <div class="d-flex align-center">
          <v-chip
            :color="
              [500, 404, 204].includes(item.status)
                ? 'red'
                : item.type === 'request'
                ? 'blue'
                : 'green'
            "
            dark
            label
            small
            >{{ item.status }} {{ item.type.toUpperCase() }}</v-chip
          >
          <span class="ml-4">to {{ item.origin }} </span>
          <span v-if="item.version" class="ml-8 text-caption grey--text"
            >Backend version: {{ item.version }}</span
          >
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <h5>Request:</h5>
        <code
          style="
            max-width: 100%;
            overflow-x: auto;
            display: block;
            white-space: nowrap;
          "
          >{{ item.endpoint }}</code
        >
        <json-viewer
          v-if="item.type === 'response' && item.response"
          boxed
          :expand-depth="3"
          :expanded="true"
          :value="item.response"
        ></json-viewer>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import JsonViewer from "vue-json-viewer";

export default {
  name: "RequestLogger",
  components: {
    JsonViewer,
  },
  computed: {
    ...mapState("logs", ["logs"]),
    parsedLogs() {
      return this.logs.map((l) => {
        return {
          type: l.type,
          origin: l.origin || null,
          status: l.payload.status || null,
          version: l.payload.version || null,
          endpoint: l.type === "request" ? l.payload : l.payload.request,
          response: l.type === "response" ? l.payload.response : null,
        };
      });
    },
  },
};
</script>

<template>
  <v-container style="max-width: 700px">
    <v-row v-if="error">
      <v-col>
        <v-card class="pa-6">
          <v-card-text class="text-center">
            <h4 v-if="error.code" v-html="`ERROR ${error.code}`"></h4>
            <h2
              v-if="error.message"
              class="text-h4 mb-6"
              v-html="error.message"
            ></h2>
            <p
              v-if="error.description"
              class="text-subtitle-1"
              v-html="error.description"
            ></p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    error: {
      default: () => {}
    }
  }
};
</script>

<template>
  <v-dialog v-model="offerDialog" max-width="800">
    <v-card>
      <div class="d-flex flex-column" style="max-height: 90vh">
        <div
          v-if="!selectedOfferWithRatings"
          class="d-flex py-2 px-4 align-center"
        >
          <h3 class="text-h6">Offer Details</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          v-if="selectedOfferWithRatings && offerDialogView !== 'booking'"
          class="d-flex pa-2 align-center"
        >
          <h3 class="text-h6">
            {{ selectedOfferWithRatings.outbound.departure.code }} to
            {{ selectedOfferWithRatings.inbound.departure.code }}
          </h3>
          <div class="ml-4">
            <template
              v-if="currentSelectedOffer.cost !== selectedOfferWithRatings.cost"
            >
              <span class="text-h6 text-decoration-line-through red--text ml-1"
                >${{ currentSelectedOffer.cost }}</span
              >
            </template>
            <span class="text-h5 green--text ml-1"
              ><strong>${{ selectedOfferWithRatings.cost }}</strong></span
            >
          </div>
          <v-spacer></v-spacer>
          <v-checkbox
            v-model="addedToShortlist"
            label="Add to Comparison"
            class="mr-4"
          ></v-checkbox>
          <v-btn
            v-if="!isCurrentOfferAlreadyBooked"
            color="primary"
            elevation="2"
            large
            class="mr-4"
            @click.stop="getBookingLink"
          >
            Book this Offer
          </v-btn>
          <!-- <span
            v-if="currentSelectedOffer.cost !== selectedOfferWithRatings.cost"
            class="blue lighten-5 text-h6 font-weight-bold blue--text text--lighten-1 py-1 px-4 mr-4 rounded"
            >Already Booked</span
          > -->
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          v-if="selectedOfferWithRatings && offerDialogView === 'booking'"
          class="d-flex pa-2 align-center"
        >
          <h3 class="text-h6">Book your selected Offer</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>
        <div class="flex-grow-1 overflow-y-auto">
          <div
            v-if="offerLoading"
            class="d-flex align-items justify-center pa-10"
            style="height: 100%; width: 100%"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              :size="80"
            ></v-progress-circular>
          </div>
          <error-message
            v-if="offerError && !offerLoading"
            :error="offerError"
          ></error-message>
          <v-alert
            v-if="
              currentSelectedOffer &&
              !offerLoading &&
              currentView.indexOf('cancel') === -1 &&
              currentView.indexOf('change') === -1 &&
              !offerError &&
              isCurrentOfferAlreadyBooked
            "
            text
            type="info"
            prominent
          >
            <v-row align="center">
              <v-col class="grow">
                <h4>You already booked this offer.</h4>
                <p class="ma-0">What do you want to do?</p>
              </v-col>
              <v-col class="shrink">
                <div class="d-flex align-center">
                  <v-btn small @click="setOfferDetailsView('cancel.policy')"
                    >Cancel Booking</v-btn
                  >
                  <v-btn
                    small
                    class="ml-4"
                    @click="setOfferDetailsView('change.policy')"
                    >Change Ticket</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-alert>

          <offer-details
            v-if="
              currentSelectedOffer &&
              !offerLoading &&
              showDetails &&
              !offerError
            "
          ></offer-details>
          <ticket-cancel-info
            v-if="currentView.indexOf('cancel') !== -1"
          ></ticket-cancel-info>
          <ticket-change-info
            v-if="currentView.indexOf('change') !== -1"
          ></ticket-change-info>
          <booking-info
            v-if="
              selectedOfferWithRatings &&
              currentView === 'booking' &&
              typeof selectedOfferWithRatings.booking !== 'undefined' &&
              !offerError
            "
            :baseline="currentBaseline"
            :offer="selectedOfferWithRatings"
          ></booking-info>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import OfferDetails from "./OfferDetails";
import BookingInfo from "./BookingInfo";
import ErrorMessage from "./ErrorMessage";
import TicketCancelInfo from "./Policies/TicketCancelInfo";
import TicketChangeInfo from "./Policies/TicketChangeInfo";

export default {
  name: "OfferDialog",
  components: {
    OfferDetails,
    BookingInfo,
    ErrorMessage,
    TicketCancelInfo,
    TicketChangeInfo,
  },
  computed: {
    ...mapState(["flightClass"]),
    ...mapState("offers", [
      "offerDialog",
      "offerLoading",
      "currentSelectedOffer",
      "offerError",
      "currentBaseline",
      "offerDialogView",
      "shortlist",
    ]),
    ...mapGetters("offers", [
      "selectedOfferWithRatings",
      "baselineOffer",
      "isCurrentOfferAlreadyBooked",
    ]),
    currentView: {
      get() {
        return this.offerDialogView || "details.formatted";
      },
      set(view) {
        console.log("setOfferDetailsView", view);
        this.setOfferDetailsView(view);
      },
    },
    showDetails() {
      return this.currentView.indexOf("details") !== -1;
    },
    addedToShortlist: {
      get() {
        return this.shortlist.includes(this.selectedOfferWithRatings.id);
      },
      set(isIncluded) {
        let shortlist = [...this.shortlist];
        if (
          isIncluded &&
          !shortlist.includes(this.selectedOfferWithRatings.id)
        ) {
          shortlist.push(this.selectedOfferWithRatings.id);
        }
        if (
          !isIncluded &&
          shortlist.includes(this.selectedOfferWithRatings.id)
        ) {
          shortlist.splice(
            shortlist.indexOf(this.selectedOfferWithRatings.id),
            1
          );
        }

        this.setShortlist(shortlist);
      },
    },
  },
  methods: {
    close() {
      this.closeDialog();
      this.setOfferDetailsView("details");
    },
    getBookingLink() {
      this.getCurrentOfferBookingLinks().then(() => {
        this.setOfferDetailsView("booking");
      });
    },
    ...mapActions("offers", [
      "closeDialog",
      "getCurrentOfferBookingLinks",
      "setOfferDetailsView",
      "setShortlist",
    ]),
  },
};
</script>

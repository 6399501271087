<template>
  <v-navigation-drawer
    v-model="active"
    fixed
    right
    temporary
    hide-overlay
    tag="aside"
    style="z-index: 20"
    disable-route-watcher
  >
    <div class="d-flex align-center px-4 pa-3">
      <h3 class="text-h6">Feature flags</h3>
      <v-spacer></v-spacer>
      <v-btn icon @click="active = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <v-list-item>
      <v-btn small color="primary" @click="resetFlags">Reset Flags</v-btn>
    </v-list-item>
    <v-list-group :value="true">
      <template #activator>
        <v-list-item-content>
          <v-list-item-title>Servers</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item>
        <v-select
          v-model="flagServerApiSource"
          label="Source Server"
          :items="serversItems"
          item-text="label"
          item-value="value"
        ></v-select>
      </v-list-item>
    </v-list-group>
    <v-list-group :value="true">
      <template #activator>
        <v-list-item-content>
          <v-list-item-title>Offers</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item>
        <v-switch
          v-model="flagApplyFilters"
          hide-details
          dense
          label="Apply filters to Offers"
        ></v-switch>
      </v-list-item>
      <v-list-item>
        <v-switch
          v-model="flagHideSignals"
          hide-details
          dense
          label="Hide Signals"
        ></v-switch>
      </v-list-item>
      <v-list-item>
        <v-switch
          v-model="flagGroupCodesharedOffers"
          hide-details
          dense
          label="Group Codeshared Offers"
        ></v-switch>
      </v-list-item>
      <v-list-item>
        <v-switch
          v-model="flagEnableRequestLogger"
          hide-details
          dense
          label="Enable Request Logger"
        ></v-switch>
      </v-list-item>
    </v-list-group>
    <v-list-group :value="false">
      <template #activator>
        <v-list-item-content>
          <v-list-item-title>Offers Landscape</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item>
        <v-switch
          v-model="flagToggleXScale"
          hide-details
          dense
          label="Toggle X Scale"
        ></v-switch>
      </v-list-item>
    </v-list-group>
    <v-list-group :value="true">
      <template #activator>
        <v-list-item-content>
          <v-list-item-title>Offers Grid</v-list-item-title>
        </v-list-item-content>
      </template>
      <draggable v-model="flagOffersColsOrder" handle=".handle">
        <v-list-item
          v-for="col in offersColumns"
          :key="`flag-offer-col-${col.key}`"
          class="d-flex"
        >
          <v-checkbox
            v-model="flagOffersCols"
            :value="col.key"
            dense
            hide-details=""
            :label="col.label"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-icon class="handle" style="cursor: move">mdi-drag</v-icon>
        </v-list-item>
      </draggable>
    </v-list-group>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";
import { servers } from "../modules/servers";

export default {
  name: "FeatureFlags",
  components: {
    draggable,
  },
  data() {
    return {
      offersListCols: {
        select: "Select",
        flights: "Flights",
        "outbound.departure.at": "Departure Date",
        "inbound.departure.at": "Return Date",
        rating: "Rating",
        userRating: "User Rating",
        "cost.amount": "Price",
        costPerHour: "Cost / Hour",
        offerValue: "Cost / Point",
        upgradeCost: "Upgrade Cost",
        "upgradeBenefit.total": "Upgrade Benefit",
        quadrant: "Quadrant",
        actions: "Actions",
      },
    };
  },
  computed: {
    ...mapState("flags", [
      "shouldApplyFilters",
      "groupCodesharedOffers",
      "hideSignals",
      "offersColumns",
      "isActive",
      "toggleXScale",
      "apiSource",
      "enableRequestLogger",
    ]),
    serversItems() {
      return Object.keys(servers).map((k) => {
        return {
          label: servers[k].name,
          value: k,
        };
      });
    },
    flagApplyFilters: {
      get() {
        return this.shouldApplyFilters;
      },
      set(val) {
        this.setFlag({
          flag: "shouldApplyFilters",
          value: val,
        });
      },
    },
    flagGroupCodesharedOffers: {
      get() {
        return this.groupCodesharedOffers;
      },
      set(val) {
        this.setFlag({
          flag: "groupCodesharedOffers",
          value: val,
        });
      },
    },
    flagHideSignals: {
      get() {
        return this.hideSignals;
      },
      set(val) {
        this.setFlag({
          flag: "hideSignals",
          value: val,
        });
      },
    },
    flagToggleXScale: {
      get() {
        return this.toggleXScale;
      },
      set(val) {
        this.setFlag({
          flag: "toggleXScale",
          value: val,
        });
      },
    },
    flagEnableRequestLogger: {
      get() {
        return this.enableRequestLogger;
      },
      set(val) {
        this.setFlag({
          flag: "enableRequestLogger",
          value: val,
        });
      },
    },
    active: {
      get() {
        return this.isActive;
      },
      set(val) {
        this.setFlag({
          flag: "isActive",
          value: val,
        });
      },
    },
    flagOffersCols: {
      get() {
        return this.offersColumns.filter((c) => c.display).map((c) => c.key);
      },
      set(val) {
        console.log("flagOffersCols", val);
        let cols = [...this.offersColumns];

        cols.map((c) => {
          c.display = val.includes(c.key);
          return c;
        });
        this.setFlag({
          flag: "offersColumns",
          value: cols,
        });
      },
    },
    flagOffersColsOrder: {
      get() {
        return this.offersColumns;
      },
      set(val) {
        console.log("flagOffersCols", val);
        this.setFlag({
          flag: "offersColumns",
          value: val,
        });
      },
    },
    flagServerApiSource: {
      get() {
        return this.apiSource;
      },
      set(val) {
        this.setFlag({
          flag: "apiSource",
          value: val,
        });
        window.location.reload();
      },
    },
  },
  methods: {
    ...mapActions("flags", ["setFlag", "resetFlags"]),
  },
};
</script>

<template>
  <v-simple-table v-if="!offersLoading && selectedOffersWithRatings.length > 0">
    <thead>
      <tr>
        <th></th>
        <th style="background-color: #fafafa">
          {{ parsedBaseline.offerName }}
          <div class="text-caption">Your Baseline Trip</div>
        </th>
        <th
          v-for="(header, k) in parsedHeaders"
          :key="k"
          :style="getCellClass(k)"
        >
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(map, rowIndex) in parsedBodyMap" :key="map.type">
        <td>
          <strong>{{ map.label }}</strong>
        </td>
        <td style="background-color: #fafafa">
          <template v-if="map.type.toLowerCase().indexOf('rating') !== -1">
            <v-chip>{{ parsedBaseline[map.type] }}</v-chip>
          </template>
          <template
            v-if="
              [
                'departureId',
                'departureCabinClass',
                'returnId',
                'returnCabinClass',
                'departureCost',
                'returnCost',
                'departureItinerary',
                'returnItinerary',
              ].indexOf(map.type) !== -1
            "
          >
            <div v-html="parsedBaseline[map.type]"></div>
          </template>
          <template v-if="map.type === 'details'">
            <slot name="action" :offer="baseline"></slot>
          </template>
          <template v-if="map.type === 'cost'">{{
            parsedBaseline[map.type]
          }}</template>
        </td>
        <td v-for="(value, i) in parsedBody[rowIndex]" :key="i">
          <template v-if="map.type.toLowerCase().indexOf('rating') !== -1">
            <v-chip>{{ value }}</v-chip>
          </template>
          <template
            v-if="
              [
                'departureId',
                'departureCabinClass',
                'returnId',
                'returnCabinClass',
                'departureCost',
                'returnCost',
                'departureItinerary',
                'returnItinerary',
              ].indexOf(map.type) !== -1
            "
          >
            <div v-html="value"></div>
          </template>
          <template v-if="map.type === 'details'">
            <slot name="action" :offer="selectedOffersWithRatings[i]"></slot>
          </template>
          <template v-if="map.type === 'cost'">{{ value }}</template>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { format, parseISO } from "date-fns";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CompareOffersList",
  data() {
    return {
      dataMap: [
        {
          type: "offerName",
          label: "",
        },
        {
          type: "rating",
          label: "Offer System Rating",
        },
        {
          type: "userRating",
          label: "Offer User Rating",
        },
        {
          type: "cabinRating",
          label: "Offer Cabin Rating",
        },
        {
          type: "cost",
          label: "Offer Total Cost",
        },
        {
          type: "departureId",
          label: "Departure Flight",
        },
        {
          type: "departureCabinClass",
          label: "Departure Flight Cabin Class",
        },
        {
          type: "departureItinerary",
          label: "Departure Flight Itinerary",
        },
        {
          type: "departureRating",
          label: "Departure Flight System Rating",
        },
        {
          type: "departureUserRating",
          label: "Departure Flight User Rating",
        },
        {
          type: "departureCabinRating",
          label: "Departure Flight Cabin Rating",
        },
        // {
        //   type: "departureCost",
        //   label: "Departure Flight Cost"
        // },
        {
          type: "returnId",
          label: "Return Flight",
        },
        {
          type: "returnCabinClass",
          label: "Return Flight Cabin Class",
        },
        {
          type: "returnItinerary",
          label: "Return Flight Itinerary",
        },
        {
          type: "returnRating",
          label: "Return Flight Rating",
        },
        {
          type: "returnUserRating",
          label: "Return Flight User Rating",
        },
        {
          type: "returnCabinRating",
          label: "Return Flight Cabin Rating",
        },
        // {
        //   type: "returnCost",
        //   label: "Return Flight Cost"
        // },
        {
          type: "details",
          label: "More Details",
        },
      ],
    };
  },
  computed: {
    baseline() {
      return this.selectedOffersWithRatings.find((o) => o.isBaseline);
    },
    offers() {
      return this.selectedOffersWithRatings.filter((o) => !o.isBaseline);
    },
    parsedBaseline() {
      let baseline = {};

      this.dataMap.forEach((map) => {
        baseline[map.type] = this.formatData(this.baseline, map.type);
      });

      return baseline;
    },
    parsedData() {
      if (this.offers.length === 0 || !this.flightClass) {
        return [];
      }

      let rows = [];

      this.dataMap.forEach((map) => {
        let row = [];

        this.offers.forEach((o) => {
          row.push(this.formatData(o, map.type));
        });

        rows.push(row);
      });

      return rows;
    },
    parsedHeaders() {
      return this.parsedData[0];
    },
    parsedBody() {
      return this.parsedData.slice(1);
    },
    parsedBodyMap() {
      return { ...this.dataMap.filter((t) => t.type !== "offerName") };
    },
    ...mapState(["flightClass", "attributeWeights"]),
    ...mapState("offers", ["offersLoading"]),
    ...mapGetters("offers", ["selectedOffersWithRatings"]),
  },
  methods: {
    formatData(offer, type) {
      if (type === "offerName") {
        return `${offer.outbound.carrier.code}-${offer.outbound.number} / ${offer.inbound.carrier.code}-${offer.inbound.number}`;
      }

      if (type === "rating") {
        return offer.rating;
      }

      if (type === "userRating") {
        return offer.userRating;
      }

      if (type === "cabinRating") {
        return offer.cabinRating.toFixed(2);
      }

      if (type === "cost") {
        // const outbound = Number(
        //   offer.outbound.cost[0].price.replace(/,/g, "")
        // );
        // const inbound = Number(
        //   offer.inbound.cost[0].price.replace(/,/g, "")
        // );

        return `$${offer.cost}`;
      }

      if (type === "departureId" || type === "returnId") {
        const key = type === "departureId" ? "outbound" : "inbound";
        let string = `${offer[key].carrier.code}-${offer[key].number}`;

        if (offer[key].carrier.code !== offer[key].operating.code) {
          string += `<div class="text-caption">Operated by ${offer[key].operating.name}</div>`;
        }

        return string;
      }

      if (type === "departureCost" || type === "returnCost") {
        const key = type === "departureCost" ? "outbound" : "inbound";

        return `$${Number(offer[key].cost[0].price.replace(/,/g, ""))}`;
      }

      if (type === "departureCabinClass" || type === "returnCabinClass") {
        const key = type === "departureCabinClass" ? "outbound" : "inbound";
        if (typeof offer[key].cost === "undefined") {
          return offer[key].aircraft.cabin.cabinClass;
        } else {
          return offer[key].cost.class;
        }
      }

      if (type === "departureRating" || type === "returnRating") {
        const key = type === "departureRating" ? "outbound" : "inbound";
        if (typeof offer[key].cost === "undefined") {
          return offer[key].aircraft.cabin.rating;
        } else {
          const flightClass = offer[key].cost.class;
          return offer[key].aircraft.cabins[flightClass].rating;
        }
      }

      if (type === "departureUserRating" || type === "returnUserRating") {
        const key = type === "departureUserRating" ? "outbound" : "inbound";
        if (typeof offer[key].cost === "undefined") {
          return offer[key].aircraft.cabin.userRating;
        } else {
          const flightClass = offer[key].cost.class;
          return offer[key].aircraft.cabins[flightClass].userRating;
        }
      }

      if (type === "departureCabinRating" || type === "returnCabinRating") {
        const key = type === "departureCabinRating" ? "outbound" : "inbound";
        if (typeof offer[key].cost === "undefined") {
          return offer[key].aircraft.cabin.cabinRating.toFixed(2);
        } else {
          const flightClass = offer[key].cost.class;
          return offer[key].aircraft.cabins[flightClass].cabinRating.toFixed(2);
        }
      }

      if (type === "departureItinerary" || type === "returnItinerary") {
        const key = type === "departureItinerary" ? "outbound" : "inbound";

        return `
            <h6 class="text-subtitle-2">Departure</h6>
            <div class="text-body-2">${this.formatLocationAndDate(
              offer[key].departure
            )}</div>
            <hr style="height: 1px; border: none; border-top: 1px solid #ddd">
            <h6 class="text-subtitle-2">Arrival</h6>
            <div class="text-body-2">${this.formatLocationAndDate(
              offer[key].arrival
            )}</div>`;
      }

      if (type === "details") {
        return offer.id;
      }
    },
    formatLocationAndDate(obj) {
      return `${obj.code} - ${format(parseISO(obj.at), "MMM d, Y p")}`;
    },
    getCellClass(key) {
      if (key === 0) {
        return {
          backgroundColor: "#FAFAFA",
        };
      }
      return null;
    },
  },
};
</script>

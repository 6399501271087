<template>
  <v-container v-if="offers" class="px-10 py-4" fluid>
    <v-row class="d-flex align-center">
      <v-col md="8" class="d-flex align-center">
        <h1 class="text-h4">Step 3: Choose an Offer</h1>
      </v-col>
    </v-row>
    <v-row v-if="!offersError">
      <v-col md="2">
        <upgrade-levers></upgrade-levers>
      </v-col>
      <v-col md="10" sticky-container>
        <div
          v-if="offersLoading"
          class="d-flex align-center justify-center"
          style="width: 100%; height: 60vh"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="80"
          ></v-progress-circular>
        </div>
        <div v-if="!offersLoading" v-sticky>
          <div class="d-flex align-center mb-2">
            <h2>{{ tabName }}</h2>
            <v-tooltip top color="grey darken-4">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    color="green"
                    dark
                    small
                    class="ml-4"
                    @click="exportToCSV"
                    >Export to CSV</v-btn
                  >
                </div>
              </template>
              <div style="max-width: 250px">
                Click here to export all the offers on the screen to a .csv
                file.
              </div>
            </v-tooltip>
            <div class="ml-auto">
              <offers-list-tabs></offers-list-tabs>
            </div>
            <v-tooltip top color="grey darken-4">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    color="primary"
                    elevation="2"
                    x-large
                    class="ml-8"
                    :disabled="shouldDisableCompareBtn"
                    @click="getOffers"
                    >Compare Offers</v-btn
                  >
                </div>
              </template>
              <div style="max-width: 250px">
                To compare offers, tick the checkboxes on the Offers Grid View
                to select offers, then click here.
              </div>
            </v-tooltip>
          </div>
          <v-row>
            <v-col>
              <offers-tabs></offers-tabs>
            </v-col>
          </v-row>
          <offers-picker v-if="viewType === 'list'"></offers-picker>
          <top-10-upgrades v-if="viewType === 'top10'"></top-10-upgrades>
          <offers-diagram
            v-if="!offersLoading && offers.length > 0 && viewType === 'chart'"
          ></offers-diagram>
          <request-logger v-if="viewType === 'raw'"></request-logger>
        </div>
      </v-col>
    </v-row>
    <error-message
      v-if="offersError && !offersLoading"
      :error="offersError"
    ></error-message>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import OffersDiagram from "./OffersDiagram";
import OffersPicker from "./OffersPicker";
import OffersTabs from "./OffersTabs";
import UpgradeLevers from "./UpgradeLevers";
import Top10Upgrades from "./Top10Upgrades";
import ErrorMessage from "./ErrorMessage";
import RequestLogger from "./RequestLogger";
import OffersListTabs from "./OffersListTabs";

// import api from "../modules/store/api";
import { convert2CSV } from "../modules/export2csv";

export default {
  name: "OffersList",
  components: {
    OffersPicker,
    UpgradeLevers,
    Top10Upgrades,
    OffersDiagram,
    OffersTabs,
    ErrorMessage,
    RequestLogger,
    OffersListTabs,
  },
  computed: {
    applyFilters: {
      get() {
        return this.shouldApplyFilters;
      },
      set(val) {
        this.toggleOfferFilters(val);
      },
    },
    shouldGroupOffers: {
      get() {
        return this.groupCodesharedOffers;
      },
      set(val) {
        this.groupOffers(val);
      },
    },
    shouldHideSignals: {
      get() {
        return this.hideSignals;
      },
      set(val) {
        this.setSignalsVisibility(val);
      },
    },
    shouldDisableCompareBtn() {
      return (
        this.shortlist.length === 0 ||
        (this.filteredOffers &&
          this.filteredOffers.filter((o) => this.shortlist.includes(o.id))
            .length === 0)
      );
    },
    tabName() {
      const map = {
        list: "Offers Grid",
        chart: "Upgrade Landscape",
        top10: "Top 10 Upgrades",
        raw: "Request Logger",
      };

      return map[this.viewType];
    },
    ...mapState("offers", [
      "offers",
      "offersLoading",
      "shortlist",
      "offersError",
      "viewType",
      "shouldApplyFilters",
      "groupCodesharedOffers",
    ]),
    ...mapState("flights", ["baseline"]),
    ...mapState("filters", ["hideSignals"]),
    ...mapState("route", ["query"]),
    ...mapGetters("offers", ["filteredOffers"]),
  },
  methods: {
    ...mapActions("offers", [
      "getSelectedOffers",
      "setLevers",
      "toggleOfferFilters",
      "groupOffers",
    ]),
    ...mapActions("filters", ["setSignalsVisibility"]),
    getOffers() {
      this.getSelectedOffers();
      this.$store.commit("SET_STEP", "shortlist");
      this.$router.push({
        path: "shortlist",
        query: {
          ...this.query,
          offers: this.shortlist,
        },
      });
    },
    exportToCSV() {
      console.log("exportToCSV");
      convert2CSV(this.filteredOffers);
      // try {
      //   const csv = await api.exportOffers(this.filteredOffers);
      //   console.log(csv);
      // } catch (err) {
      //   console.log(err);
      // }
      console.log("finished");
    },
  },
};
</script>

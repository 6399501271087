const state = {
  logs: []
};

const mutations = {
  SET_LOG(state, payload) {
    state.logs.push(payload);
  }
};

const actions = {
  setLog({ commit }, payload) {
    commit("SET_LOG", payload);
  }
};

export default {
  state,
  mutations,
  actions
};

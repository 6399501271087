<template>
  <div>
    <v-data-table
      v-model="shortlist"
      :loading="offersLoading"
      :headers="allowedColumns"
      :items="groupedOffers"
      :items-per-page="10"
      item-key="id"
      class="elevation-2"
      :custom-sort="sortItems"
      sort-by="cost.amount"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }"
    >
      <template #item="{ item }">
        <offers-picker-row :item="item">
          <template #row-selector>
            <input
              v-if="!item.isBaseline"
              v-model="shortlist"
              type="checkbox"
              :value="item.id"
            />
          </template>
          <template #actions>
            <v-btn
              v-if="!item.isBaseline"
              color="white"
              dense
              small
              @click.stop="setBaseline(item.id)"
              >Set Baseline</v-btn
            >
            <v-chip
              v-if="item.isBaseline"
              label
              small
              color="light-blue lighten-4 mb-1"
              >Baseline</v-chip
            >
            <v-btn
              color="primary"
              dense
              small
              elevation="2"
              @click.stop="viewOfferDetails(item.id)"
              >Details</v-btn
            >
          </template>
        </offers-picker-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import OffersPickerRow from "./OffersPickerRow";

export default {
  name: "OffersPicker",
  components: {
    OffersPickerRow,
  },
  data() {
    return {
      offersHeaders: [
        {
          text: "Select",
          sortable: false,
          value: "select",
          width: "2%",
        },
        {
          text: "Flights",
          sortable: false,
          value: "flights",
          width: "20%",
        },
        {
          text: "Departure Date",
          sortable: true,
          value: "outbound.departure.at",
          width: "18%",
        },
        {
          text: "Return Date",
          sortable: true,
          value: "inbound.departure.at",
          width: "18%",
        },
        {
          text: "Rating",
          sortable: true,
          value: "rating",
          width: "5%",
        },
        {
          text: "User Rating",
          sortable: true,
          value: "userRating",
          width: "5%",
        },
        {
          text: "Cabin Rating",
          sortable: true,
          value: "cabinRating",
          width: "5%",
        },
        {
          text: "Price",
          sortable: true,
          value: "cost.amount",
          width: "7%",
        },
        {
          text: "Cost / Hour",
          sortable: true,
          value: "costPerHour",
          width: "5%",
        },
        {
          text: "Cost / Point",
          sortable: true,
          value: "offerValue",
          width: "10%",
        },
        {
          text: "Upgrade Cost",
          sortable: true,
          value: "upgradeCost",
          align: "10%",
        },
        {
          text: "Upgrade Benefit",
          sortable: true,
          value: "upgradeBenefit.total",
          align: "10%",
        },
        {
          text: "Quadrant",
          sortable: true,
          value: "quadrant",
          align: "10%",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    allowedColumns() {
      const keys = this.offersColumns
        .filter((c) => c.display)
        .map((c) => c.key);
      return this.offersHeaders
        .filter((h) => keys.includes(h.value))
        .sort((a, b) => {
          const aIndex = keys.indexOf(a.value);
          const bIndex = keys.indexOf(b.value);
          if (aIndex < bIndex) {
            return -1;
          }
          if (aIndex > bIndex) {
            return 1;
          }
          return 0;
        });
    },
    // allColumns() {
    //   const allowed = ["select", ...this.offersColumns, "actions"];
    //   return this.offersHeaders.filter((h) => allowed.includes(h.value));
    // },
    shortlist: {
      get() {
        return this.$store.state.offers.shortlist;
      },
      set(val) {
        console.log("setShortlist", val);
        this.setShortlist(val);
      },
    },
    ...mapGetters("offers", ["filteredOffers", "groupedOffers"]),
    ...mapState("offers", ["offers", "offersLoading"]),
    ...mapState("flags", ["offersColumns"]),
  },
  methods: {
    ...mapActions("offers", [
      "viewOfferDetails",
      "setShortlist",
      "setCurrentBaselineOffer",
    ]),
    setBaseline(id) {
      const offer = this.filteredOffers.find((o) => o.id === id);

      if (offer) {
        this.setCurrentBaselineOffer(offer);
      }
    },
    sortItems(items, sortBy, sortDesc) {
      // let items = [...raw];
      const baselineIndex = items.findIndex((i) => i.isBaseline);
      const baseline = items.splice(baselineIndex, 1);

      items.sort((a, b) => {
        let prev = a[sortBy[0]];
        let current = b[sortBy[0]];

        if (sortBy[0] === "outbound.departure.at") {
          prev = new Date(a.outbound.departure.at).getTime();
          current = new Date(b.outbound.departure.at).getTime();
        }
        if (sortBy[0] === "inbound.departure.at") {
          prev = new Date(a.inbound.departure.at).getTime();
          current = new Date(b.inbound.departure.at).getTime();
        }
        if (sortBy[0] === "cost.amount") {
          prev = a.cost.amount;
          current = b.cost.amount;
        }
        if (sortBy[0] === "upgradeBenefit.total") {
          prev = b.upgradeBenefit.total;
          current = a.upgradeBenefit.total;
          console.log(prev, current);
        }
        if (prev > current) {
          return 1;
        }
        if (prev < current) {
          return -1;
        }
        return 0;
      });

      if (sortDesc[0]) {
        items.reverse();
      }

      return [...baseline, ...items];
    },
  },
};
</script>

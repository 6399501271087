<template>
  <v-form>
    <v-container style="max-width: 720px">
      <v-row>
        <v-col>
          <h1 class="text-h4">Profile</h1>
          <v-card v-if="user" class="pa-md-6 mt-6">
            <v-card-title>Personal Details</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="firstName"
                    label="First Name"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="lastName"
                    label="Last Name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="user" class="pa-md-6 mt-6">
            <v-card-title>Elite Status</v-card-title>
            <v-card-subtitle
              >If you provide your elite status plans and levels, we can be more
              accurate finding offers that best leverage your
              status.</v-card-subtitle
            >
            <v-card-text>
              <airline-plans-selector></airline-plans-selector>
            </v-card-text>
          </v-card>
          <v-card v-if="user" class="pa-md-6 mt-6">
            <v-card-title>Rating Profiles</v-card-title>
            <v-card-subtitle
              >Manage your selected profiles to improve how we rate offers for
              you.</v-card-subtitle
            >
            <v-card-text>
              <rating-profiles :disable-tabs="false"></rating-profiles>
            </v-card-text>
          </v-card>
          <v-row>
            <v-col class="d-flex justify-end mt-6">
              <v-btn color="primary" elevation="2" large @click="updateUser"
                >Save Profile</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AirlinePlansSelector from "./AirlinePlansSelector";
import RatingProfiles from "./RatingProfiles";

export default {
  name: "Profile",
  components: {
    AirlinePlansSelector,
    RatingProfiles,
  },
  computed: {
    ...mapState("profile", ["user"]),
    firstName: {
      get() {
        return this.user.user_metadata.first_name || null;
      },
      set(val) {
        this.$store.commit("profile/SET_USER_META", {
          first_name: val,
        });
      },
    },
    lastName: {
      get() {
        return this.user.user_metadata.last_name || null;
      },
      set(val) {
        this.$store.commit("profile/SET_USER_META", {
          last_name: val,
        });
      },
    },
    // zipCode: {
    //   get() {
    //     return this.user.user_metadata.zip_code || null;
    //   },
    //   set(val) {
    //     this.$store.commit("profile/SET_USER_META", {
    //       zip_code: val
    //     });
    //   }
    // }
  },
  methods: {
    ...mapActions("profile", ["updateUser"]),
    //   updateUserSettings() {
    //     let user = {
    //       first_name: this.firstName,
    //       last_name: this.lastName,
    //     };

    //     this.updateUser(user);
    //   },
  },
  // mounted() {
  //   const elements = recurly.Elements();
  //   const cardElement = elements.CardElement({
  //     style: {
  //       fontSize: "1em",
  //       padding: "1em",
  //       fontFamily: "Roboto",
  //       placeholder: {
  //         color: "gray !important",
  //         content: {
  //           number: "Card number",
  //           cvv: "CVC"
  //         }
  //       },
  //       invalid: {
  //         fontColor: "red"
  //       }
  //     }
  //   });
  //   cardElement.attach("#recurly-elements");
  // }
};
</script>

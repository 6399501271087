import Vue from "vue";
import Vuex from "vuex";
import state from "./store/state";
import getters from "./store/getters";
import mutations from "./store/mutations";
import actions from "./store/actions";
import session from "./store/modules/session";
import profile from "./store/modules/profile";
import flights from "./store/modules/flights";
import offers from "./store/modules/offers";
import seats from "./store/modules/seats";
import billing from "./store/modules/billing";
import airlinePlans from "./store/modules/airlinePlans";
import filters from "./store/modules/filters";
import kb from "./store/modules/kb";
import policies from "./store/modules/policies";
import logs from "./store/modules/logs";
import flags from "./store/modules/flags";

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    session: {
      namespaced: true,
      ...session,
    },
    profile: {
      namespaced: true,
      ...profile,
    },
    airlinePlans: {
      namespaced: true,
      ...airlinePlans,
    },
    flights: {
      namespaced: true,
      ...flights,
    },
    offers: {
      namespaced: true,
      ...offers,
    },
    seats: {
      namespaced: true,
      ...seats,
    },
    billing: {
      namespaced: true,
      ...billing,
    },
    filters: {
      namespaced: true,
      ...filters,
    },
    kb: {
      namespaced: true,
      ...kb,
    },
    policies: {
      namespaced: true,
      ...policies,
    },
    logs: {
      namespaced: true,
      ...logs,
    },
    flags: {
      namespaced: true,
      ...flags,
    },
  },
});

export default store;

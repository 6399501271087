import { getDefaultServer } from "../../servers";
import router from "../../router";
import qs from "qs";

// default set of flags
const defaults = {
  shouldApplyFilters: true,
  hideSignals: false,
  groupCodesharedOffers: true,
  toggleXScale: false,
  enableRequestLogger: false,
  offersColumns: [
    {
      label: "Select",
      key: "select",
      display: true,
    },
    {
      label: "Flights",
      key: "flights",
      display: true,
    },
    {
      label: "Departure Date",
      key: "outbound.departure.at",
      display: true,
    },
    {
      label: "Return Date",
      key: "inbound.departure.at",
      display: true,
    },
    {
      label: "Rating",
      key: "rating",
      display: true,
    },
    {
      label: "User Rating",
      key: "userRating",
      display: true,
    },
    {
      label: "Cabin Rating",
      key: "cabinRating",
      display: false,
    },
    {
      label: "Price",
      key: "cost.amount",
      display: true,
    },
    {
      label: "Cost / Hour",
      key: "costPerHour",
      display: true,
    },
    {
      label: "Cost / Point",
      key: "offerValue",
      display: true,
    },
    {
      label: "Upgrade Cost",
      key: "upgradeCost",
      display: false,
    },
    {
      label: "Upgrade Benefit",
      key: "upgradeBenefit.total",
      display: false,
    },
    {
      label: "Quadrant",
      key: "quadrant",
      display: false,
    },
    {
      label: "Actions",
      key: "actions",
      display: true,
    },
  ],
  apiSource: getDefaultServer(),
};

let storedState;
/**
 * Get flags included at the URL, flags stored on localState, and merge them together.
 * Flags on the URL has precedence over localStorage flags.
 *
 * @since 0.9.8
 *
 * @returns
 */
const setupFlags = () => {
  const query = window.location.search,
    parsed = qs.parse(query);

  if (typeof parsed.flags === "undefined") {
    return;
  }

  const parsedFlags = JSON.parse(atob(parsed.flags));
  const localState = localStorage.getItem("UEfeatFlags")
    ? JSON.parse(localStorage.getItem("UEfeatFlags"))
    : defaults;

  storedState = { ...localState, ...parsedFlags };

  localStorage.setItem("UEfeatFlags", JSON.stringify(storedState));
};

setupFlags();

// Merge defaults with current stored state.
// Ensure that if we add new feature flags, the app won't break because flag definitions are missing.
const currentState = { ...defaults, ...storedState };

const state = {
  shouldApplyFilters: currentState.shouldApplyFilters || true,
  groupCodesharedOffers: currentState.groupCodesharedOffers || true,
  hideSignals: currentState.hideSignals || false,
  offersColumns: currentState.offersColumns || defaults.offersColumns,
  toggleXScale: currentState.toggleXScale || false,
  enableRequestLogger: currentState.enableRequestLogger || false,
  apiSource: currentState.apiSource || "test",
  isActive: false,
};

const getters = {
  encodedFlags: (state) => {
    const flags = { ...state };
    delete flags.isActive;

    const string = JSON.stringify(flags),
      encoded = btoa(string);

    return encoded;
  },
};

const mutations = {
  SET_FLAG(state, payload) {
    state[payload.flag] = payload.value;
    if (payload.key !== "isActive") {
      currentState[payload.flag] = payload.value;
      localStorage.setItem("UEfeatFlags", JSON.stringify(currentState));
    }
  },
};

const actions = {
  /**
   * Commit flag change, ensuring that it's stored on local state and on the URL level.
   *
   * @since v0.8.69
   *
   * @param {Object} payload object containing the flag key and it's new value.
   */
  setFlag({ commit, getters, rootState }, payload) {
    commit("SET_FLAG", payload);
    if (payload.flag !== "isActive") {
      router.push({
        path: rootState.route.path,
        query: {
          ...rootState.route.query,
          flags: getters.encodedFlags,
        },
      });
    }
  },
  /**
   * If for some reason the flags system is broken - due to a flag schema change, for example - this
   * action reset the stored flags and reloads the page.
   *
   * @since 0.8.69
   */
  resetFlags({ rootState }) {
    localStorage.removeItem("UEfeatFlags");
    const query = { ...rootState.route.query };

    if (typeof query.flags !== "undefined") {
      delete query.flags;
    }

    router.push({
      path: rootState.route.path,
      query,
    });

    window.location.reload();
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};

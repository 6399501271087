<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
    <v-alert v-if="showErrorMessage"
      dense

      type="error"
    >
     Wrong password, please try again
    </v-alert>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Enter Password
        </v-card-title>

         <v-text-field
           @input="update"

          ></v-text-field>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="submit($router)"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
        dialog: true,
        showErrorMessage: false,
        name: ""
    };
  },

  methods: {
    submit(router) {
        if(this.name == import.meta.env.VITE_UE_PASSWORD) {
          localStorage.setItem("sessionExists", true);
        router.push('landingPage')
        } else {
          this.showErrorMessage = true;
        router.push('/')
        }
    },
    update(event) {
this.name=event;
    }
  },
};
</script>

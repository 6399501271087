import store from "./store";
/**
 * Helper action to verify if user has a specific permission.
 *
 * @since v0.8
 *
 * @param {String} permission the permission name
 */
export default function(permission) {
  const permissions = store.getters["session/userPermissions"];

  if (!permissions) {
    return false;
  }
  return permissions.includes(permission);
}

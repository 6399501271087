<template>
  <v-row>
    <v-col v-if="user && currentPlans">
      <v-autocomplete
        v-model="currentPlans"
        :items="plansList"
        item-text="plan"
        item-value="id"
        label="Airline Plans"
        auto-select-first
        chips
        clearable
        deletable-chips
        multiple
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-html="item.plan"></v-list-item-title>
            <v-list-item-subtitle
              v-html="getPlanString(item)"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AirlinePlansSelector",
  // data() {
  //   return {
  //     currentPlans: []
  //   };
  // },
  computed: {
    // userMetaString() {
    //   return this.user ? JSON.stringify(this.user.user_metadata) : null;
    // },
    ...mapState("profile", ["user"]),
    ...mapState("airlinePlans", {
      airlinePlansLoading: (state) => state.loading,
      plansList: (state) => state.plans,
    }),
    currentPlans: {
      get() {
        if (!this.user) {
          return [];
        }
        const ids = this.user.user_metadata.airline_plans
          ? this.user.user_metadata.airline_plans
          : [];

        return this.plansList.filter((p) => ids.includes(p.id));
      },
      set(val) {
        this.$store.commit("profile/SET_USER_META", {
          airline_plans: val,
        });
      },
    },
  },
  methods: {
    ...mapActions("profile", ["updateUser"]),
    ...mapActions("airlinePlans", ["getPlans"]),
    getPlanLevels(planId) {
      const plan = this.plansList.find((p) => p.id === planId);

      if (!plan) {
        return [];
      }

      return plan.levels;
    },
    getPlanString(item) {
      // return `${item.airline.name} and other ${item.alliance} partners`;
      return `${item.airline.name} and other partners`;
    },
    getBenefitsString(item) {
      return `${item.benefit.type} ${item.benefit.timeframe}`;
    },
    // addPlan() {
    //   let current = this.currentPlans ? [...this.currentPlans] : [];
    //   current.push(null);
    //   this.currentPlans = current;
    // },
    // removePlan(key) {
    //   let current = this.currentPlans ? [...this.currentPlans] : [];
    //   current.splice(key, 1);

    //   this.currentPlans = current;
    // }
  },
  created() {
    this.getPlans();
    // if (
    //   this.user &&
    //   typeof this.user.user_metadata.airline_plans !== "undefined"
    // ) {
    //   this.currentPlans = this.plansList.filter(p =>
    //     this.user.user_metadata.airline_plans.includes(p)
    //   );
    // }
  },
  // watch: {
  //   userMetaString() {
  //     if (
  //       this.user &&
  //       typeof this.user.user_metadata.airline_plans !== "undefined"
  //     ) {
  //       this.currentPlans = this.user.user_metadata.airline_plans;
  //     }
  //   },
  //   currentPlans(val) {}
  // }
};
</script>

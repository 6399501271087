import api from "../api";
import dashify from "dashify";

const state = {
  loading: true,
  loadingCosts: false,
  allianceMapping: null,
  cityMapping: null,
  quadrants: {
    type: "single",
    label: "Quadrants",
    description: "Choose what type of upgrade you want to see",
    checkAll: false,
    options: [
      {
        label: "All Offers",
        value: "overview",
        selected: true,
      },
      {
        label: "Summary",
        value: "summary",
        selected: false,
      },
      {
        label: "Paid Upgrades",
        value: "paidupgrades",
        description: "Higher-rated flights for more money.",
        subLabel: "More for more.",
        color: "64, 115, 191",
        selected: false,
      },
      {
        label: "Free Upgrades",
        value: "freeupgrades",
        description: "Higher-rated flights for similar money.",
        subLabel: "More for a little more or less.",
        color: "166, 191, 64",
        selected: false,
      },
      {
        label: "Better + Cheaper Upgrades",
        oldLabel: "Better than free",
        value: "betterthanfree",
        description: "Higher-rated flights for LESS money.",
        subLabel: "More for Less. Our favorite.",
        color: "64, 191, 115",
        selected: false,
      },
      {
        label: "Cheaper But Downgrade",
        oldLabel: "Savings Downgrades",
        value: "savingsdowngrades",
        description: "Lower-rated flights for less money.",
        subLabel: "Less for less.",
        color: "166, 64, 191",
        selected: false,
      },
      {
        label: "No-Go Zone",
        oldLabel: "Trash",
        value: "trash",
        description: "Less value for more money.",
        subLabel: "Trash we take out for you.",
        color: "191, 64, 64",
        selected: false,
      },
    ],
  },
  destinations: {
    type: "multiple",
    label: "Alternate Cities",
    description: "Some destination cities might be a better option.",
    checkAll: true,
    options: [],
  },
  price: {
    type: "range",
    label: "Offer Price",
    checkAll: true,
    range: {
      min: 0,
      max: 0,
    },
    selected: [0, 0],
  },
  alliances: {
    type: "multiple",
    label: "Alliances",
    description: "Select which alliances you want to include on the results.",
    checkAll: true,
    options: [],
  },
  airlines: {
    type: "multiple",
    label: "Airlines",
    description: "Select which airlines you want to include on the results.",
    checkAll: true,
    options: [],
  },
  dateFlexibility: {
    type: "multiple",
    label: "Date Flexibility",
    description:
      "Similar departure and return dates might give you better offers.",
    permissions: [],
    options: [
      {
        label: "-3 days",
        value: -3,
        selected: false,
      },
      {
        label: "-2 days",
        value: -2,
        selected: false,
      },
      {
        label: "-1 day",
        value: -1,
        selected: false,
      },
      {
        label: "None",
        value: 0,
        selected: false,
      },
      {
        label: "+ 1 day",
        value: 1,
        selected: false,
      },
      {
        label: "+ 2 days",
        value: 2,
        selected: false,
      },
      {
        label: "+ 3 days",
        value: 3,
        selected: false,
      },
    ],
    min_steps: 0,
    max_steps: 6,
  },
  mixedCabin: {
    type: "boolean",
    label: "Mixed Cabin Offers",
    description: "Include Mixed cabin offers on the list of results",
    // permissions: ["read:mixed_cabin_offers"],
    selected: true,
  },
  cabins: {
    type: "multiple",
    label: "Cabin Flexibility",
    description: "Select which cabin classes you want to fly on",
    permissions: [],
    options: [
      {
        label: "Economy / Economy",
        value: "EE",
        selected: false,
      },
      {
        label: "Premium Economy / Premium Economy",
        value: "PP",
        selected: false,
      },
      {
        label: "Business / Business",
        value: "BB",
        selected: false,
      },
      {
        label: "First / First",
        value: "FF",
        selected: false,
        // permissions: ["read:first_class_offers"]
      },
    ],
  },
  cabinRules: [],
  dictionary: {
    cabins: {
      E: "Economy",
      P: "Premium Economy",
      B: "Business",
      F: "First",
    },
  },
  upgradeCosts: null,
  // hideSignals: false,
};

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_LOADING_COSTS: (state, loading) => {
    state.loadingCosts = loading;
  },
  SET_ALLIANCES: (state, alliances) => {
    state.allianceMapping = Object.fromEntries(
      alliances.map((al) => [al.slug, al.airlines.map((a) => a.iata)])
    );

    const mapped = alliances.map((alliance) => {
      return {
        label: alliance.name,
        value: alliance.slug,
        selected: false,
      };
    });

    state.alliances.options = mapped.sort((a, b) => {
      if (a.label < b.label) return -1;
      return a.label > b.label ? 1 : 0;
    });

    let airlines = alliances
      .map((a) => Object.values(a.airlines))
      .reduce((p, c) => {
        p = p.concat(c);
        return p;
      }, []);

    const airlinesMapped = airlines.map((a) => {
      return {
        label: a.name,
        value: a.iata,
        logo: a.logo,
        selected: false,
      };
    });

    state.airlines.options = airlinesMapped.sort((a, b) => {
      if (a.label < b.label) return -1;
      return a.label > b.label ? 1 : 0;
    });
  },
  SET_DESTINATIONS: (state, payload) => {
    let options = [payload.city];
    if (typeof payload.city.alternate !== "undefined") {
      options = options.concat(payload.city.alternate);
    }
    state.destinations.options = options.map((c) => {
      return {
        label: c.name,
        value: c.iata,
        selected: false,
        // selected: c.iata === payload.destination,
      };
    });

    state.cityMapping = {};
    if (payload.city.airports) {
      state.cityMapping[payload.city.iata] = payload.city.airports.map(
        (a) => a.iata
      );
    }
  },
  SET_PRICE_RANGE: (state, payload) => {
    state.price.range.min = Math.floor(payload.min);
    state.price.range.max = Math.ceil(payload.max);
    state.price.selected = [Math.floor(payload.min), Math.ceil(payload.max)];
  },
  SET_SELECTED: (state, payload) => {
    payload.forEach((filter) => {
      if (typeof state[filter.key] === "undefined") {
        return;
      }

      const settings = state[filter.key];

      if (["boolean", "range"].includes(settings.type)) {
        state[filter.key].selected = filter.value;
        return;
      }

      settings.options.forEach((o, k) => {
        if (settings.type === "multiple" || settings.type === "range") {
          state[filter.key].options[k].selected = filter.value.includes(
            o.value
          );
        } else {
          state[filter.key].options[k].selected = filter.value === o.value;
        }
      });
    });
  },
  SET_UPGRADE_COSTS: (state, payload) => {
    if (payload === null) {
      state.upgradeCosts = null;
      return;
    }
    const parsedKeys = {};
    Object.keys(payload).forEach((k) => {
      parsedKeys[dashify(k)] = payload[k];
    });
    state.upgradeCosts = parsedKeys;
  },
  SET_CABIN_RULES(state, cabinRules) {
    state.cabinRules = cabinRules;

    let options = [];
    Object.keys(cabinRules).forEach((k) => {
      const rules = cabinRules[k];
      rules.forEach((r) => {
        if (state.cabins.options.find((o) => o.value === r)) {
          return;
        }
        const currentIndex = options.findIndex((o) => o.value === r);

        if (currentIndex === -1) {
          options.push({
            label: `${state.dictionary.cabins[r[0]]} / ${
              state.dictionary.cabins[r[1]]
            }`,
            value: r,
            selected: false,
            conditions: [
              {
                key: "mixedCabin",
                value: true,
                comparator: "EQUALS",
              },
              // {
              //   key: "cabins",
              //   value: [value],
              //   comparator: "HAS ANY"
              // }
            ],
          });
        }
      });
    });

    state.cabins.options = state.cabins.options.concat(options);
  },
  // SET_SIGNALS_VISIBILITY: (state, visible) => {
  //   state.hideSignals = visible;
  // },
};

const getters = {
  filters: (state) => {
    const allowed = [
      // "quadrants",
      "destinations",
      "price",
      "dateFlexibility",
      "alliances",
      "airlines",
      "mixedCabin",
      "cabins",
    ];

    let filters = {};

    allowed.forEach((key) => {
      filters[key] = state[key];
    });

    return filters;
  },

  selectedCosts: (state, getters) => {
    if (!state.upgradeCosts) {
      return null;
    }
    0;
    // new /signals response format, return signals for the selected quadrant
    if (typeof state.upgradeCosts.overview !== "undefined") {
      const key =
        getters.selectedOptions.quadrants === "summary"
          ? "overview"
          : getters.selectedOptions.quadrants;
      return state.upgradeCosts[key];
    }

    // deprecated /signals response format, return everything
    return state.upgradeCosts;
  },

  filtersWithCosts: (state, getters, rootState) => {
    const costs = getters.selectedCosts;

    if (!costs || rootState.flags.hideSignals) {
      return getters.filters;
    }

    let parsed = [];

    // let parsed = { ...getters.filters };

    // little helper function to format the signals code
    // in a way that we can assign to the right options,
    // and to the proper comparison for codes and values
    function isOptionMatch(k, code, value) {
      // if (k === "cabins") {
      //   return `${code[0]}${code[0]}` === value;
      // }
      if (k === "alliances") {
        return code.toUpperCase() === value;
      }

      if (k === "destinations") {
        const isCity = typeof state.cityMapping[value] !== "undefined";

        if (isCity) {
          return state.cityMapping[value].includes(code);
        }
      }

      return code === value;
    }

    Object.keys(getters.filters).forEach((k) => {
      const filter = getters.filters[k];

      parsed[k] = {};
      Object.assign(parsed[k], filter);

      if (
        typeof costs[k] === "undefined" ||
        k === "quadrants" ||
        k === "price"
      ) {
        return;
      }

      // console.log(k, filter);

      if (filter.type === "boolean") {
        parsed[k].upgradeCost = costs[k];
      } else {
        filter.options.forEach((option, i) => {
          const costOption = costs[k].find((c) => {
            return isOptionMatch(k, c.code, option.value);
          });
          // console.log(costOption);
          if (costOption) {
            parsed[k].options[i].upgradeCost = costOption;
          }
        });
      }
      // console.log(k, parsed[k]);
    });

    // generate the summaries
    Object.keys(parsed).forEach((k) => {
      if (parsed[k].type === "boolean" || k === "quadrants" || k === "price") {
        return;
      }

      const allCosts = parsed[k].options
        .filter((o) => typeof o.upgradeCost !== "undefined")
        .map((o) => o.upgradeCost.minPriceDiff);

      parsed[k].upgradeCost = {
        min: Math.min(...allCosts),
        max: Math.max(...allCosts),
      };
    });

    return parsed;
  },

  selectedOptions: (state) => {
    const keys = [
      "quadrants",
      "price",
      "destinations",
      "dateFlexibility",
      "alliances",
      "airlines",
      "mixedCabin",
      "cabins",
    ];

    let selected = {};
    keys.forEach((k) => {
      if ("boolean" === state[k].type) {
        if (state[k].selected) {
          selected[k] = state[k].selected;
        }
        return;
      }

      if ("range" === state[k].type) {
        if (
          state[k].selected &&
          state[k].selected !== [0, 0] &&
          (state[k].selected[0] !== state[k].range.min ||
            state[k].selected[1] !== state[k].range.max)
        ) {
          selected[k] = state[k].selected;
        }
        return;
      }

      const options = state[k].options;
      const selectedParent = options
        .filter((o) => o.selected)
        .map((o) => o.value);

      // console.log(k, options, selectedParent);

      if (k === "cabins") {
        let combinations;
        if (state.mixedCabin.selected) {
          combinations = selectedParent;
        } else {
          // if mixed cabin is off, only includes single cabin options
          combinations = selectedParent.filter((c) => c[0] === c[1]);
        }

        if (combinations.length > 0) {
          selected[k] = combinations;
        }

        return;
      }

      if (selectedParent.length > 0 && selectedParent.length < options.length) {
        if (
          k === "destinations" &&
          Object.keys(state.cityMapping).some((c) => selectedParent.includes(c))
        ) {
          const airports = selectedParent.reduce((prev, current) => {
            if (typeof state.cityMapping[current] !== "undefined") {
              prev = prev.concat(state.cityMapping[current]);
            }
            return prev;
          }, []);
          selected[k] = [...selectedParent, ...airports];
        } else {
          selected[k] = selectedParent;
        }
      }

      if (k === "quadrants") {
        selected[k] = selectedParent[0];
      }
    });

    return selected;
  },
};

const actions = {
  /**
   * Pull list of airlines and alliances from external database,
   * and generate airlines and alliances filter options.
   *
   * @since v0.7.0
   */
  async getAlliances({ state, commit }) {
    console.log("getAlliances");
    if (state.airlines.options.length > 0) {
      return;
    }
    try {
      const alliances = await api.getAirlines();
      // console.log(alliances);
      commit("SET_ALLIANCES", alliances.data);
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * Pull information about selected destination and it's alternated destinations
   * from external database, and generate destinations filter options.
   *
   * @since v0.7.0
   */
  async getDestinations({ rootState, commit }) {
    if (state.destinations.options.length > 0) {
      return;
    }
    try {
      const destinations = await api.getCities(rootState.destination);
      // console.log(destinations);
      commit("SET_DESTINATIONS", {
        city: destinations.data,
        destination: rootState.destination,
      });
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * Pull list of all mixed cabin class combinations from an external database,
   * and include those options into the cabins filter.
   *
   * @since v0.7.0
   */
  async getCabinRules({ commit }) {
    if (state.cabinRules.length > 0) {
      return;
    }

    try {
      const cabinRules = await api.getCabinRules();
      commit("SET_CABIN_RULES", cabinRules.data);
      return cabinRules.data;
    } catch (err) {
      console.log(err);
    }
  },

  setPriceRange({ commit }, payload) {
    const costs = payload.map((o) => o.cost.amount);
    commit("SET_PRICE_RANGE", {
      min: Math.min(...costs),
      max: Math.max(...costs),
    });
  },

  /**
   * Asynchronous action to pull all the information we need to populate filters,
   * from an external database.
   *
   * @since v0.7.0
   */
  async getAllFilters({ commit, dispatch }) {
    commit("SET_LOADING", true);
    await Promise.all([
      dispatch("getAlliances"),
      dispatch("getDestinations"),
      dispatch("getCabinRules"),
    ]);
    commit("SET_LOADING", false);
  },

  /**
   * Helper action to update selected options for specific filter and verify
   * if we need to also update other filters.
   *
   * @since v0.7.0
   *
   * @param {Array} payload an array of objects, where the object contains the filter key, and the selected values.
   */
  async setFilter({ commit, dispatch }, payload) {
    let parsed = [];

    console.log("setFilter", payload);

    for (const filter of payload) {
      parsed.push(filter);
      const otherFilter = await dispatch("getExtraFilters", filter);
      if (otherFilter) {
        parsed.push(otherFilter);
      }
    }

    commit("SET_SELECTED", parsed);

    // if (
    //   payload.map(p => p.key).some(k => ["cabins", "destinations"].includes(k))
    // ) {
    //   dispatch("offers/getOffers", null, { root: true });
    // }
  },

  /**
   * Asynchronous action to pull offers signals from /signals API endpoint.
   *
   * @since v0.8.0
   *
   * @param {*} params the search parameters for the /signals API endpoint.
   */
  async getSignals({ state, commit, dispatch }, params) {
    commit("SET_LOADING_COSTS", true);

    if (!state.cabinRules) {
      await dispatch("getCabinRules");
      // console.log("rules", rules);
    }

    // console.log("getSignals", getters.filtersWithCosts.cabins.options);

    const signalsParams = {
      ...params,
      Cabins: state.cabins.options.map((o) => o.value).join(","),
    };

    try {
      const signals = await api.getOffersSignals(signalsParams);

      // console.log("signals", signals);

      dispatch(
        "logs/setLog",
        {
          type: "response",
          origin: "/signals",
          payload: {
            request: signals.config.url,
            version: signals.headers
              ? signals.headers["ue-code-version"]
              : null || null,
            response: signals.data,
            status: signals.status,
          },
        },
        {
          root: true,
        }
      );

      // console.log(signals);
      dispatch("setUpgradeCosts", signals.data);
      commit("SET_LOADING_COSTS", false);

      return signals.data;
    } catch (error) {
      console.log("error", error);
    }
  },

  /**
   * Based on a filter and it's selected options, checks if we need to updated
   * other filters as well.
   *
   * @since v0.7.0
   *
   * @param {Object} filter an object containing the filter key and the selected values.
   */
  getExtraFilters({ state, getters }, filter) {
    if (filter.key === "airlines") {
      const alliances = Object.keys(state.allianceMapping).filter((k) => {
        const airlines = state.allianceMapping[k];

        return airlines.every((a) => filter.value.includes(a));
      });

      // if (alliances.length > 0) {
      return {
        key: "alliances",
        value: alliances,
      };
      // }
    }

    if (filter.key === "alliances") {
      const alliances = Object.keys(state.allianceMapping).filter((k) =>
        filter.value.includes(k)
      );

      if (alliances.length > 0) {
        const airlines = alliances
          .map((k) => state.allianceMapping[k])
          .reduce((p, c) => {
            p = p.concat(c);
            return p;
          }, []);

        const allValues = getters.selectedOptions.airlines
          ? [...getters.selectedOptions.airlines].concat(airlines)
          : airlines;

        return {
          key: "airlines",
          value: allValues,
        };
      } else {
        return {
          key: "airlines",
          value: [],
        };
      }
    }
  },

  /**
   * Loading indicator for sidebar filters while we're loading data from external
   * database.
   *
   * @since v0.7.0
   */
  setLoading({ commit }, payload) {
    commit("SET_LOADING", payload);
  },

  // /**
  //  * Helper function to toggle signals UI visibility.
  //  *
  //  * @since v0.8.9
  //  */
  // setSignalsVisibility({ commit }, visible) {
  //   commit("SET_SIGNALS_VISIBILITY", visible);
  // },

  /**
   * Set list of upgrade costs.
   *
   * @since v0.7.0
   *
   * @param {Object} payload an object containing upgrade costs for all options inside all filters.
   */
  setUpgradeCosts({ commit }, payload) {
    commit("SET_UPGRADE_COSTS", payload);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};

<template>
  <div class="d-flex white">
    <button
      v-for="item in quadrants.options"
      :key="`quadrants-${item.value}`"
      class="flex-grow-1 flex-shrink-1 d-flex flex-column"
      @click="setTab(item.value)"
      @mouseover="mouseover(item.value)"
      @mouseleave="mouseleave"
    >
      <div
        class="pt-2 pb-3 px-4 flex-grow-1 d-flex flex-column align-center justify-center"
        style="width: 100%"
      >
        <div
          class="text-subtitle-2 text-uppercase"
          :class="[
            currentTab === item.value
              ? 'black--text'
              : currentHover === item.value
              ? 'black--text'
              : 'grey--text',
          ]"
          style="transition: all 0.2s"
        >
          {{ item.label }}
        </div>
        <div>
          <v-chip
            small
            :style="`background-color:${
              typeof item.color !== 'undefined'
                ? `rgba(${item.color},0.5)`
                : '#ccc'
            }`"
            >{{ offersPerQuadrant[item.value] || 0 }} offers</v-chip
          >
        </div>
      </div>
      <div
        class="mt-auto"
        :class="[currentTab === item.value ? 'blue' : 'transparent']"
        style="height: 4px; width: 100%"
      ></div>
    </button>
  </div>
  <!-- <v-tabs v-model="currentTab" icons-and-text>
    <v-tab
      v-for="item in quadrants.options"
      :key="`quadrants-${item.value}`"
      class="flex-column"
      style="width: 14.28%"
    >
      <div class="p-2">
        <div class="mb-2">{{ item.label }}</div>
        <v-chip
          x-small
          :style="`background-color:${
            typeof item.color !== 'undefined'
              ? `rgba(${item.color},0.5)`
              : '#ccc'
          }`"
          >{{
            offersPerQuadrant[item.value] > 0
              ? offersPerQuadrant[item.value]
              : "no"
          }}
          offers</v-chip
        >
      </div>
    </v-tab>
  </v-tabs> -->
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "OffersTabs",
  data() {
    return {
      currentHover: null,
    };
  },
  computed: {
    currentTab() {
      const tab = this.quadrants.options.find((o) => o.selected);

      return tab.value;
    },
    ...mapState("filters", ["quadrants"]),
    ...mapGetters("offers", ["offersPerQuadrant"]),
  },
  methods: {
    ...mapActions("filters", ["setFilter"]),
    setTab(val) {
      this.setFilter([
        {
          key: "quadrants",
          value: val,
        },
      ]);
    },
    mouseover(val) {
      this.currentHover = val;
    },
    mouseleave() {
      this.currentHover = null;
    },
  },
};
</script>

<template>
  <div class="pa-4 pt-0">
    <v-row class="mb-2 d-flex align-center">
      <v-col>
        <h3>Mileage Earnings for this Offer</h3>
      </v-col>
      <v-col align-self="end">
        <v-switch
          v-model="showAllPrograms"
          dense
          hide-details
          label="Show only Frequent Flyer programs related to this offer"
          class="mt-0"
        ></v-switch>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="parsedEarnings"
      :items-per-page="-1"
      class="elevation-2"
    >
      <template #item="{ item }">
        <tr>
          <th>{{ item.program }}</th>
          <td v-for="tier in tierCount" :key="`${item.id}-${tier - 1}`">
            <template v-if="item[`tier-${tier - 1}`]">
              <h5
                v-if="item[`tier-${tier - 1}`].tier !== ''"
                class="blue-grey--text"
              >
                {{ item[`tier-${tier - 1}`].tier }}
              </h5>
              <h5 v-else class="blue-grey--text">Basis</h5>
              <div class="">{{ item[`tier-${tier - 1}`].value }} miles</div>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "MileageEarnings",
  props: {
    offer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showAllPrograms: false,
    };
  },
  computed: {
    allowedAirlines() {
      if (!this.offer) {
        return [];
      }

      return [
        ...new Set([
          this.offer.outbound.carrier.code,
          this.offer.outbound.operating.code,
          this.offer.inbound.carrier.code,
          this.offer.inbound.operating.code,
        ]),
      ];
    },
    earnings() {
      if (!this.offer || !this.offer.earnings) {
        return null;
      }

      if (!this.showAllPrograms) {
        return this.offer.earnings;
      }
      return this.offer.earnings.filter((e) =>
        this.allowedAirlines.includes(e.id)
      );
    },
    tierCount() {
      if (!this.earnings) {
        return 0;
      }

      return Math.max(...this.earnings.map((e) => e.earnings.length));
    },
    parsedEarnings() {
      if (!this.earnings) {
        return null;
      }

      return this.earnings.map((e) => {
        let obj = {
          id: e.id,
          program: `${e.name} ${e.programName}`,
        };

        for (let i = 0; i < this.tierCount; i++) {
          obj[`tier-${i}`] = e.earnings[i] || null;
        }
        return obj;
      });
    },
    headers() {
      let headers = [
        {
          text: "Frequent Flyer Program",
          value: "program",
          sortable: true,
        },
      ];

      for (let i = 1; i <= this.tierCount; i++) {
        headers.push({
          text: `Tier ${i}`,
          value: `tier-${i}.value`,
          sortable: true,
        });
      }

      return headers;
    },
  },
};
</script>

<template>
  <v-container class="px-10 py-4">
    <v-row>
      <v-col class="d-flex align-items">
        <h1 class="text-h4 mb-6">Step 4: Compare Offers</h1>
        <v-spacer></v-spacer>
        <v-btn color="primary" :to="{ path: '/results', query }"
          ><v-icon class="mr-2">mdi-arrow-left</v-icon> Back to Offers</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-circular
          v-if="offersLoading"
          indeterminate
          color="primary"
          :size="80"
        ></v-progress-circular>
        <v-card elevation="2">
          <compare-offers-list v-if="!offersLoading">
            <template #action="slotProps">
              <v-btn
                color="primary"
                dense
                elevation="2"
                @click.stop="viewOfferDetails(slotProps.offer.id)"
                >View Details</v-btn
              >
            </template>
          </compare-offers-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CompareOffersList from "./CompareOffersList";

export default {
  name: "CompareOffers",
  components: {
    CompareOffersList,
  },
  computed: {
    ...mapState("offers", ["offersLoading"]),
    ...mapState("route", ["path", "query"]),
  },
  methods: {
    ...mapActions("offers", ["viewOfferDetails"]),
  },
};
</script>

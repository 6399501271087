import api from "../api";

const state = {
  plans: [],
  loading: false,
};

const getters = {
  planList: (state) => {
    return state.plans.map((p) => {
      return {
        label: p.plan,
        value: p.id,
        airline: p.airline,
      };
    });
  },
};

const mutations = {
  SET_PLANS(state, plans) {
    state.plans = plans;
  },
  SET_PLANS_LOADING(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  getPlans({ state, commit }) {
    commit("SET_PLANS_LOADING", true);

    api
      .getAirlinesPlans()
      .then((response) => {
        commit("SET_PLANS", response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        commit("SET_PLANS_LOADING", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};

import api from "../api";
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("1234567890abcdef", 10);

// Profile / State
const state = {
  user: null,
  manager: null,
  error: null,
  updatingProfile: false,
  // isUserOnboarded: !!localStorage.getItem("is_onboarded"),
};

// Profile / Getters
const getters = {
  // returns user ID without the identity provider string.
  userID: (state) => {
    if (!state.user) {
      return null;
    }

    const fragments = state.user.user_id.split("|");

    return fragments[1];
  },
  // returns name composed by first + last name
  userFullName: (state) => {
    if (state.user && state.user.user_metadata) {
      return `${state.user.user_metadata.first_name} ${state.user.user_metadata.last_name}`;
    }

    return null;
  },

  // returns profile Ids for departure / return rating profiles
  currentProfiles: (state) => {
    if (
      !state.user ||
      !state.user.user_metadata ||
      !state.user.user_metadata.selected_rating_profiles
    ) {
      return null;
    }

    return (
      state.user.user_metadata.selected_rating_profiles || {
        departure: null,
        return: null,
      }
    );
  },

  // helper getter to check if there's changes to selected rating profiles
  currentProfilesString: (state) => {
    if (!state.user || !state.user.user_metadata) {
      return null;
    }

    return JSON.stringify(state.user.user_metadata.selected_rating_profiles);
  },

  // merge presets with custom profiles
  ratingProfileGroups: (state, getters, rootState) => {
    let groups = [];

    if (
      state.user &&
      typeof state.user.user_metadata.rating_profiles !== "undefined" &&
      state.user.user_metadata.rating_profiles.length > 0
    ) {
      groups.push({ header: "Custom" });
      groups = [
        ...groups,
        { divider: true },
        ...state.user.user_metadata.rating_profiles,
      ];
    }

    groups = [
      ...groups,
      {
        header: "Presets",
      },
      ...rootState.ratingPresets,
    ];

    return groups;
  },

  // Returns departure rating profile Id
  currentProfileDeparture: (state, getters) => {
    if (!getters.currentProfilesString || !getters.currentProfiles.departure) {
      return null;
    }
    if (getters.currentProfiles.departure) {
      const index = getters.ratingProfileGroups.findIndex(
        (i) =>
          typeof i.id !== "undefined" &&
          i.id === getters.currentProfiles.departure
      );

      if (index !== -1) {
        return getters.ratingProfileGroups[index];
      }
    }
    return null;
  },

  // Returns return rating profile Id
  currentProfileReturn: (state, getters) => {
    if (!getters.currentProfilesString || !getters.currentProfiles.return) {
      return null;
    }
    if (getters.currentProfiles.return) {
      const index = getters.ratingProfileGroups.findIndex(
        (i) =>
          typeof i.id !== "undefined" && i.id === getters.currentProfiles.return
      );
      if (index !== -1) {
        return getters.ratingProfileGroups[index];
      }
    }

    return null;
  },

  // get current subscription from user
  userSubscription: (state) => {
    if (state.user && state.user.app_metadata) {
      return state.user.app_metadata.subscription || null;
    }

    return null;
  },

  // Get user role based on Auth0 roles system.
  userRole: (state, getters, rootState, rootGetters) => {
    const decoded = rootGetters["session/idTokenDecoded"];

    if (!decoded) {
      return "Free";
    }

    const roles = decoded["https://www.upgradeengine.com/roles"];

    if (typeof roles === "undefined" || roles.length === 0) {
      return "Free";
    }

    return roles[roles.length - 1];
  },

  userAirlinePlans: (state, getters, rootState, rootGetters) => {
    if (!state.user || !state.user.user_metadata) {
      return null;
    }

    const userPlans = state.user.user_metadata.airline_plans || null;
    if (!userPlans) {
      return null;
    }

    return userPlans;
  },

  userContext: (state, getters, rootState, rootGetters) => {
    // if (!state.user) {
    //   return null;
    // }

    let context = {};

    if (rootGetters["parsedAttributeWeights"]) {
      context.weightings = {
        outbound: rootGetters["parsedAttributeWeights"].departure,
        inbound: rootGetters["parsedAttributeWeights"].return,
      };
    }

    if (getters.userAirlinePlans) {
      context.airlinePlans = getters.userAirlinePlans;
    }

    return context;
  },

  encodedContext: (state, getters) => {
    return encodeURIComponent(btoa(JSON.stringify(getters.userContext)));
  },
};

// Profile / Mutations
const mutations = {
  // Sets main user data object
  SET_USER: (state, user) => {
    state.user = user;
  },

  // Helper mutation to handle user meta management
  SET_USER_META: (state, meta) => {
    // console.log("SET_USER_META", meta);
    Object.entries(meta).forEach((i) => {
      console.log(i);
      state.user.user_metadata[i[0]] = i[1];
      // console.log(state.user.user_metadata);
    });
  },

  // Sets object for management API
  SET_MANAGER: (state, client) => {
    state.manager = client;
  },

  // Sets an error message for any Auth0 process
  SET_ERROR: (state, e) => {
    state.error = e;
  },

  // Sets state of profile update on / off
  SET_UPDATE_PROFILE: (state, e) => {
    state.updatingProfile = e;
  },
  // SET_USER_ONBOARDED: (state, onboarded) => {
  //   state.isUserOnboarded = onboarded;
  //   localStorage.setItem("is_onboarded", onboarded);
  // },
};

// Profile / Actions
const actions = {
  /**
   * Initialze Auth0 Management API
   *
   * @since v0.2
   *
   * @returns Promise (after manager is instantiated)
   */
  initializeManager({ state, commit, rootState }) {
    return new Promise((resolve, reject) => {
      if (state.auth0Manager) {
        resolve();
      }
      const manager = getAuth0Manager(rootState.session.accessToken);
      commit("SET_AUTH_MANAGER", manager);
      resolve();
    });
  },

  // /**
  //  * Initialze Management API helper
  //  *
  //  * @since v0.8.2
  //  *
  //  * @returns Object api instance
  //  */
  // async setManager({ state, commit, rootState }) {
  //   const token = await rootState.session.auth0Client.getTokenSilently();

  //   api.setToken(token);

  //   if (api) {
  //     commit("SET_MANAGER", api);
  //   }
  // },

  /**
   * Uses Management API to get current user dataset.
   *
   * @since v0.2
   *
   */
  async getUser({ state, commit, dispatch, rootState }) {
    try {
      const currentUser = await api.getUser();
      // console.log(currentUser);
      commit("SET_USER", currentUser.data);
      dispatch("identifyUser");
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * Helper function to manage user metadata.
   *
   * @since v0.2
   *
   * @param {Object} payload Object containing updated user metadata to be patched.
   */
  async updateUser({ state, commit }) {
    const payload = state.user.user_metadata;

    try {
      const user = await api.updateUser(payload);
      return user;
    } catch (err) {
      commit("SET_ERROR", err);
    }
  },

  async clearUserRoles({ state, commit }) {
    try {
      await api.clearUserRoles();
    } catch (err) {
      commit("SET_ERROR", err);
    }
  },

  async updateUserRole({ state, commit }, role) {
    try {
      console.log("updateUserRole", state.user.user_id, role);
      const user = await api.updateUserRole(role);
      console.log("updateUserRole", user);
      return user;
    } catch (err) {
      commit("SET_ERROR", err);
    }
  },

  /**
   * Stores new rating profile into user metadata, and set it as current profile for currenc direction.
   *
   * @since v0.2
   *
   * @param {Object} payload Object containing the new profile and the current route direction.
   */
  async saveRatingsProfile({ state, commit, dispatch }, payload) {
    // check if profile already exists
    let ratingProfiles = { ...state.user.user_metadata.rating_profiles } || {};
    if (!payload.profile.id) {
      payload.profile.id = nanoid();
    }

    ratingProfiles[payload.profile.id] = payload.profile;
    let metadata = {
      rating_profiles: Object.values(ratingProfiles),
      selected_rating_profiles: {
        ...state.user.user_metadata.selected_rating_profiles,
      } || {
        departure: null,
        return: null,
      },
    };

    metadata.selected_rating_profiles[payload.direction] = payload.profile.id;

    commit("SET_UPDATE_PROFILE", true);
    commit("SET_USER_META", metadata);
    await dispatch("updateUser", metadata);
    commit("SET_UPDATE_PROFILE", false);
  },

  /**
   * Unselect rating profile Id for specific route direction.
   *
   * @since v0.2
   *
   * @param {String} direction The direction name.
   */
  async clearRatingsProfile({ state, commit, dispatch }, direction) {
    let metadata = {
      selected_rating_profiles: {
        ...state.user.user_metadata.selected_rating_profiles,
      } || {
        departure: null,
        return: null,
      },
    };

    metadata.selected_rating_profiles[direction] = null;

    commit("SET_UPDATE_PROFILE", true);
    commit("SET_USER_META", metadata);
    await dispatch("updateUser", metadata);
    commit("SET_UPDATE_PROFILE", false);
  },

  /**
   * Sets rating profile Id for specific route direction.

   * @since v0.2
   * 
   * @param {Object} payload Object containing rating profile Id and current route direction.
   */
  async setRatingsProfile({ state, commit, dispatch }, payload) {
    console.log("setRatingsProfile", payload);
    let metadata = {
      selected_rating_profiles: {
        ...state.user.user_metadata.selected_rating_profiles,
      } || {
        departure: null,
        return: null,
      },
    };

    metadata.selected_rating_profiles[payload.direction] = payload.id;

    commit("SET_UPDATE_PROFILE", true);
    commit("SET_USER_META", metadata);
    await dispatch("updateUser", metadata);
    commit("SET_UPDATE_PROFILE", false);
  },

  /**
   * Deletes rating profile from list of saved profiles.
   *
   * @since v0.2.1
   *
   * @param {String} profileId the rating profile Id to be deleted.
   */
  async deleteRatingsProfile({ state, commit, dispatch }, profileId) {
    let metadata = {
      rating_profiles: [...state.user.user_metadata.rating_profiles],
      selected_rating_profiles: {
        ...state.user.user_metadata.selected_rating_profiles,
      } || {
        departure: null,
        return: null,
      },
    };

    const profileIndex = metadata.rating_profiles.findIndex(
      (p) => p.id === profileId
    );

    if (profileIndex !== -1) {
      metadata.rating_profiles.splice(profileIndex, 1);
    }

    ["departure", "return"].forEach((direction) => {
      if (metadata.selected_rating_profiles[direction] === profileId) {
        metadata.selected_rating_profiles[direction] = null;
      }
    });

    commit("SET_UPDATE_PROFILE", true);
    commit("SET_USER_META", metadata);
    await dispatch("updateUser", metadata);
    commit("SET_UPDATE_PROFILE", false);
  },

  identifyUser({ state, rootState, getters }) {
    if (rootState.session.isAuthenticated && state.user) {
      if (typeof chmln !== "undefined") {
        chmln.identify(state.user.user_id, {
          // REQUIRED Unique ID of each user in your database (e.g. 23443 or "590b80e5f433ea81b96c9bf6")
          email: state.user.email, // RECOMMENDED Used to connect data coming from various integrations
          created: state.user.created_at, // Send dates in ISO or unix timestamp format (e.g. "2017-07-01T03:21:10Z" or 1431432000)
          name: getters.userFullName, // We will parse this to extra first and surnames (e.g. "James Doe")
          // role: USER.ROLE,                  // Send properties useful for targeting types of users (e.g. "Admin")
          // logins: USER.LOGIN_COUNT,         // Send any data about user engagement (e.g. 39)
          // project: USER.PROJECT_ID,         // Send any unique data for a user that might appear in any page URLs (e.g. 09876 or "12a34b56")
        });
      }
      if (typeof FS !== "undefined") {
        // This is an example script - don't forget to change it!
        FS.identify(state.user.user_id, {
          displayName: getters.userFullName,
          email: getters.userFullName,
        });
      }
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

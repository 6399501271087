// import * as recurlyjs from "recurly";
import api from "../api";

const state = {
  client: null,
  tempToken: null,
  errors: [],
  status: null
};

const mutations = {
  SET_CLIENT: (state, obj) => {
    state.client = obj;
  },
  SET_TEMP_TOKEN: (state, obj) => {
    state.tempToken = obj;
  },
  SET_ERROR: (state, errors) => {
    state.errors = errors;
  },
  SET_STATUS: (state, status) => {
    state.status = status;
  }
};

const actions = {
  async setup({ state, commit, rootState }) {
    const token = await rootState.session.auth0Client.getTokenSilently();
    api.setToken(token);
    // const client = recurly.initialize(rootState.session.accessToken);
    // const client = new recurlyjs.Client(recurlyApiKey);
    commit("SET_CLIENT", api);
  },

  /**
   * Takes user profile data, and creates a Recurly account.
   *
   * @since v0.8
   *
   * @param {Object} payload An object with user profile information.
   */
  async createAccount({ state, commit, rootState, rootGetters }) {
    commit("SET_STATUS", "Creating subscription account...");

    try {
      const accountCreate = {
        code: rootGetters["profile/userID"],
        first_name: rootState.profile.user.user_metadata.first_name,
        last_name: rootState.profile.user.user_metadata.last_name,
        email: rootState.profile.user.email,
        address: {
          postal_code: rootState.profile.user.user_metadata.zip_code
        }
      };

      const account = await api.createAccount(accountCreate);
      return account;
    } catch (err) {
      if (err.response) {
        console.log("Unknown Error: ", err.response.data.err);
        commit("SET_ERROR", err.response.data.err);
        commit("SET_STATUS", null);
        // return err.response.data.err;
      }
    }

    //   state.client
    //     .createAccount(accountCreate)
    //     .then(account => {
    //       // console.log("Created Account: ", account.data);
    //       resolve(account);
    //     })
    //     .catch(err => {
    //       if (err.response) {
    //         console.log("Unknown Error: ", err.response.data.err);
    //         commit("SET_ERROR", err.response.data.err);
    //         commit("SET_STATUS", null);
    //         reject(err.response.data.err);
    //       }
    //     });
    // });
  },

  /**
   * Add billing info to an account using Recurly.js temporary token.
   *
   * @since v0.8
   *
   * @param {Object} payload An object with user profile information.
   */
  async addBillingInfo({ state, commit, rootGetters }) {
    commit("SET_STATUS", "Adding Billing Info...");
    try {
      const billingInfo = await api.addBillingInfo(state.tempToken);
      return billingInfo;
    } catch (err) {
      if (err.response) {
        console.log("Unknown Error: ", err.response.data.err);
        commit("SET_ERROR", err.response.data.err);
        commit("SET_STATUS", null);
        // return err.response.data.err;
      }
    }
  },

  /**
   * Adds a new subscription to a user.
   *
   * @since v0.8
   *
   * @param {Object} payload An object with user profile information.
   */
  async createSubscription({ state, commit, rootGetters }, plan) {
    commit("SET_STATUS", "Creating Subscription...");

    try {
      let subscriptionReq = {
        plan: plan
      };

      const sub = api.addSubscription(plan);
      return sub;
    } catch (err) {
      if (err.response) {
        console.log("Unknown Error: ", err.response.data.err);
        commit("SET_ERROR", err.response.data.err);
        commit("SET_STATUS", null);
        // reject(err.response.data.err);
      }
    }
  },

  /**
   * Performs all steps of the membership purchase flow.
   *
   * @since v.0.8
   *
   * @param {*} payload
   */
  async purchase({ state, commit, rootState, rootGetters, dispatch }, plan) {
    commit("SET_ERROR", null);
    const account = await dispatch("createAccount");
    if (!state.errors || state.errors.length === 0) {
      const billing = await dispatch("addBillingInfo");
    }
    if (!state.errors || state.errors.length === 0) {
      const subscription = await dispatch("createSubscription", plan);
    }
    if (!state.errors) {
      if (plan !== "Free") {
        const planCapitalized = plan[0].toUpperCase() + plan.slice(1);

        // clear user roles
        await dispatch("profile/clearUserRoles", null, {
          root: true
        });

        // update new roles
        await dispatch("profile/updateUserRole", planCapitalized, {
          root: true
        });

        // // refresh tokens
        // await dispatch("session/getAuthTokens", null, {
        //   root: true
        // });
        // // refresh tokens
        // await dispatch("session/getAppTokens", null, {
        //   root: true
        // });

        await dispatch("profile/getUser", null, { root: true });
      }
      commit("SET_STATUS", "Membership successfully created!");
    }
  }
};

export default {
  state,
  mutations,
  actions
};

<template>
  <div>
    <v-checkbox
      v-for="(option, k) in leverOptions"
      v-show="checkCondition(option)"
      :key="`${leverKey}-${k}`"
      v-model="currentLever"
      :value="option.value"
      :label="getLabelString(option)"
      :disabled="checkIfDisabled(option)"
      multiple
      dense
      hide-details
    >
      <template #label>
        <div class="d-flex align-center" style="width: 100%">
          <span>{{ getLabelString(option) }}</span>
        </div>
        <v-tooltip
          v-if="option.upgradeCost && !hideSignals"
          right
          z-index="9999"
          color="black"
        >
          <template #activator="{ on, attrs }">
            <v-chip
              small
              badge
              class="ml-auto flex-shrink-0"
              v-bind="attrs"
              v-on="on"
              >{{ getCostString(option) }}</v-chip
            >
          </template>
          <ul>
            <li v-for="(val, i) in option.upgradeCost" :key="i">
              {{ i }}: {{ val }}
            </li>
          </ul>
        </v-tooltip>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { comparator } from "../../modules/helpers";
import { format } from "date-fns";
import userHasPermission from "../../modules/userHasPermission";

export default {
  name: "FilterCheckBoxes",
  props: {
    leverKey: {
      type: String,
      default: null,
    },
    hideEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentChecked:
        this.filters && this.filters[this.leverKey]
          ? this.filters[this.leverKey].options
              .filter((o) => o.selected)
              .map((o) => o.value)
          : [],
    };
  },
  computed: {
    currentLeverDetails() {
      if (!this.filters || !this.filtersWithCosts[this.leverKey]) {
        return null;
      }
      return this.filtersWithCosts[this.leverKey];
    },
    leverOptions() {
      if (!this.currentLeverDetails) {
        return [];
      }
      if (!this.hideEmpty) {
        return this.currentLeverDetails.options;
      }

      return this.currentLeverDetails.options.filter(
        (o) => typeof o.upgradeCost !== "undefined"
      );
    },
    currentLever: {
      get() {
        if (!this.currentLeverDetails) {
          return null;
        }

        return this.currentLeverDetails.options
          .filter((o) => o.selected)
          .map((o) => o.value);
      },
      set(val) {
        this.setFilter([
          {
            key: this.leverKey,
            value: val,
          },
        ]);
      },
    },
    ...mapState("route", ["query"]),
    ...mapState("flags", ["hideSignals"]),
    ...mapGetters("filters", [
      "filters",
      "filtersWithCosts",
      "selectedOptions",
    ]),
    ...mapState("profile", ["user"]),
  },
  methods: {
    ...mapActions("filters", ["setFilter"]),
    checkIfDisabled(option) {
      if (
        typeof option.permissions === "undefined" ||
        option.permissions.length === 0
      ) {
        return false;
      }
      const isAllowed = this.user.user_id
        ? option.permissions.every((v) => userHasPermission(v))
        : false;

      return !isAllowed;
    },
    getLabelString(option) {
      if (this.leverKey === "dateFlexibility") {
        return this.getDateLabel(option);
      }
      if (this.leverKey === "airlines") {
        return `${option.label} (${option.value})`;
      }

      return option.label;
    },
    getCostString(option) {
      const val =
        option.upgradeCost.minPriceDiff < 0
          ? `-$${Math.floor(Math.abs(option.upgradeCost.minPriceDiff))}`
          : `$${Math.ceil(option.upgradeCost.minPriceDiff)}`;

      if (
        typeof option.permissions !== "undefined" &&
        option.permissions.length > 0
      ) {
        if (!this.user) {
          return `available for members`;
        } else {
          if (!option.permissions.every((v) => userHasPermission(v))) {
            return `available for higher plans`;
          }
        }
      }

      return val;
    },
    getDateLabel(option) {
      const departureDate = new Date(`${this.query.departureDate}T00:00:00`);
      const returnDate = new Date(`${this.query.returnDate}T00:00:00`);

      departureDate.setDate(departureDate.getDate() + option.value);
      returnDate.setDate(returnDate.getDate() + option.value);

      return `${format(departureDate, "MMM d")} to ${format(
        returnDate,
        "MMM d"
      )}`;
    },
    checkCondition(option) {
      if (
        typeof option.conditions === "undefined" ||
        option.conditions.length === 0
      ) {
        return true;
      }

      let evaluated = [];

      option.conditions.forEach((c) => {
        const values = this.selectedOptions[c.key] || false;
        evaluated.push(comparator(values, c.comparator, c.value));
      });

      return evaluated.filter((v) => v === false).length <= 0;
    },
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Redirecting...</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AuthRedirect"
};
</script>

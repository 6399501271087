<template>
  <v-card>
    <template v-if="current.quadrant">
      <v-card-title>{{ current.data.label }}</v-card-title>
      <v-card-subtitle>{{ current.data.count }} offers</v-card-subtitle>
    </template>
    <template v-if="!current.quadrant">
      <v-card-title>
        {{ current.data.outbound.carrier.code }}-{{
          current.data.outbound.flight
        }}
        - {{ current.data.inbound.carrier.code }}-{{
          current.data.inbound.flight
        }}
      </v-card-title>
      <v-card-subtitle>
        {{ formatDate(current.data.outbound.departure.at) }} -
        {{ formatDate(current.data.inbound.departure.at) }}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <h4>System Rating:</h4>
            <div class="d-flex align-center">
              <v-rating
                :length="5"
                half-increments
                dense
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half"
                :value="Number(current.data.rating)"
                :size="16"
              >
              </v-rating>
              <div class="ml-2">{{ current.data.rating }}</div>
            </div>
            <h4>Your Rating:</h4>
            <div class="d-flex align-center">
              <v-rating
                :length="5"
                half-increments
                dense
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half"
                :value="Number(current.data.userRating)"
                :size="16"
              ></v-rating>
              <div class="ml-2">{{ current.data.userRating }}</div>
            </div>
            <h4>Cabin Rating:</h4>
            <div class="d-flex align-center">
              <v-rating
                :length="5"
                half-increments
                dense
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half"
                :value="Number(current.data.cabinRating)"
                :size="16"
              ></v-rating>
              <div class="ml-2">{{ current.data.cabinRating }}</div>
            </div>
          </v-col>
          <v-col cols="6">
            <template v-if="outboundSeatPics">
              <v-img
                lazy-src="https://placehold.co/300x200"
                :src="outboundSeatPics[0]"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </template>
            <!-- <template v-if="inboundSeatPics">
              <img :src="inboundSeatPics[0]" width="100%" />
            </template> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <h4>Details:</h4>
            <v-simple-table dense class="grey lighten-4 mt-2">
              <tbody>
                <tr>
                  <th>
                    <h3>Route:</h3>
                  </th>
                  <td>
                    {{ current.data.outbound.departure.code }} /
                    {{ current.data.inbound.departure.code }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <h3>Classes:</h3>
                  </th>
                  <td>
                    {{ current.data.outbound.class }} /
                    {{ current.data.inbound.class }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <h3>Cost:</h3>
                  </th>
                  <td>
                    <span
                      v-if="
                        offerPricePaid &&
                        current.baseline &&
                        currentBaseline.cost.amount !== offerPricePaid
                      "
                      class="red--text text-decoration-line-through"
                      >${{ currentBaseline.cost.amount }}</span
                    >
                    ${{ current.data.cost.amount }}
                  </td>
                </tr>
                <tr v-if="current.data.upgradeCost && !current.baseline">
                  <th>
                    <h3>Cost to Upgrade:</h3>
                  </th>
                  <td>
                    <span>{{
                      current.data.upgradeCost > 0
                        ? `$${Math.round(current.data.upgradeCost)}`
                        : `-$${Math.abs(Math.round(current.data.upgradeCost))}`
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col cols="4">
            <h4>Rating Chart:</h4>
            <offers-radar-chart
              :dataset="radarChartData"
              :show-labels="false"
              :show-tooltip="false"
              :w="100"
              :h="100"
              :margin="16"
            ></offers-radar-chart>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { format, parseISO } from "date-fns";
import OffersRadarChart from "./OffersRadarChart";
import { getSeatIdentifier } from "../modules/helpers";

export default {
  name: "OffersDiagramPopover",
  components: {
    OffersRadarChart,
  },
  props: {
    current: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(["attributeLabels"]),
    ...mapState("offers", ["offerPricePaid", "currentBaseline"]),
    ...mapState("seats", ["seats"]),
    averageRatings() {
      if (!this.current) {
        return null;
      }

      const averages = {};

      Object.keys(this.attributeLabels).forEach((k) => {
        const offerOutboundRating = this.current.data.outbound.ratings[k] || 1;
        const offerInboundRating = this.current.data.inbound.ratings[k] || 1;

        averages[k] = (offerOutboundRating + offerInboundRating) / 2;
      });

      return averages;
    },
    radarChartData() {
      if (!this.averageRatings) {
        return null;
      }

      let dataset = [];

      const offerData = Object.keys(this.averageRatings).map((k) => {
        return {
          axis: k,
          label: this.attributeLabels[k],
          value: Number(this.averageRatings[k]),
          color: "#F44336",
        };
      });
      dataset.push(offerData);

      return dataset;
    },
    outboundSeatPics() {
      if (!this.current) {
        return null;
      }
      const id = getSeatIdentifier(this.current.data.outbound),
        seat = this.seats.find((s) => s.id === id);

      if (!seat) {
        return null;
      }

      console.log(id, seat);

      return seat.media.pictures || null;
    },
    inboundSeatPics() {
      if (!this.current) {
        return null;
      }
      const id = getSeatIdentifier(this.current.data.inbound),
        seat = this.seats.find((s) => s.id === id);

      if (!seat) {
        return null;
      }

      return seat.media.pictures || null;
    },
  },
  methods: {
    formatDate(date) {
      return format(parseISO(date), "MMM d, Y p");
    },
  },
};
</script>

<template>
  <div>
    <v-tabs v-model="offerTab">
      <v-tab key="formatted">Offer Details</v-tab>
      <v-tab key="earnings">Mileage Earnings</v-tab>
      <v-tab
        v-if="
          currentBaseline &&
          selectedOfferWithRatings &&
          currentBaseline.id !== selectedOfferWithRatings.id
        "
        key="compare"
        >Compare with Baseline (chart)</v-tab
      >
      <v-tab key="raw">Raw JSON</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="offerTab" class="pa-4">
      <v-tab-item key="formatted">
        <v-row>
          <v-col md="6">
            <h3 class="mb-2">
              Departure Flight:
              {{ selectedOfferWithRatings.outbound.departure.code }} to
              {{ selectedOfferWithRatings.outbound.arrival.code }}
            </h3>
            <flight-card
              :flight="selectedOfferWithRatings.outbound"
            ></flight-card>
          </v-col>
          <v-col md="6">
            <h3 class="mb-2">
              Return Flight:
              {{ selectedOfferWithRatings.inbound.departure.code }} to
              {{ selectedOfferWithRatings.inbound.arrival.code }}
            </h3>
            <flight-card
              :flight="selectedOfferWithRatings.inbound"
            ></flight-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item key="earnings">
        <mileage-earnings :offer="selectedOfferWithRatings"></mileage-earnings>
      </v-tab-item>
      <v-tab-item
        v-if="
          currentBaseline &&
          selectedOfferWithRatings &&
          currentBaseline.id !== selectedOfferWithRatings.id
        "
        key="compare"
      >
        <v-row>
          <v-col>
            <offers-radar-chart
              v-if="radarChartData"
              :w="230"
              :h="230"
              :dataset="radarChartData"
            ></offers-radar-chart>
          </v-col>
          <v-col>
            <h3>Compare selected offer with baseline:</h3>
            <v-checkbox
              v-model="showBaselineInChart"
              dense
              hide-details
              color="blue"
              label="Baseline offer"
            ></v-checkbox>
            <v-checkbox
              v-model="showSelectedInChart"
              dense
              hide-details
              color="red"
              label="Your selected offer"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item key="raw">
        <json-viewer
          :expand-depth="3"
          :expanded="true"
          :value="selectedOfferWithRatings"
        >
        </json-viewer>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import { mapState, mapGetters, mapActions } from "vuex";
import FlightCard from "./FlightCard";
import OffersRadarChart from "./OffersRadarChart";
import MileageEarnings from "./MileageEarnings";
import JsonViewer from "vue-json-viewer";

export default {
  name: "OfferDetails",
  components: {
    FlightCard,
    JsonViewer,
    OffersRadarChart,
    MileageEarnings,
  },
  data() {
    return {
      colors: {
        baseline: "#2196F3",
        offer: "#F44336",
      },
      showBaselineInChart: true,
      showSelectedInChart: true,
    };
  },
  computed: {
    ...mapState(["attributeLabels"]),
    ...mapState("offers", ["currentBaseline", "offerDialogView"]),
    ...mapGetters("offers", ["selectedOfferWithRatings"]),
    tabs() {
      if (
        this.currentBaseline &&
        this.selectedOfferWithRatings &&
        this.currentBaseline.id === this.selectedOfferWithRatings.id
      ) {
        return ["details.formatted", "details.raw"];
      }
      return ["details.formatted", "details.compare", "details.raw"];
    },
    offerTab: {
      get() {
        return this.tabs.indexOf(this.offerDialogView) !== -1
          ? this.tabs.indexOf(this.offerDialogView)
          : 0;
      },
      set(key) {
        console.log("setOfferDetailsView", this.tabs[key]);
        this.setOfferDetailsView(this.tabs[key]);
      },
    },
    averageRatings() {
      if (!this.selectedOfferWithRatings || !this.currentBaseline) {
        return null;
      }

      const averages = {
        baseline: {},
        selected: {},
      };

      Object.keys(this.attributeLabels).forEach((k) => {
        const baselineOutbound = this.currentBaseline.outbound.ratings[k] || 1;
        const baselineInbound = this.currentBaseline.inbound.ratings[k] || 1;

        averages.baseline[k] = (baselineOutbound + baselineInbound) / 2;

        const offerOutbound = this.selectedOfferWithRatings.outbound.aircraft.cabin.amenities.find(
          (a) => a.key === k
        );
        const offerOutboundRating = offerOutbound ? offerOutbound.rating : 1;
        const offerInbound = this.selectedOfferWithRatings.inbound.aircraft.cabin.amenities.find(
          (a) => a.key === k
        );
        const offerInboundRating = offerInbound ? offerInbound.rating : 1;

        averages.selected[k] = (offerOutboundRating + offerInboundRating) / 2;
      });

      return averages;
    },
    radarChartData() {
      if (!this.averageRatings) {
        return null;
      }

      let dataset = [];

      if (this.showBaselineInChart) {
        const baselineData = Object.keys(this.averageRatings.baseline).map(
          (k) => {
            return {
              axis: k,
              label: this.attributeLabels[k],
              value: Number(this.averageRatings.baseline[k]),
              color: this.colors.baseline,
            };
          }
        );
        dataset.push(baselineData);
      }

      if (this.showSelectedInChart) {
        const offerData = Object.keys(this.averageRatings.selected).map((k) => {
          return {
            axis: k,
            label: this.attributeLabels[k],
            value: Number(this.averageRatings.selected[k]),
            color: this.colors.offer,
          };
        });
        dataset.push(offerData);
      }

      return dataset;
    },
  },
  methods: {
    formatLocationAndDate(obj) {
      return `${obj.code} - ${format(parseISO(obj.at), "MMM d, Y p")}`;
    },
    getUnique(a) {
      return [...new Set(a)];
    },
    ...mapActions("offers", ["setOfferDetailsView"]),
  },
};
</script>

<template>
  <v-dialog v-model="isOpen" max-width="960" persistent>
    <v-card class="blue-grey lighten-5">
      <v-toolbar>
        <v-toolbar-title>Sign Up to Get Access</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <membership-plans
          v-if="!showJoin"
          @selected="setPlan"
        ></membership-plans>
        <join-form v-if="showJoin" :plan="selectedPlan"></join-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import JoinForm from "./JoinForm";
import MembershipPlans from "./MembershipPlans";

export default {
  name: "JoinDialog",
  components: {
    JoinForm,
    MembershipPlans
  },
  props: {
    isOpen: {
      default: false
    }
  },
  data() {
    return {
      selectedPlan: null,
      showJoin: false
    };
  },
  computed: {
    // ...mapState(["flightClass"]),
    // ...mapState("offers", [
    //   "offerDialog",
    //   "offerLoading",
    //   "currentSelectedOffer"
    // ]),
    // ...mapGetters("offers", ["selectedOfferWithRatings"]),
    // ...mapGetters("flights", ["baselineTrip"])
  },
  methods: {
    setPlan(val) {
      this.selectedPlan = val;
      this.showJoin = true;
    }
    // close() {
    //   this.closeDialog();
    //   this.showBooking = false;
    // },
    // ...mapActions("offers", ["closeDialog"])
  }
};
</script>

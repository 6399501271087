// Helper object to map cabin names with their codes
const classMap = {
  ECONOMY: "Y",
  "ECONOMY+": "Y+",
  PREMIUM_ECONOMY: "PE",
  BUSINESS: "J",
  FIRST: "F",
};

/**
 * Given a flight, and a collection of user preferences weights, returns the system and user ratings for the given flight.
 *
 * @since v0.1
 *
 * @param {Object} flight
 * @param {Object} weights
 */
export const getFlightRating = (flight, weights) => {
  const allowedWeights = flight.aircraft.cabin.amenities.map((a) => a.key);
  const allWeights =
    Object.keys(weights)
      .filter((w) => allowedWeights.includes(w))
      .map((w) => weights[w]) || null;
  let amenities;

  amenities = flight.aircraft.cabin.amenities.filter(
    (a) => typeof weights[a.key] !== "undefined"
  );

  const allRatings = [];
  const allUserRatings = [];

  amenities.forEach((a) => {
    allRatings.push(a.rating);

    if (typeof weights[a.key] === "undefined") {
      return;
    }

    allUserRatings.push(a.rating * weights[a.key]);
  });

  return {
    system: Number(
      (allRatings.reduce((a, b) => a + b) / allWeights.length).toFixed(2)
    ),
    user: Number(
      (
        allUserRatings.reduce((a, b) => a + b) /
        allWeights.reduce((a, b) => a + b)
      ).toFixed(2)
    ),
  };
};

/**
 * Helper function to generate a seat identifier based on a flight.
 *
 * @since v0.8.22
 *
 * @param {Object} flight the flight object.
 * @returns {String} the seat unique identifier.
 */
export const getSeatIdentifier = (flight) => {
  const airline = flight.operating.code || flight.carrier.code;
  const equipment = flight.aircraft.code || null;
  const version = 1;
  const cabin = classMap[flight.class || flight.aircraft.cabin.cabinClass];

  return `${airline}-${equipment}-${version}-${cabin}`;
};

/**
 * Given a basic flight object coming from an offer, and a set of user preferences weights, returns the system and user ratings for the given flight.
 *
 * @since v0.1
 *
 * @param {Object} flight
 * @param {Object} weights
 */
export const getOfferFlightRating = (flight, weights) => {
  const allowedWeights = Object.keys(flight.ratings).filter(
    (k) => flight.ratings[k] > 0
  );
  const allWeights =
    Object.keys(weights)
      .filter((w) => allowedWeights.includes(w))
      .map((w) => weights[w]) || null;

  const ratings = Object.keys(flight.ratings)
    .filter((r) => typeof weights[r] !== "undefined")
    .map((key) => {
      return flight.ratings[key];
    });

  const userRatings = Object.keys(flight.ratings)
    .filter((r) => typeof weights[r] !== "undefined")
    .map((key) => {
      const rating = flight.ratings[key];
      return Number((rating * weights[key]).toFixed(2));
    });

  return {
    system: Number(
      (
        ratings.reduce((a, b) => a + b) / Object.keys(allWeights).length
      ).toFixed(2)
    ),
    user: Number(
      (
        userRatings.reduce((a, b) => a + b) / allWeights.reduce((a, b) => a + b)
      ).toFixed(2)
    ),
  };
};

/**
 * Given an offer object, and a set of user preferences weights, returns the system and user ratings for the given offer.
 *
 * @since v0.1
 *
 * @param {Object} offer
 * @param {Object} weights
 */
export const getOfferRating = (offer) => {
  return {
    system: ((offer.outbound.rating + offer.inbound.rating) / 2).toFixed(2),
    user: ((offer.outbound.userRating + offer.inbound.userRating) / 2).toFixed(
      2
    ),
  };
};

const methods = {
  isEqual: (x, y) => {
    return x === y;
  },
  isNotEqual: (x, y) => {
    return x !== y;
  },
  isGreaterThan: (x, y) => {
    return x > y;
  },
  isLowerThan: (x, y) => {
    return x < y;
  },
  isIncluded: (x, y) => {
    return Array.isArray(x) && x.includes(y);
  },
  isNotIncluded: (x, y) => {
    return Array.isArray(x) && x.includes(y);
  },
  hasAny: (x, y) => {
    return Array.isArray(x) && y.some((z) => x.includes(z));
  },
  hasAll: (x, y) => {
    return Array.isArray(x) && y.every((z) => x.includes(z));
  },
  hasNone: (x, y) => {
    return Array.isArray(x) && !y.every((z) => x.includes(z));
  },
};
const map = {
  EQUALS: "isEqual",
  "NOT EQUAL": "isNotEqual",
  "GREATER THAN": "isGreaterThan",
  "LOWER THAN": "isLowerThan",
  INCLUDES: "isIncluded",
  "NOT INCLUDES": "isNotIncluded",
  "HAS ANY": "hasAny",
  "HAS ALL": "hasAll",
  "HAS NONE": "hasNone",
};

/**
 *
 * Helper function to run advanced comparison logic.
 *
 * @since v0.1
 *
 * @param {String|Number|Array|Boolean} x     first value to compare
 * @param {String} type                        comparator type
 * @param {String|Number|Array|Boolean} y     second value to compare
 * @returns
 */
export const comparator = (x, type, y) => {
  const callback = methods[map[type]];

  return callback(x, y);
};

/**
 * Helper function to merge seats info with a flight object.
 *
 * @since v.0.9
 *
 * @param {Object} flight
 * @param {Array} seats
 * @returns
 */
export const getFlightWithSeatInfo = (flight, seats) => {
  const amenitiesMap = {
    seatWidth: {
      name: "Seat Width",
      unit: "inches",
      value: null,
      rating: 0,
      key: "seatWidth",
    },
    seatPitch: {
      name: "Seat Legroom",
      unit: "inches",
      value: null,
      rating: 0,
      key: "seatPitch",
    },
    seatRecline: {
      name: "Seat Recline",
      value: null,
      rating: 0,
      key: "seatType",
    },
    seatPrivacy: {
      name: "Seat Privacy",
      value: null,
      rating: 0,
      key: "seatPrivacy",
    },
  };

  const flightClass = flight.aircraft.cabin.cabinClass;
  const identifier = `${flight.carrier.code}-${flight.aircraft.code}-1-${classMap[flightClass]}`;
  const seat = seats.find((s) => s.id === identifier);

  console.log("flightInfo", identifier, seat);

  if (seat) {
    flight.aircraft.cabin.amenities = Object.keys(
      seat.ratings.perAttribute
    ).map((k) => {
      let base = amenitiesMap[k];
      const values = seat.ratings.perAttribute[k];
      base.value = values.value;
      base.rating = values.rating;

      return base;
    });
    flight.aircraft.cabin.rating = seat.ratings.system;
    flight.aircraft.cabin.pictures = seat.media.pictures || [
      "https://dl.airtable.com/.attachmentThumbnails/090d74b56f1d85e180beae7970dcdb03/622508af",
    ];
    flight.aircraft.cabin.video = seat.media.video || null;
  }

  return flight;
};

/**
 * Helper function to calculate cost to upgrade based on flight duration strings.
 *
 * @since v0.10
 *
 * @param {*} durationOutbound
 * @param {*} durationInbound
 * @param {*} cost
 * @returns
 */
export const getCostToUpgrade = (durationOutbound, durationInbound, cost) => {
  // if no duration string for both directions, just bail
  if (!durationOutbound && !durationInbound) {
    return null;
  }

  const parsedDurationOutbound = durationOutbound
    ? durationOutbound
        .replace("M", "")
        .replace("PT", "")
        .split("H")
        .map((n) => Number(n))
    : 0;
  const parsedDurationInbound = durationInbound
    ? durationInbound
        .replace("M", "")
        .replace("PT", "")
        .split("H")
        .map((n) => Number(n))
    : 0;

  const totalHours = parsedDurationOutbound[0] + parsedDurationInbound[0];
  const totalMinutes =
    parsedDurationOutbound[1] + parsedDurationInbound[1] + totalHours * 60;

  const finalHours = Number((totalMinutes / 60).toFixed(2));

  return Number((cost / finalHours).toFixed(2));
};

/**
 * Small function to calculate min and max values of a price within a specific range.
 *
 * @since v0.8.23
 *
 * @param {Number} cost the cost that represents the middle of the range.
 * @param {Number} range the amount of variation compared with the cost. It's a percentage represented by a float number.
 * @returns {Object} an object containing the min and max values for the range.
 */
export const getPriceRange = (cost, range) => {
  const variation = cost * range;
  return {
    min: cost - variation,
    max: cost + variation,
  };
};

const quadrantCompare = {
  // free upgrades means that price is lower than baseline and ratings are higher.
  betterthanfree: (baseline, offer, quadrantsBy) => {
    const priceRange = getPriceRange(baseline.cost.amount, 0.05);
    return (
      priceRange.min >= offer.cost.amount &&
      baseline[quadrantsBy] < offer[quadrantsBy]
    );
  },

  // paid upgrades means that price is higher than baseline and ratings are higher or the same.
  paidupgrades: (baseline, offer, quadrantsBy) => {
    const priceRange = getPriceRange(baseline.cost.amount, 0.05);
    return (
      priceRange.max <= offer.cost.amount &&
      baseline[quadrantsBy] < offer[quadrantsBy]
    );
  },

  // savings downgrages means that price is lower than baseline and ratings are lower or the same.
  savingsdowngrades: (baseline, offer, quadrantsBy) => {
    return (
      baseline.cost.amount > offer.cost.amount &&
      baseline[quadrantsBy] >= offer[quadrantsBy]
    );
  },

  // trash means that price is higher than baseline and ratings are lower.
  trash: (baseline, offer, quadrantsBy) => {
    return (
      baseline.cost.amount < offer.cost.amount &&
      baseline[quadrantsBy] >= offer[quadrantsBy]
    );
  },

  // Similar means that deals have better ratings but slightly different prices.
  freeupgrades: (baseline, offer, quadrantsBy) => {
    const priceRange = getPriceRange(baseline.cost.amount, 0.05);
    return (
      priceRange.min < offer.cost.amount &&
      priceRange.max > offer.cost.amount &&
      baseline[quadrantsBy] < offer[quadrantsBy]
    );
  },
};

/**
 * Helper function verify if offer is on a specific quadrant, compared to the baseline.
 *
 * @since v0.8.23
 *
 * @param {Object} baseline the baseline offer.
 * @param {Object} offer the selected offer.
 * @param {String} quadrant the quadrant identifier.
 * @param {String} quadrantsBy the offer variable key used to compare offers with quadrants rules.
 * @returns {Array} filtered offers.
 */
export const compareToQuadrant = (baseline, offer, quadrant, quadrantsBy) => {
  // console.log("compareToQuadrant", baseline, offer, quadrant);
  return quadrantCompare[quadrant](baseline, offer, quadrantsBy);
};

/**
 * Helper function verify if offer is on a specific quadrant, compared to the baseline.
 *
 * @since v0.8.71
 *
 * @param {Object} baseline the baseline offer.
 * @param {Object} offer the selected offer.
 * @param {String} quadrant the quadrant type.
 * @param {String} quadrantsBy the offer variable key used to compare offers with quadrants rules.
 * @returns {Array} filtered offers.
 */
export const getOfferQuadrant = (baseline, offer, quadrantsBy) => {
  const quadrants = Object.keys(quadrantCompare);
  const included = quadrants.filter((q) =>
    quadrantCompare[q](baseline, offer, quadrantsBy)
  );
  return included[0];
};

/**
 * Helper function to abstract logic to group offers per quadrant.
 *
 * @since v0.8.22
 *
 * @param {Object} baseline the baseline offer.
 * @param {Array} offers an array of offers that doesn't contain the baseline.
 * @param {String} quadrant the quadrant type.
 * @param {String} quadrantsBy the offer variable key used to compare offers with quadrants rules.
 * @returns {Array} filtered offers.
 */
export const filterByQuadrant = (baseline, offers, quadrant, quadrantsBy) => {
  return offers.filter((o) =>
    compareToQuadrant(baseline, o, quadrant, quadrantsBy)
  );
};

/**
 * Helper function to format a cabin class string.
 *
 * @since v0.8.28
 *
 * @param {String} className the cabin class string to be formatted.
 * @returns  {String} the formatted string.
 */
export const formatClassName = (className) => {
  let formatted = className
    .split("_")
    .map((s) => s[0].toUpperCase() + s.substring(1).toLowerCase())
    .join(" ");

  if (className === "PREMIUM_ECONOMY") {
    return formatted.replace("Economy", "E.");
  }
  return formatted;
};

/**
 * Helper class to properly format a YouTube url to be embedded.
 *
 * @since v0.8.28
 *
 * @param {String} url the raw YouTube url.
 * @returns {String} the formatted url.
 */
export const formatEmbedUrl = (url) => {
  if (url.indexOf("embed") !== -1) {
    return url;
  }

  const id = url.split("watch?v=")[1];

  return `https://www.youtube.com/embed/${id}?controls=0`;
};

/**
 * Generate a range of hsl colors.
 *
 * @since v0.8.33
 *
 * @param {Number} saturation
 * @param {Number} lightness
 * @param {Number} alpha
 * @param {Number} amount
 * @returns {String}
 */
export const generateHslaColors = (saturation, lightness, alpha, amount) => {
  let colors = [];
  let huedelta = Math.trunc(360 / amount);

  for (let i = 0; i < amount; i++) {
    let hue = i * huedelta;
    colors.push(`hsla(${hue},${saturation}%,${lightness}%,${alpha})`);
  }

  return colors;
};

/**
 * Given a cabin code, returns the respective class code.
 *
 * @since v0.9.0
 *
 * @param {String} code the one (or two) letter code that represents a cabin.
 * @returns {String} the Amadeus-compatible class identifier.
 */
export const cabinCodeToClass = (code) => {
  const codes = Object.entries(classMap),
    index = codes.findIndex((c) => {
      return c[1] === code;
    });

  if (index != -1) {
    return codes[index][0];
  }

  return null;
};

/**
 * Given a cabin class identifier, return the respective rating.
 *
 * @since 0.9.11
 *
 * @param {String} cabin the cabin identifier.
 * @returns {Number} the cabin rating number.
 */
export const getCabinRating = (cabin) => {
  if (!cabin) {
    return 1;
  }
  const cabinMap = {
    ECONOMY: 1,
    "ECONOMY+": 2,
    PREMIUM_ECONOMY: 3,
    BUSINESS: 4,
    FIRST: 5,
  };

  return cabinMap[cabin];
};

/**
 * Given two cabin class identifiers, return the average of their respective rating.
 *
 * @since 0.9.11
 *
 * @param {String} outboundCabin the outbound flight cabin identifier.
 * @param {String} inboundCabin the inbound flight cabin identifier.
 * @returns {Number} the cabin rating number.
 */
export const getOfferCabinRating = (outboundCabin, inboundCabin) => {
  const outboundRating = getCabinRating(outboundCabin);
  const inboundRating = getCabinRating(inboundCabin);

  return (outboundRating + inboundRating) / 2;
};

<template>
  <v-container class="px-10 py-4">
    <v-row>
      <v-col>
        <h1 class="text-h4 mb-6">Step 1: Choose your baseline trip</h1>
        <baseline-selector></baseline-selector>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-circular
          v-if="flightsLoading"
          indeterminate
          color="primary"
          :size="80"
        ></v-progress-circular>
        <v-row v-if="!flightsLoading">
          <v-col>
            <flight-picker
              v-if="flights && flights[step].length > 0"
            ></flight-picker>
          </v-col>
        </v-row>
        <error-message
          v-if="error && !flightsLoading"
          :error="error"
        ></error-message>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaselineSelector from "./BaselineSelector";
import FlightPicker from "./FlightPicker";
import ErrorMessage from "./ErrorMessage";
import { mapState, mapActions } from "vuex";

export default {
  name: "BaselinePicker",
  components: {
    BaselineSelector,
    FlightPicker,
    ErrorMessage,
  },
  computed: {
    // ...mapState(["step"]),
    ...mapState("route", ["query"]),
    ...mapState("flights", {
      flightsLoading: (state) => state.loading,
      flights: (state) => state.flights,
      error: (state) => state.error,
    }),
    step() {
      return this.query.departureFlight ? "return" : "departure";
    },
  },
};
</script>

<template>
  <v-simple-table>
    <template #default>
      <tbody v-if="currentPolicy">
        <tr>
          <th>Policy Link</th>
          <td>
            <v-btn
              v-for="(link, i) in currentPolicy.policies.change.policyUrls
                .fares"
              :key="`airline-change-link-${i}`"
              color="primary"
              :href="link.url"
              target="_blank"
              :class="i > 0 ? 'ml-4' : ''"
              >Open Airline Website</v-btn
            >
          </td>
        </tr>
        <tr>
          <th>Risk of Buying with Cash and Paying Change/Cancel Fees</th>
          <td>{{ currentPolicy.policies.change.buyCashRisk }}</td>
        </tr>
        <tr>
          <th>Current Policy for New Bookings Applies Until</th>
          <td>
            {{ formatDate(currentPolicy.policies.change.policyExpiration) }}
          </td>
        </tr>
        <tr>
          <th>Cancellation Restrictions</th>
          <td>
            {{ currentPolicy.policies.change.restrictionsCancellation }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  name: "TicketCancelPolicy",
  props: {
    currentPolicy: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return "N/A";
      }
      return format(parseISO(date), "MMM d, Y");
    },
  },
};
</script>

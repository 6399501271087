<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="4">
        <v-select
          v-model="yAxisType"
          :items="yScaleTypes"
          item-value="key"
          item-text="label"
          dense
          outlined
          label="Y axis by:"
        ></v-select>
      </v-col>
      <v-col cols="8"></v-col>
    </v-row>
    <!-- <v-switch v-model="toggleXScale" label="Toggle X Scale"></v-switch> -->
    <div
      ref="container"
      class="offers-diagram"
      style="width: 100%; height: 640px; marging: -40px"
    >
      <div class="y-axis-legend">Comfort</div>
      <div class="x-axis-legend">Cost</div>
      <svg id="chart" :viewBox="viewBox">
        <g
          class="chart-margin"
          :transform="`translate(${margin * 2},${margin})`"
        >
          <g class="x-axis" :transform="`translate(0,${chartHeight})`"></g>
          <g class="y-axis"></g>
          <g class="quadrants-divider">
            <line
              v-for="(line, i) in quadrantLines"
              :key="`ql-${i}`"
              class="line"
              :y1="line.y1"
              :y2="line.y2"
              :x1="line.x1"
              :x2="line.x2"
            ></line>
          </g>
          <g
            ref="plot-quadrants"
            class="plot-quadrants"
            :width="chartWidth"
            :height="chartHeight"
          >
            <g
              v-for="q in plotQuadrants"
              :key="`q-${q.id}`"
              :transform="`translate(${q.x1},${q.y1})`"
              class="quadrant"
              :class="
                selectedOptions.quadrants === 'summary' ? 'clickable' : ''
              "
              @click.stop="setQuadrant(q.id)"
            >
              <rect
                :width="q.width"
                :height="q.height"
                :fill="`rgba(${q.color},1)`"
              ></rect>
              <g
                v-if="
                  ['summary', 'overview', q.id].includes(
                    selectedOptions.quadrants
                  )
                "
                :transform="
                  selectedOptions.quadrants === 'summary' ||
                  selectedOptions.quadrants === 'overview'
                    ? q.id === 'freeupgrades'
                      ? 'translate(0,-80)'
                      : 'translate(0,0)'
                    : q.id === 'freeupgrades'
                    ? 'translate(0,-100)'
                    : 'translate(0,-40)'
                "
              >
                <text
                  :x="q.width / 2"
                  :y="q.height / 2"
                  alignment-baseline="middle"
                  fill="#000"
                  text-anchor="middle"
                  font-size="21"
                  :class="`quadrant-${q.id}`"
                  @mouseover="quadrantMouseover(q)"
                  @mouseleave="quadrantMouseleave"
                >
                  {{ q.label }}
                </text>
                <text
                  v-if="
                    selectedOptions.quadrants !== 'summary' &&
                    selectedOptions.quadrants !== 'overview' &&
                    q.oldLabel
                  "
                  :x="q.width / 2"
                  :y="q.height / 2"
                  alignment-baseline="middle"
                  fill="#000"
                  text-anchor="middle"
                  font-size="21"
                  transform="translate(0, 24)"
                >
                  (or {{ q.oldLabel }})
                </text>
                <text
                  v-if="
                    selectedOptions.quadrants !== 'summary' &&
                    selectedOptions.quadrants !== 'overview'
                  "
                  :x="q.width / 2"
                  :y="q.height / 2"
                  alignment-baseline="middle"
                  font-size="16"
                  fill="#000"
                  text-anchor="middle"
                  :transform="`translate(0, ${
                    selectedOptions.quadrants !== 'summary' &&
                    selectedOptions.quadrants !== 'overview' &&
                    q.oldLabel
                      ? '48'
                      : '24'
                  })`"
                >
                  {{ q.desc }}
                </text>
                <text
                  v-if="
                    selectedOptions.quadrants !== 'summary' &&
                    selectedOptions.quadrants !== 'overview'
                  "
                  :x="q.width / 2"
                  :y="q.height / 2"
                  alignment-baseline="middle"
                  font-size="16"
                  fill="#000"
                  text-anchor="middle"
                  :transform="`translate(0, ${
                    selectedOptions.quadrants !== 'summary' &&
                    selectedOptions.quadrants !== 'overview' &&
                    q.oldLabel
                      ? '72'
                      : '48'
                  })`"
                >
                  {{ q.sub }}
                </text>
                <text
                  :x="q.width / 2"
                  :y="q.height / 2"
                  alignment-baseline="middle"
                  font-size="16"
                  fill="#000"
                  text-anchor="middle"
                  :transform="`translate(0, ${
                    selectedOptions.quadrants === 'summary' ||
                    selectedOptions.quadrants === 'overview'
                      ? '24'
                      : q.oldLabel
                      ? '96'
                      : '72'
                  })`"
                >
                  ({{ q.offers }} offers)
                </text>
              </g>
            </g>
          </g>
          <g class="plot">
            <circle
              v-for="circle in plotCircles"
              :key="circle.id"
              class="offer-dot"
              :class="[
                {
                  pulse: circle.pulse,
                },
                circle.baseline ? 'dot-baseline' : '',
                `dot-${circle.data.id}`,
              ]"
              :r="circle.r"
              :cx="circle.cx"
              :cy="circle.cy"
              :fill="circle.fill"
              @mouseover="mouseover(circle)"
              @mouseleave="mouseleave"
              @click.stop="
                !circle.quadrant
                  ? viewOfferDetails(circle.data.id)
                  : setQuadrant(circle.data.id)
              "
            ></circle>
          </g>
        </g>
      </svg>
      <div ref="baselineTooltip" class="baseline-tooltip">
        <div class="tooltip-arrow" data-popper-arrow></div>
        <v-sheet
          v-show="showBaselineTooltip"
          elevation="8"
          width="100"
          rounded
          dark
          color="grey darken-2"
        >
          <div class="text-body-2 pa-1 text-center">Your baseline Offer</div>
        </v-sheet>
      </div>
      <div ref="tooltipQuadrant" class="tooltip">
        <v-card v-if="currentQuadrant">
          <v-card-title>{{ currentQuadrant.label }}</v-card-title>
          <v-card-subtitle v-if="currentQuadrant.offers > 0"
            >{{ currentQuadrant.offers }} offers</v-card-subtitle
          >
          <v-card-subtitle v-if="currentQuadrant.offers === 0"
            >no offers found</v-card-subtitle
          >
          <v-card-text>
            <strong v-if="currentQuadrant.oldLabel"
              >OR {{ currentQuadrant.oldLabel }}</strong
            >
            <div>{{ currentQuadrant.desc }}</div>
            <div>{{ currentQuadrant.sub }}</div>
          </v-card-text>
        </v-card>
      </div>
      <div ref="tooltip" class="tooltip" :style="tooltipStyle">
        <offers-diagram-popover
          v-if="currentHover"
          :current="currentHover"
        ></offers-diagram-popover>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { mapState, mapGetters, mapActions } from "vuex";
import {
  filterByQuadrant,
  getPriceRange,
  // generateHslaColors,
} from "../modules/helpers";
import { createPopper } from "@popperjs/core";
import OffersDiagramPopover from "./OffersDiagramPopover";

export default {
  name: "OffersDiagram",
  components: {
    OffersDiagramPopover,
  },
  data() {
    return {
      chartWidth: 800,
      chartHeight: 500,
      margin: 40,
      svg: null,
      x: null,
      y: null,
      gx: null,
      gy: null,
      plot: null,
      currentHover: null,
      // toggleXScale: false,
      popperQuadrant: null,
      currentQuadrant: null,
      showBaselineTooltip: true,
      popperBaseline: null,
      yScaleTypes: [
        {
          key: "userRating",
          label: "By Comfort Rating",
        },
        {
          key: "cabinRating",
          label: "By Cabin Class Rating",
        },
      ],
    };
  },
  computed: {
    ...mapState("offers", ["offerPricePaid", "currentBaseline", "quadrantsBy"]),
    ...mapState("filters", ["quadrants"]),
    ...mapState("flags", ["toggleXScale"]),
    ...mapGetters("offers", ["filteredOffersOnQuadrant", "offersWithRatings"]),
    ...mapGetters("flights", ["baselineTrip"]),
    ...mapGetters("filters", ["selectedOptions"]),
    yAxisType: {
      get() {
        return this.quadrantsBy;
      },
      set(val) {
        this.setQuadrantsBy(val);
      },
    },
    viewBox() {
      if (!this.chartWidth && !this.chartHeight) {
        return "0 0 0 0";
      }
      return `0 0 ${this.chartWidth + this.margin * 3} ${
        this.chartHeight + this.margin * 2
      }`;
    },
    xRange() {
      // if (this.toggleXScale) {
      //   const max = Math.max(...this.filteredOffersOnQuadrant.map((o) => o.cost.amount));
      //   return [max, 1];
      // } else {
      if (this.selectedOptions.quadrants === "freeupgrades") {
        return [
          Math.round(
            this.priceRange.max + this.baselineOffer.cost.amount * 0.01
          ),
          Math.round(
            this.priceRange.min - this.baselineOffer.cost.amount * 0.01
          ),
        ];
      }
      const set = [
        ...new Set(
          this.filteredOffersOnQuadrant
            .map((o) => o.cost.amount)
            .sort((a, b) => a - b)
            .reverse()
        ),
      ];

      if (set.length === 1) {
        const allOffers = this.offersWithRatings
          .filter((o) => !o.isBaseline)
          .map((o) => o.cost.amount);

        if (
          ["paidupgrades", "trash"].includes(this.selectedOptions.quadrants)
        ) {
          return [Math.max(...allOffers), set[0]]
            .sort((a, b) => a - b)
            .reverse();
        }
        if (
          ["betterthanfree", "savingsdowngrades"].includes(
            this.selectedOptions.quadrants
          )
        ) {
          return [Math.min(...allOffers), set[0]]
            .sort((a, b) => a - b)
            .reverse();
        }
      }
      return [set[0], set[set.length - 1]];
      // }
    },
    yRange() {
      if (
        ["paidupgrades", "freeupgrades", "betterthanfree"].includes(
          this.selectedOptions.quadrants
        )
      ) {
        return [this.baselineOffer[this.yAxisType] * 0.9, 5];
      }
      if (
        ["savingsdowngrades", "trash"].includes(this.selectedOptions.quadrants)
      ) {
        return [1, this.baselineOffer[this.yAxisType] * 1.1];
      }
      return [1, 5];
      // return this.filteredOffersOnQuadrant.map((o) => o[this.yAxisType]).sort((a, b) => a - b);
    },
    xScale() {
      if (["freeupgrades"].includes(this.selectedOptions.quadrants)) {
        return d3.scaleLinear().domain(this.xRange).range([0, this.chartWidth]);
      }
      if (this.toggleXScale) {
        return d3
          .scaleSymlog()
          .domain(this.xRange)
          .range([1, this.chartWidth])
          .constant(5);
      }
      return d3
        .scaleLog()
        .domain(this.xRange)
        .range([1, this.chartWidth])
        .base(9);
    },
    xAxis() {
      return d3
        .axisBottom(this.xScale)
        .ticks(10)
        .tickFormat((d) => `$${d}`);
    },
    yScale() {
      return d3.scaleLinear().domain(this.yRange).range([this.chartHeight, 0]);
    },
    yAxis() {
      const labels = [
        "Economy",
        "Economy Plus",
        "Premium Economy",
        "Business",
        "First",
      ];
      return d3
        .axisLeft(this.yScale)
        .ticks(5)
        .tickFormat((d) => {
          if (this.yAxisType === "cabinRating") {
            return labels[d - 1];
          }
          return `${d} ${d > 1 ? "Stars" : "Star"}`;
        });
    },
    baselineOffer() {
      if (!this.filteredOffersOnQuadrant) {
        return null;
      }
      return this.filteredOffersOnQuadrant.find((o) => o.isBaseline);
    },
    priceRange() {
      return getPriceRange(this.baselineOffer.cost.amount, 0.05);
    },
    quadrantLines() {
      const priceRange = this.priceRange;

      let lines = [];

      // ratingLine
      lines.push({
        x1: 0,
        x2: this.chartWidth,
        y1: this.yScale(this.baselineOffer[this.yAxisType]),
        y2: this.yScale(this.baselineOffer[this.yAxisType]),
      });

      // priceLineFree
      if (
        [
          "savingsdowngrades",
          "betterthanfree",
          "freeupgrades",
          "summary",
          "overview",
        ].includes(this.selectedOptions.quadrants)
      ) {
        lines.push({
          x1: this.xScale(priceRange.min),
          x2: this.xScale(priceRange.min),
          y1: 0,
          y2: this.yScale(this.baselineOffer[this.yAxisType]),
        });
      }

      // priceLinePaid
      if (
        [
          "trash",
          "paidupgrades",
          "freeupgrades",
          "summary",
          "overview",
        ].includes(this.selectedOptions.quadrants)
      ) {
        lines.push({
          x1: this.xScale(priceRange.max),
          x2: this.xScale(priceRange.max),
          y1: 0,
          y2: this.yScale(this.baselineOffer[this.yAxisType]),
        });
      }

      // priceLineLower
      lines.push({
        x1: this.xScale(this.baselineOffer.cost.amount),
        x2: this.xScale(this.baselineOffer.cost.amount),
        y1: this.yScale(this.baselineOffer[this.yAxisType]),
        y2: this.chartHeight,
      });

      return lines;
    },
    plotQuadrants() {
      // const color = generateHslaColors(50, 50, 0.5, 5);

      let quadrants = {};

      // if (
      //   ["freeupgrades", "summary", "overview"].includes(
      //     this.selectedOptions.quadrants
      //   )
      // ) {
      const freeupgrades = this.quadrants.options.find(
        (o) => o.value === "freeupgrades"
      );

      quadrants.freeupgrades = {
        label: freeupgrades.label,
        oldLabel: freeupgrades.oldLabel || null,
        desc: freeupgrades.description,
        sub: freeupgrades.subLabel,
        color: freeupgrades.color,
        x1: ["betterthanfree", "savingsdowngrades"].includes(
          this.selectedOptions.quadrants
        )
          ? this.xScale(this.baselineOffer.cost.amount)
          : this.xScale(this.priceRange.max),
        x2: ["paidupgrades", "trash"].includes(this.selectedOptions.quadrants)
          ? this.xScale(this.baselineOffer.cost.amount)
          : this.xScale(this.priceRange.min),
        y1: 0,
        y2: this.yScale(this.baselineOffer[this.yAxisType]),
      };
      // }

      if (
        [
          "savingsdowngrades",
          "betterthanfree",
          "freeupgrades",
          "summary",
          "overview",
        ].includes(this.selectedOptions.quadrants)
      ) {
        const betterthanfree = this.quadrants.options.find(
          (o) => o.value === "betterthanfree"
        );
        quadrants.betterthanfree = {
          label: betterthanfree.label,
          oldLabel: betterthanfree.oldLabel || null,
          desc: betterthanfree.description,
          sub: betterthanfree.subLabel,
          color: betterthanfree.color,

          x1: this.xScale(this.priceRange.min),
          x2: this.chartWidth,
          y1: 0,
          y2: this.yScale(this.baselineOffer[this.yAxisType]),
        };
      }

      if (
        [
          "paidupgrades",
          "trash",
          "freeupgrades",
          "summary",
          "overview",
        ].includes(this.selectedOptions.quadrants)
      ) {
        const paidupgrades = this.quadrants.options.find(
          (o) => o.value === "paidupgrades"
        );

        quadrants.paidupgrades = {
          label: paidupgrades.label,
          oldLabel: paidupgrades.oldLabel || null,
          desc: paidupgrades.description,
          sub: paidupgrades.subLabel,
          color: paidupgrades.color,

          x1: 0,
          x2: this.xScale(this.priceRange.max),
          y1: 0,
          y2: this.yScale(this.baselineOffer[this.yAxisType]),
        };
      }

      // if (
      //   [
      //     "betterthanfree",
      //     "savingsdowngrades",
      //     "freeupgrades",
      //     "summary",
      //     "overview",
      //   ].includes(this.selectedOptions.quadrants)
      // ) {
      const savingsdowngrades = this.quadrants.options.find(
        (o) => o.value === "savingsdowngrades"
      );
      quadrants.savingsdowngrades = {
        label: savingsdowngrades.label,
        oldLabel: savingsdowngrades.oldLabel || null,
        desc: savingsdowngrades.description,
        sub: savingsdowngrades.subLabel,
        color: savingsdowngrades.color,

        x1: this.xScale(this.baselineOffer.cost.amount),
        x2: this.chartWidth,
        y1: this.yScale(this.baselineOffer[this.yAxisType]),
        y2: this.chartHeight,
      };
      // }

      // if (
      //   [
      //     "paidupgrades",
      //     "trash",
      //     "freeupgrades",
      //     "summary",
      //     "overview",
      //   ].includes(this.selectedOptions.quadrants)
      // ) {
      const trash = this.quadrants.options.find((o) => o.value === "trash");

      quadrants.trash = {
        label: trash.label,
        oldLabel: trash.oldLabel || null,
        desc: trash.description,
        sub: trash.subLabel,
        color: trash.color,
        x1: 0,
        x2: this.xScale(this.baselineOffer.cost.amount),
        y1: this.yScale(this.baselineOffer[this.yAxisType]),
        y2: this.chartHeight,
      };
      // }

      const offers = this.filteredOffersOnQuadrant.filter((o) => !o.isBaseline);

      return Object.keys(quadrants).map((q) => {
        let quadrant = quadrants[q];
        quadrant.id = q;
        quadrant.width = Math.abs(quadrant.x1 - quadrant.x2);
        quadrant.height = Math.abs(quadrant.y1 - quadrant.y2);

        const filtered = filterByQuadrant(
          this.baselineOffer,
          offers,
          q,
          this.yAxisType
        );

        quadrant.offers = filtered.length;

        return quadrant;
      });
    },
    plotCircles() {
      const offers = this.filteredOffersOnQuadrant.filter((o) => !o.isBaseline);

      let circles;
      if (this.selectedOptions.quadrants === "summary") {
        let grouped = [];
        //   const quadrants = this.quadrants.options.filter(
        //     (o) => !["summary", "overview"].includes(o.value)
        //   );

        //   const radiusRange = d3
        //     .scaleLinear()
        //     .domain([0, offers.length / 2])
        //     .range([0, offers.length]);

        //   quadrants.forEach((q) => {
        //     const filtered = filterByQuadrant(
        //       this.baselineOffer,
        //       offers,
        //       q.value
        //     );

        //     if (filtered.length === 0) {
        //       return;
        //     }

        //     const sums = filtered.reduce(
        //       (a, b) => {
        //         a.cost += Number(b.cost.amount);
        //         a.rating += Number(b[this.yAxisType]);

        //         return a;
        //       },
        //       {
        //         cost: 0,
        //         rating: 0,
        //       }
        //     );

        //     const averages = {
        //       cost: Number((sums.cost / filtered.length).toFixed(2)),
        //       rating: Number((sums.rating / filtered.length).toFixed(2)),
        //     };

        //     const data = {
        //       id: q.value,
        //       label: q.label,
        //       count: filtered.length,
        //       rating: averages.rating,
        //       cost: averages.cost,
        //     };

        //     grouped.push({
        //       fill: "#FF5722",
        //       cy: this.yScale(data.rating),
        //       cx: this.xScale(data.cost),
        //       r: radiusRange(data.count),
        //       baseline: false,
        //       pulse: false,
        //       quadrant: true,
        //       data,
        //     });
        //   });

        circles = [this.generateCirle(this.baselineOffer), ...grouped];
      } else {
        circles = [...offers, this.baselineOffer].map((o) =>
          this.generateCirle(o)
        );
      }

      return circles;
    },
    tooltipStyle() {
      let styles = { opacity: this.currentHover ? 1 : 0 };

      if (this.tooltipCoord) {
        styles = { ...styles, ...this.tooltipCoord };
      }

      return styles;
    },
  },
  watch: {
    filteredOffersOnQuadrant() {
      this.buildChart();
    },
    toggleXScale() {
      this.buildChart();
    },
    yAxisType() {
      this.buildChart();
    },
  },
  mounted() {
    const sizes = this.$refs.container.getBoundingClientRect();
    console.log(sizes);
    this.chartWidth = sizes.width;
    this.chartHeight = sizes.height - 40;
    this.buildChart();
  },
  methods: {
    generateCirle(o, type) {
      return {
        fill: o.isBaseline ? "#1976D2" : "#FF5722",
        cy: this.yScale(o[this.yAxisType]),
        cx: this.xScale(o.cost.amount),
        r: o.isBaseline ? (type === "pulse" ? 14 : 12) : 8,
        baseline: o.isBaseline,
        pulse: type === "pulse",
        quadrant: false,
        data: o,
      };
    },
    buildChart() {
      this.xScale.domain([this.xRange[0], this.xRange[this.xRange.length - 1]]);
      this.yScale.domain([this.yRange[0], this.yRange[this.yRange.length - 1]]);

      d3.select(".x-axis").call(this.xAxis);
      d3.select(".y-axis").call(this.yAxis);

      d3.selectAll(".tick text").attr("font-size", 16).attr("font-weight", 700);

      if (this.yAxisType === "cabinRating") {
        var self = this;
        d3.selectAll("g.y-axis text").each(function () {
          console.log(this);
          self.setLineBreaks(this);
        });
      }
      this.setBaselinePopover();
    },
    mouseover: function (d) {
      this.currentHover = d;
      this.setPopover();
    },
    mouseleave: function () {
      this.currentHover = null;
      this.tooltipCoord = null;
      // this.$refs.tooltip.style("opacity", 0);
    },
    quadrantMouseover(q) {
      if (["summary", "overview"].includes(this.selectedOptions.quadrants)) {
        this.currentQuadrant = q;
        this.setQuadrantPopover();
      }
    },
    quadrantMouseleave() {
      if (["summary", "overview"].includes(this.selectedOptions.quadrants)) {
        this.currentQuadrant = null;
      }
    },
    setPopover() {
      if (this.popper) {
        this.popper.destroy();
      }
      this.$nextTick(() => {
        const baseline = document.getElementsByClassName(
          `dot-${this.currentHover.data.id}`
        );
        this.popper = createPopper(baseline[0], this.$refs.tooltip, {
          placement: "bottom",
          strategy: "fixed",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 12],
              },
            },
          ],
        });
      });
    },
    setBaselinePopover() {
      this.$nextTick(() => {
        const baseline = document.getElementsByClassName(`dot-baseline`);
        this.popperBaseline = createPopper(
          baseline[0],
          this.$refs.baselineTooltip,
          {
            placement: "top",
            strategy: "fixed",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 12],
                },
              },
            ],
          }
        );
      });
    },
    setQuadrantPopover() {
      if (this.popperQuadrant) {
        this.popperQuadrant.destroy();
      }
      this.$nextTick(() => {
        console.log("this.currentQuadrant", this.currentQuadrant);
        const quadrant = document.getElementsByClassName(
          `quadrant-${this.currentQuadrant.id}`
        );
        console.log("quadrant", quadrant);
        this.popperQuadrant = createPopper(
          quadrant[0],
          this.$refs.tooltipQuadrant,
          {
            placement: "bottom",
            strategy: "fixed",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 12],
                },
              },
            ],
          }
        );
      });
    },
    setQuadrant(quadrant) {
      if (this.selectedOptions.quadrants !== "summary") {
        return;
      }
      this.setFilter([
        {
          key: "quadrants",
          value: quadrant,
        },
      ]);
    },
    setLineBreaks(d) {
      var el = d3.select(d);
      var words = el.text().split(" ");
      el.text("");

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0) tspan.attr("x", -9).attr("dy", "15");
      }
    },
    ...mapActions("offers", ["viewOfferDetails", "setQuadrantsBy"]),
    ...mapActions("filters", ["setFilter"]),
  },
};
</script>

<style lang="scss">
.offers-diagram {
  position: relative;
  padding: 0 0 40px 40px;

  .x-axis-legend,
  .y-axis-legend {
    position: absolute;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .y-axis-legend {
    top: 50%;
    left: 0;
    transform: translate(-24px, -50%) rotate(-90deg);
  }
  .x-axis-legend {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .offer-dot {
    cursor: pointer;
    opacity: 0.5;

    &.baseline {
      opacity: 0.9;
    }

    // &.pulse {
    //   fill: white;
    //   fill-opacity: 0;
    //   transform-origin: 50% 50%;
    //   animation-duration: 2s;
    //   animation-name: pulse;
    //   animation-iteration-count: infinite;
    // }
  }
  position: relative;
  .tooltip {
    position: absolute;
    width: 100%;
    max-width: 450px;
    top: 0;
  }

  .quadrants-divider {
    .line {
      stroke: black;
      stroke-width: 1px;
      stroke-dasharray: 4px;
    }
  }

  .plot-quadrants {
    .quadrant {
      rect {
        opacity: 0.3;
        transition: opacity 0.2s;
      }
      &.clickable {
        cursor: pointer;
        // &:hover {
        //   rect {
        //     opacity: 1;
        //   }
        // }
      }
    }
  }

  .baseline-tooltip {
    .tooltip-arrow,
    .tooltip-arrow::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: #616161;
    }

    .tooltip-arrow {
      visibility: hidden;
    }

    .tooltip-arrow::before {
      visibility: visible;
      content: "";
      transform: rotate(45deg);
    }

    &[data-popper-placement^="top"] > .tooltip-arrow {
      bottom: -4px;
    }

    &[data-popper-placement^="bottom"] > .tooltip-arrow {
      top: -4px;
    }

    &[data-popper-placement^="left"] > .tooltip-arrow {
      right: -4px;
    }

    &[data-popper-placement^="right"] > .tooltip-arrow {
      left: -4px;
    }
  }
}

// @keyframes pulse {
//   from {
//     stroke-width: 3px;
//     stroke-opacity: 1;
//     transform: scale(0.3);
//   }
//   to {
//     stroke-width: 0;
//     stroke-opacity: 0;
//     transform: scale(2);
//   }
// }
</style>

<template>
  <v-sheet class="pa-8" rounded="" elevation="2">
    <header class="d-flex mb-8">
      <h3>Seat Details</h3>
      <v-spacer></v-spacer>
      <v-btn transparent icon to="/seats"><v-icon>mdi-close</v-icon></v-btn>
    </header>

    <v-row>
      <v-col cols="6">
        <h4 class="text-h4">{{ selectedSeat.aircraft.name }}</h4>
        <small class="text-subtitle-2"
          >{{ selectedSeat.airline.name }} - Version
          {{ selectedSeat.aircraft.version }}</small
        >
        <v-chip>{{ selectedSeat.class.name }}</v-chip>
        <div class="mt-8">
          <div class="text-subtitle-2">System Rating</div>
          <v-avatar size="64" color="primary" class="white--text text-h6">
            {{ selectedSeat.ratings.system }}
          </v-avatar>
        </div>
      </v-col>
      <v-col cols="6">
        <h3 class="mb-4">Attributes Information</h3>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Value</th>
                <th>Rating</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(amenity, k) in selectedSeat.ratings.perAttribute"
                :key="`departure-amenity-${k}`"
              >
                <td>
                  <strong>{{ labelMap[k] }}</strong>
                </td>
                <td>
                  <template v-if="Array.isArray(amenity.value)">
                    {{ getUnique(amenity.value).join(" / ") }}
                    {{ unitMap[k] }}
                  </template>
                  <template v-else>
                    {{ amenity.value }} {{ unitMap[k] }}
                  </template>
                </td>
                <td>{{ amenity.rating }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <h3 class="mt-4 mb-4">Seat Media</h3>
    <flight-card-carousel
      :height="450"
      :slides="seatMedia"
    ></flight-card-carousel>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import FlightCardCarousel from "../FlightCardCarousel";

export default {
  name: "SeatDetails",
  components: {
    FlightCardCarousel,
  },
  data() {
    return {
      labelMap: {
        seatWidth: "Seat Width",
        seatPitch: "Seat Pitch",
        seatPrivacy: "Seat Privacy",
        seatRecline: "Seat Recline",
      },
      unitMap: {
        seatWidth: "inches",
        seatPitch: "inches",
        seatPrivacy: "",
        seatRecline: "",
      },
    };
  },
  computed: {
    ...mapState("seats", ["seats"]),
    ...mapState("route", ["params"]),
    selectedSeat() {
      if (!this.seats) {
        return null;
      }
      return this.seats.find((s) => s.id === this.params.id);
    },
    seatMedia() {
      if (!this.selectedSeat) {
        return [];
      }

      let media = [];

      if (
        this.selectedSeat.media.pictures &&
        this.selectedSeat.media.pictures.length > 0
      ) {
        this.selectedSeat.media.pictures.forEach((p) => {
          media.push({
            type: "img",
            src: p,
          });
        });
      }
      if (this.selectedSeat.media.video) {
        media.push({
          type: "video",
          src: this.selectedSeat.media.video,
        });
      }

      return media;
    },
  },
};
</script>

<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      :size="80"
    ></v-progress-circular>
    <template v-if="!loading && Object.keys(filtersWithCosts).length > 0">
      <template v-for="lever in leversComponents">
        <v-card
          v-if="lever.isVisible && lever.key !== 'mixedCabin'"
          :key="lever.key"
          class="mb-6"
        >
          <v-card-title class="pb-1"
            >{{ lever.label }}
            <v-progress-circular
              v-if="loadingCosts"
              :size="24"
              :width="3"
              color="primary"
              indeterminate
              class="ml-4"
            ></v-progress-circular>
          </v-card-title>
          <p
            v-if="getLeverSummary(lever) && !loadingCosts"
            class="text-caption mb-1 mx-4"
          >
            <v-chip label small>{{ getLeverSummary(lever) }}</v-chip>
          </p>
          <p
            v-if="lever.description"
            class="text-caption mb-n1 mx-4 grey--text text-lighten-4"
            v-html="lever.description"
          ></p>
          <v-card-text>
            <template v-if="lever.key === 'cabins'">
              <filter-switch lever-key="mixedCabin"></filter-switch>
            </template>
            <component
              :is="lever.component"
              v-if="lever.isVisible"
              :lever-key="lever.key"
              :hide-empty="lever.hideEmpty"
            ></component>
            <v-alert v-if="!lever.isAllowed" text dense type="warning">
              <template v-if="!user">
                This option is only available for members.
              </template>
              <template v-else>
                This option is only available for a higher membership plan.
              </template>
            </v-alert>
          </v-card-text>
        </v-card>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import userHasPermission from "../modules/userHasPermission";
import { comparator } from "../modules/helpers";
import FilterSlider from "./Filters/FilterSlider";
import FilterRange from "./Filters/FilterRange";
import FilterSwitch from "./Filters/FilterSwitch";
import FilterCheckboxes from "./Filters/FilterCheckboxes";

export default {
  name: "UpgradeLevers",
  components: {
    FilterSwitch,
    FilterRange,
    FilterSlider,
    FilterCheckboxes,
  },
  computed: {
    leversComponents() {
      if (!this.filtersWithCosts) {
        return [];
      }

      const map = {
        quadrants: FilterCheckboxes,
        price: FilterRange,
        destinations: FilterCheckboxes,
        dateFlexibility: FilterCheckboxes,
        alliances: FilterCheckboxes,
        airlines: FilterCheckboxes,
        cabins: FilterCheckboxes,
      };

      // console.log(this.filters);

      return Object.keys(this.filtersWithCosts).map((k) => {
        const l = this.filtersWithCosts[k];
        // console.log(k, l);
        let obj = {
          key: k,
          label: l.label,
          description: l.description,
          upgradeCost:
            l.type !== "boolean" && l.upgradeCost && !this.hideSignals
              ? l.upgradeCost
              : null,
          component: map[k],
          isAllowed: true,
          isVisible:
            k === "price"
              ? this.offersWithRatings.length > 0
              : this.checkCondition(l),
          hideEmpty: ["airlines", "alliances"].includes(k),
        };

        if (typeof l.permissions !== "undefined" && l.permissions.length > 0) {
          if (!this.user) {
            obj.isAllowed = false;
          } else {
            obj.isAllowed = l.permissions.every((v) => {
              const allowed = userHasPermission(v);
              // console.log(v, allowed);
              return allowed;
            });
          }
        }

        return obj;
      });
    },
    // ...mapState("offers", ["leversLoading", "leversValues"]),
    ...mapState("filters", ["loading", "loadingCosts", "hideSignals"]),
    ...mapGetters("offers", ["allowedLevers", "offersWithRatings"]),
    ...mapGetters("filters", ["filtersWithCosts"]),
    ...mapGetters("profile", ["user"]),
  },
  methods: {
    checkCondition(lever) {
      if (
        typeof lever.conditions === "undefined" ||
        lever.conditions.length === 0
      ) {
        return true;
      }

      let evaluated = [];

      lever.conditions.forEach((c) => {
        const leverSettings = this.leversValues[c.key];

        let values = leverSettings.value;

        if (leverSettings.type === "boolean") {
          values = typeof values !== "boolean" ? values === "true" : values;
        }

        if (leverSettings.type === "number") {
          values = parseInt(values);
        }

        evaluated.push(comparator(values, c.compare, c.value));
      });

      return evaluated.filter((v) => v === false).length <= 0;
    },
    getLeverSummary(lever) {
      if (!lever.upgradeCost) {
        return null;
      }

      if (typeof lever.upgradeCost !== "object") {
        const cost =
          lever.upgradeCost < 0
            ? `-$${Math.abs(lever.upgradeCost)}`
            : `$${lever.upgradeCost}`;
        return `Upgrades up to ${cost}`;
      }

      const min =
        lever.upgradeCost.min < 0
          ? `-$${Math.floor(Math.abs(lever.upgradeCost.min))}`
          : `$${Math.floor(lever.upgradeCost.min)}`;
      const max =
        lever.upgradeCost.max < 0
          ? `-$${Math.ceil(Math.abs(lever.upgradeCost.max))}`
          : `$${Math.ceil(lever.upgradeCost.max)}`;
      return lever.upgradeCost.min == lever.upgradeCost.max
        ? `Upgrades at ${min}`
        : `Upgrades from ${min} to ${max}`;
    },
  },
};
</script>

<template>
  <div>
    <v-switch
      v-model="currentLever"
      :label="currentLeverDetails.description"
      :disabled="checkIfDisabled(currentLeverDetails)"
    ></v-switch>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import userHasPermission from "../../modules/userHasPermission";

export default {
  name: "FilterSwitch",
  props: {
    leverKey: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentLeverDetails() {
      if (!this.filtersWithCosts || !this.filtersWithCosts[this.leverKey]) {
        return null;
      }

      return this.filtersWithCosts[this.leverKey];
    },
    currentLever: {
      get() {
        if (!this.filtersWithCosts || !this.filtersWithCosts[this.leverKey]) {
          return null;
        }
        return this.filtersWithCosts[this.leverKey].selected;
      },
      set(val) {
        // console.log("setLever", val);
        this.setFilter([
          {
            key: this.leverKey,
            value: val,
          },
        ]);
      },
    },
    ...mapGetters("filters", ["filtersWithCosts"]),
    ...mapState("profile", ["user"]),
  },
  methods: {
    ...mapActions("filters", ["setFilter"]),
    ...mapActions("profile", ["userHasPermission"]),
    checkIfDisabled(option) {
      if (
        typeof option.permissions === "undefined" ||
        option.permissions.length === 0
      ) {
        return false;
      }

      const isAllowed = this.user
        ? option.permissions.every((v) => userHasPermission(v))
        : false;

      return !isAllowed;
    },
  },
};
</script>

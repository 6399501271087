import jsonexport from "jsonexport/dist";
import { format } from "date-fns";

/**
 * Turns json data into a CSV file and trigger a download.
 * @since v0.9.9
 *
 * @param {Array|Object} json a json array or object.
 */
export const convert2CSV = (json) => {
  // remove some unrelevant data from offers object and format some numbers.
  const parsed = JSON.parse(JSON.stringify(json)).map((o) => {
    if (typeof o.outbound.aircraft.cabin !== "undefined") {
      delete o.outbound.aircraft.cabin;
    }
    if (typeof o.inbound.aircraft.cabin !== "undefined") {
      delete o.inbound.aircraft.cabin;
    }
    delete o.earnings;

    Object.keys(o.outbound.ratings).forEach((k) => {
      if (k === "random") {
        return;
      }
      o.outbound.ratings[k] = o.outbound.ratings[k].toFixed(2);
    });
    Object.keys(o.inbound.ratings).forEach((k) => {
      if (k === "random") {
        return;
      }
      o.inbound.ratings[k] = o.inbound.ratings[k].toFixed(2);
    });

    o.outbound.rating = o.outbound.rating.toFixed(2);
    o.outbound.userRating = o.outbound.userRating.toFixed(2);
    o.outbound.cabinRating = o.outbound.cabinRating.toFixed(2);
    o.inbound.rating = o.inbound.rating.toFixed(2);
    o.inbound.userRating = o.inbound.userRating.toFixed(2);
    o.inbound.cabinRating = o.inbound.cabinRating.toFixed(2);

    o.offerValue = o.offerValue.toFixed(2);
    o.cabinRating = o.cabinRating.toFixed(2);
    o.costPerHour = o.costPerHour.toFixed(2);
    o.cost.amount = o.cost.amount.toFixed(2);
    o.upgradeCost = o.upgradeCost.toFixed(2);
    o.upgradeBenefit.total = o.upgradeBenefit.total.toFixed(2);
    o.upgradeBenefit.percent = o.upgradeBenefit.percent.toFixed(2);

    return o;
  });

  jsonexport(
    parsed,
    {
      fillGaps: true,
    },
    (err, csv) => {
      if (err) return console.error(err);
      downloadCSV(csv);
    }
  );
};

/**
 * Given a csv string, generates a link to download the blob, and triggers the download.
 *
 * @since v0.9.9
 *
 * @param {String} csv a string of text representing the contents of a .csv file.
 */
export const downloadCSV = (csv) => {
  const today = format(new Date(), "MMM d, Y");
  const name = `Upgrade Engine Offers - ${today}.csv`;
  let link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
  );
  link.setAttribute("download", name);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

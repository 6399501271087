<template>
  <v-dialog v-model="policiesDialog" max-width="760" persistent>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Airline Policies</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="closePoliciesDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          :size="80"
        ></v-progress-circular>
        <div v-if="!loading && currentPolicy" class="d-flex align-center my-8">
          <v-avatar size="62" elevation="2">
            <img :src="currentPolicy.logo" />
          </v-avatar>
          <div class="ml-4">
            <h2 class="text-h4">{{ currentPolicy.name }}</h2>
          </div>
        </div>
        <template v-if="currentPolicy">
          <v-tabs v-model="activeTab">
            <v-tab v-for="(item, i) in tabs" :key="`policies-${i}`">{{
              item
            }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item v-if="currentPolicy.policies.hold" class="mt-4">
              <v-simple-table>
                <template #default>
                  <tbody>
                    <tr>
                      <th>Policy Link</th>
                      <td>
                        <v-btn
                          color="primary"
                          :href="currentPolicy.policies.hold.url"
                          target="_blank"
                          >Open Airline Website</v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Policy Option Name</th>
                      <td>{{ currentPolicy.policies.hold.optionName }}</td>
                    </tr>
                    <tr>
                      <th>Fare Hold Option Name</th>
                      <td>{{ currentPolicy.policies.hold.optionName }},</td>
                    </tr>
                    <tr>
                      <th>Fare Hold Time</th>
                      <td>{{ currentPolicy.policies.hold.time }},</td>
                    </tr>
                    <tr>
                      <th>Fare Hold Cost</th>
                      <td>{{ currentPolicy.policies.hold.cost }},</td>
                    </tr>
                    <tr>
                      <th>Fare Hold Comment</th>
                      <td>{{ currentPolicy.policies.hold.comments }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
            <v-tab-item v-if="currentPolicy.policies.change" class="mt-4">
              <v-simple-table>
                <template #default>
                  <tbody>
                    <tr>
                      <th>Policy Link</th>
                      <td>
                        <v-btn
                          v-for="(link, i) in currentPolicy.policies.change
                            .policyUrls.fares"
                          :key="`airline-change-link-${i}`"
                          color="primary"
                          :href="link.url"
                          target="_blank"
                          >Open Airline Website</v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Risk of Buying with Cash and Paying Change/Cancel Fees
                      </th>
                      <td>{{ currentPolicy.policies.change.buyCashRisk }}</td>
                    </tr>
                    <tr>
                      <th>Current Policy for New Bookings Applies Until</th>
                      <td>
                        {{
                          formatDate(
                            currentPolicy.policies.change.policyExpiration
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Cancellation Restrictions</th>
                      <td>
                        {{
                          currentPolicy.policies.change.restrictionsCancellation
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Change Restrictions</th>
                      <td>
                        {{ currentPolicy.policies.change.restrictionsChange }}
                      </td>
                    </tr>
                    <tr>
                      <th>Positives</th>
                      <td>{{ currentPolicy.policies.change.positives }}</td>
                    </tr>
                    <tr>
                      <th>Negatives</th>
                      <td>{{ currentPolicy.policies.change.negatives }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { format, parseISO } from "date-fns";

export default {
  name: "AirlinePoliciesDialog",
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapState("policies", ["loading", "currentPolicy", "policiesDialog"]),
    tabs() {
      let tabs = [];
      if (!this.currentPolicy) {
        return tabs;
      }

      if (typeof this.currentPolicy.policies.hold !== "undefined") {
        tabs.push("Fare Hold Policies");
      }

      if (typeof this.currentPolicy.policies.change !== "undefined") {
        tabs.push("Fare Change Policies");
      }

      return tabs;
    },
  },
  methods: {
    ...mapActions("policies", ["closePoliciesDialog"]),
    formatDate(date) {
      if (!date) {
        return "N/A";
      }
      return format(parseISO(date), "MMM d, Y");
    },
  },
};
</script>

const mutations = {
  SET_ORIGIN(state, val) {
    state.origin = val;
  },
  SET_DESTINATION(state, val) {
    state.destination = val;
  },
  SET_DEPARTURE_DATE(state, val) {
    state.departureDate = val;
  },
  SET_RETURN_DATE(state, val) {
    state.returnDate = val;
  },
  SET_AIRLINE(state, val) {
    state.airline = val;
  },
  SET_FLIGHT_CLASS(state, val) {
    state.flightClass = val;
  },
  SET_STEP(state, val) {
    state.step = val;
  },
  SET_RATING_PRESETS(state, val) {
    state.ratingPresets = val;
  },
  SET_ATTRIBUTE_WEIGHT(state, payload) {
    state.attributeWeights[payload.direction][payload.key] = payload.val;
  },
  SET_MENU_VIEW(state, view) {
    state.currentMenuView = view;
  },
  SET_SEATS(state, seats) {
    state.seats = seats;
  }
};

export default mutations;

<template>
  <v-app>
    <app-bar v-if="routeName !== 'auth' && routeName !== 'lp'"></app-bar>
    <v-main class="blue-grey lighten-5">
      <v-container
        v-if="!['auth', 'lp'].includes(routeName)"
        class="pa-4 d-flex align-center"
      >
        <bread-crumbs
          v-if="!['auth', 'lp', 'seats', 'seatDetails'].includes(routeName)"
        ></bread-crumbs>
        <v-spacer></v-spacer>
        <v-btn icon @click="openFlagsUI">
          <v-icon>mdi-flag</v-icon>
        </v-btn>
      </v-container>
      <router-view></router-view>
      <offer-dialog v-if="routeName !== 'auth'"></offer-dialog>
      <airline-policies-dialog></airline-policies-dialog>
    </v-main>
    <feature-flags></feature-flags>
  </v-app>
</template>

<script>
// import BaselineInfo from "./BaselineInfo";
import BreadCrumbs from "./BreadCrumbs";
import OfferDialog from "./OfferDialog";
import AirlinePoliciesDialog from "./AirlinePoliciesDialog";
import AppBar from "./AppBar";
import FeatureFlags from "./FeatureFlags";
import { mapState, mapActions } from "vuex";
import emitter from "../modules/emitter";

export default {
  name: "App",
  components: {
    // BaselineInfo,
    BreadCrumbs,
    OfferDialog,
    AirlinePoliciesDialog,
    AppBar,
    FeatureFlags,
  },
  computed: {
    ...mapState("offers", ["offers"]),
    ...mapState("flights", ["baseline"]),
    ...mapState("route", {
      fullPath: (state) => state.fullPath,
      routeName: (state) => state.name,
    }),
    allOffersAreSet() {
      return (
        Array.isArray(this.offers) &&
        this.offers.length > 0 &&
        this.baseline.outbound &&
        this.baseline.inbound
      );
    },
  },
  watch: {
    allOffersAreSet() {
      if (this.$route.query.viewOffer) {
        this.viewOfferDetails(this.$route.query.viewOffer);
      }
    },
    fullPath() {
      this.setCurrentState();
    },
  },
  async created() {
    await this.initializeAuth();
    this.setCurrentState();
    this.setRatingPresets();
    // this.getSeats();
    this.getPlans();
    this.getAlliances();

    emitter.on("kb/getArticle", (id) => {
      this.setMenuView("kb");
      this.getArticle(id);
    });
  },
  methods: {
    ...mapActions([
      "setCurrentState",
      "setRatingPresets",
      "setMenuView",
      "getSeats",
    ]),
    ...mapActions("offers", ["viewOfferDetails"]),
    ...mapActions("session", ["initializeAuth"]),
    ...mapActions("billing", ["setup"]),
    ...mapActions("profile", ["setManager"]),
    ...mapActions("kb", ["getArticle"]),
    ...mapActions("airlinePlans", ["getPlans"]),
    ...mapActions("flags", ["setFlag"]),
    ...mapActions("filters", ["getAlliances"]),
    openFlagsUI() {
      this.setFlag({
        flag: "isActive",
        value: true,
      });
    },
  },
};
</script>

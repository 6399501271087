<template>
  <v-btn-toggle v-model="tabType">
    <v-tooltip top color="grey darken-4">
      <template #activator="{ on, attrs }">
        <v-btn value="chart" v-bind="attrs" v-on="on">
          <v-icon>mdi-chart-scatter-plot</v-icon>
        </v-btn>
      </template>
      <span>Landscape View</span>
    </v-tooltip>
    <v-tooltip top color="grey darken-4">
      <template #activator="{ on, attrs }">
        <v-btn value="list" v-bind="attrs" v-on="on">
          <v-icon>mdi-format-list-checkbox</v-icon>
        </v-btn>
      </template>
      <span>Grid View</span>
    </v-tooltip>
    <v-tooltip top color="grey darken-4">
      <template #activator="{ on, attrs }">
        <v-btn value="top10" v-bind="attrs" v-on="on">
          <v-icon>mdi-trophy-variant</v-icon>
        </v-btn>
      </template>
      <span>Top 10 Upgrades</span>
    </v-tooltip>
    <v-btn v-if="enableRequestLogger" value="raw">
      <v-icon>mdi-code-braces</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "OffersListTabs",
  data() {
    return {
      keyListener: null,
    };
  },
  computed: {
    tabType: {
      get() {
        return this.viewType;
      },
      set(val) {
        this.setViewType(val);
      },
    },
    ...mapState("offers", ["viewType"]),
    ...mapState("flags", ["enableRequestLogger"]),
  },
  mounted() {
    this.keyListener = function (e) {
      console.log(
        "keyListener",
        e.key,
        e.altKey,
        e.key === "k",
        e.altKey,
        e.key === "k" && e.altKey,
        e
      );
      if (e.key === "k" && (e.ctrlKey || e.altKey)) {
        console.log("alt + k");
        e.preventDefault(); // present "Save Page" from getting triggered.
        this.setViewType("raw");
      }
    };

    document.addEventListener("keydown", this.keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyListener.bind(this));
  },
  methods: {
    ...mapActions("offers", ["setViewType"]),
  },
};
</script>

<template>
  <div>
    <v-tabs v-model="tab" grow>
      <v-tabs-slider color="blue"></v-tabs-slider>

      <v-tab
        v-for="(item, key) in tabs"
        :key="key"
        :disabled="key === 'return' && !baseline.outbound && disableTabs"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, key) in tabs" :key="key">
        <v-alert v-if="disabledProfiles" text dense prominent type="warning">
          <v-row align="center">
            <v-col class="grow">
              You need to be a member to set your rating profiles.
            </v-col>
            <v-col class="shrink">
              <v-btn dark @click="register">Sign Up</v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-list>
          <template v-if="!disabledProfiles">
            <v-list-item
              v-if="
                !filteredProfiles[currentTabId].id && profileView === 'default'
              "
            >
              <v-row>
                <v-col md="7">
                  <v-btn
                    elevation="2"
                    dense
                    color="primary"
                    @click="profileView = 'save'"
                    >Save as...</v-btn
                  >
                </v-col>
                <v-col v-if="user" md="5">
                  <v-select
                    v-if="user && key === 'departure'"
                    v-model="selectedDepartureProfile"
                    label="Select Profile"
                    :items="ratingProfileGroups"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                  <v-select
                    v-if="user && key === 'return'"
                    v-model="selectedReturnProfile"
                    label="Select Profile"
                    :items="ratingProfileGroups"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item
              v-if="filteredProfiles[key].id && profileView === 'default'"
            >
              <v-row>
                <v-col md="7" class="d-flex align-center">
                  <h3>{{ currentProfiles[key].name }}</h3>
                  <v-btn
                    v-if="
                      typeof filteredProfiles[key].readonly === 'undefined' ||
                      !filteredProfiles[key].readonly
                    "
                    icon
                    color="primary"
                    class="ml-2"
                    title="Edit existing profile"
                    @click="profileView = 'save'"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn
                    color="primary"
                    small
                    class="ml-2"
                    title="Create new rating profile"
                    @click="clearSelectedProfile"
                    >New</v-btn
                  >
                </v-col>
                <v-col md="5">
                  <v-select
                    v-if="user && key === 'departure'"
                    v-model="selectedDepartureProfile"
                    label="Select Profile"
                    :items="ratingProfileGroups"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                  <v-select
                    v-if="user && key === 'return'"
                    v-model="selectedReturnProfile"
                    label="Select Profile"
                    :items="ratingProfileGroups"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item v-if="profileView === 'save'">
              <v-row>
                <v-col class="d-flex align-top">
                  <v-btn
                    dense
                    icon
                    class="ml-1"
                    @click="profileView = 'default'"
                    ><v-icon>mdi-arrow-left</v-icon></v-btn
                  >
                  <v-text-field
                    v-model="filteredProfiles[key].name"
                    outlined
                    dense
                    class="ml-a"
                    label="Profile Name"
                  ></v-text-field>
                  <v-btn
                    dense
                    color="primary"
                    icon
                    class="ml-2"
                    title="Save Profile"
                    @click="saveProfile"
                    ><v-icon>mdi-content-save</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    class="ml-2"
                    color="red"
                    title="Delete Profile"
                    @click="deleteSelectedProfile"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(attribute, id) in filteredProfiles[key].weights"
            :key="`${key}=${id}`"
          >
            <v-list-item-content>
              <v-list-item-title>{{ attributeLabels[id] }}</v-list-item-title>
              <v-slider
                :value="attribute"
                track-color="blue-grey lighten-4"
                min="1"
                max="10"
                step="1"
                dense
                hide-details
                ticks="always"
                :tick-labels="sliderTicks"
                :disabled="
                  (filteredProfiles[key].id && profileView === 'default') ||
                  disabledProfiles
                "
                @change="setWeight($event, id)"
              ></v-slider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
    <join-dialog :is-open="joinOpen" @close="joinOpen = false"></join-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import JoinDialog from "./JoinDialog";
// import userHasPermission from "../modules/userHasPermission.js";

export default {
  name: "RatingProfiles",
  components: { JoinDialog },
  props: {
    disableTabs: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      joinOpen: false,
      tab: 0,
      tabs: {
        departure: "Outbound Flight",
        return: "Return Flight",
      },
      currentProfiles: {
        departure: {
          id: null,
          name: null,
          weights: this.$store.state.attributeWeights.departure,
        },
        return: {
          id: null,
          name: null,
          weights: this.$store.state.attributeWeights.return,
        },
      },
      profileView: "default",
      sliderTicks: [
        "Not Important",
        null,
        null,
        null,
        "Somewhat Important",
        null,
        null,
        null,
        null,
        "Very Important",
      ],
    };
  },
  computed: {
    ...mapState([
      "attributeWeights",
      "attributeLabels",
      "step",
      "ratingPresets",
    ]),
    ...mapState("flights", ["baseline"]),
    ...mapState("profile", ["user", "updatingProfile"]),
    ...mapGetters(["parsedAttributeWeights"]),
    ...mapGetters("profile", [
      "currentProfileDeparture",
      "currentProfileReturn",
      "ratingProfileGroups",
    ]),
    ...mapGetters("session", ["userPermissions"]),
    disabledProfiles() {
      if (!this.user) {
        return true;
      }

      if (!this.permissions) {
        return false;
      }
      return this.permissions.includes("create:rating_profile");
    },
    filteredProfiles() {
      const profiles = { ...this.currentProfiles };

      profiles.departure.weights = Object.keys(
        this.parsedAttributeWeights.departure
      ).reduce((prev, current) => {
        prev[current] =
          typeof profiles.departure.weights[current] !== "undefined"
            ? profiles.departure.weights[current]
            : this.parsedAttributeWeights.departure[current];

        return prev;
      }, {});

      profiles.return.weights = Object.keys(
        this.parsedAttributeWeights.return
      ).reduce((prev, current) => {
        prev[current] =
          typeof profiles.return.weights[current] !== "undefined"
            ? profiles.return.weights[current]
            : this.parsedAttributeWeights.return[current];

        return prev;
      }, {});

      return profiles;
    },
    currentSelectedProfiles() {
      return this.user && this.user.user_metadata.selected_rating_profiles
        ? this.user.user_metadata.selected_rating_profiles
        : {
            departure: null,
            return: null,
          };
    },
    selectedDepartureProfile: {
      get() {
        if (this.user && this.user.user_metadata.selected_rating_profiles) {
          console.log(
            "selected_rating_profiles",
            this.user.user_metadata.selected_rating_profiles
          );
          console.log("ratingProfileGroups", this.ratingProfileGroups);
          const index = this.ratingProfileGroups.findIndex((g) => {
            console.log(
              g,
              g.id,
              this.user.user_metadata.selected_rating_profiles.departure
            );
            return (
              typeof g.id !== "undefined" &&
              g.id ===
                this.user.user_metadata.selected_rating_profiles.departure
            );
          });
          console.log("index", index);

          if (index !== -1) {
            console.log("profile", this.ratingProfileGroups[index]);
            return this.ratingProfileGroups[index];
          }
        }
        return null;
      },
      set(val) {
        this.setRatingsProfile({
          id: val,
          direction: "departure",
        });
      },
    },
    selectedReturnProfile: {
      get() {
        if (this.user && this.user.user_metadata.selected_rating_profiles) {
          const index = this.ratingProfileGroups.findIndex(
            (g) =>
              typeof g.id !== "undefined" &&
              g.id === this.user.user_metadata.selected_rating_profiles.return
          );

          if (index !== -1) {
            return this.ratingProfileGroups[index];
          }
        }
        return null;
      },
      set(val) {
        this.setRatingsProfile({
          id: val,
          direction: "return",
        });
      },
    },
    currentProfile() {
      if (!this.currentSelectedProfiles) {
        return null;
      }
      return this.currentSelectedProfiles[this.currentTabId];
    },
    currentTabId() {
      return Object.keys(this.tabs)[this.tab];
    },
  },
  watch: {
    step() {
      if (this.step === "departure") {
        this.tab = 0;
      }
      if (this.step === "return") {
        this.tab = 1;
      }
    },
    currentProfileDeparture() {
      if (!this.currentProfileDeparture) {
        this.currentProfiles.departure = {
          id: null,
          name: null,
          weights: this.$store.state.attributeWeights.departure,
        };
      } else {
        this.currentProfiles.departure = { ...this.currentProfileDeparture };
      }
    },
    currentProfileReturn() {
      if (!this.currentProfileReturn) {
        this.currentProfiles.return = {
          id: null,
          name: null,
          weights: this.$store.state.attributeWeights.return,
        };
      } else {
        this.currentProfiles.return = { ...this.currentProfileReturn };
      }
    },
    updatingProfile() {
      if (!this.updatingProfile && this.currentProfiles[this.currentTabId].id) {
        this.profileView = "default";
      }
    },
  },
  created() {
    if (this.currentProfileDeparture) {
      this.currentProfiles.departure = { ...this.currentProfileDeparture };
    }
    if (this.currentProfileReturn) {
      this.currentProfiles.return = { ...this.currentProfileReturn };
    }
  },
  methods: {
    setWeight(val, key) {
      console.log("setWeight");
      this.currentProfiles[this.currentTabId].weights[key] = val;
      this.setAttributeWeight({
        direction: this.currentTabId,
        key: key,
        val: val,
      });
    },
    ...mapActions("profile", [
      "saveRatingsProfile",
      "clearRatingsProfile",
      "setRatingsProfile",
      "deleteRatingsProfile",
    ]),
    ...mapActions(["setAttributeWeight"]),
    ...mapActions("session", ["loginWithPopup"]),
    register() {
      this.loginWithPopup(true);
    },
    saveProfile() {
      console.log(
        "saveProfile",
        this.currentProfiles,
        this.currentTabId,
        this.currentProfiles[this.currentTabId]
      );
      this.saveRatingsProfile({
        profile: this.currentProfiles[this.currentTabId],
        direction: this.currentTabId,
      });
    },
    deleteSelectedProfile() {
      this.deleteRatingsProfile(
        this.currentSelectedProfiles[this.currentTabId]
      );

      this.currentProfiles[this.currentTabId] = {
        id: null,
        name: null,
        weights: this.$store.state.attributeWeights[this.currentTabId],
      };

      this.profileView = "default";
    },
    clearSelectedProfile() {
      this.currentProfiles[this.currentTabId] = {
        id: null,
        name: null,
        weights: this.$store.state.attributeWeights.departure,
      };

      this.currentSelectedProfiles[this.currentTabId] = null;

      this.clearRatingsProfile(this.currentTabId);
    },
  },
};
</script>

<style lang="scss">
.v-slider--horizontal {
  min-height: 48px;
  .v-slider__tick {
    .v-slider__tick-label {
      font-size: 0.8em !important;
      opacity: 0.7 !important;
      white-space: normal;
    }
    &:last-child {
      .v-slider__tick-label {
        text-align: right;
      }
    }
  }
}
</style>

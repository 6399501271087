<template>
  <v-container class="pa-8">
    <v-alert
      v-if="selectedOfferWithRatings.id !== baselineOffer.id"
      :type="alertType"
      prominent
      text
      ><span v-html="upgradeMessage"></span
    ></v-alert>
    <header class="d-flex mb-4">
      <h2 class="">Offer Summary</h2>
      <v-spacer></v-spacer>
      <div class="mr-8">
        <h5>Offer Price:</h5>
        <div class="text-h5">
          ${{ Math.round(selectedOfferWithRatings.cost) }}
        </div>
      </div>
      <div class="text-center mr-8">
        <h5>Overall Rating:</h5>
        <v-avatar color="primary" size="56">
          <span class="white--text text-h6">{{
            selectedOfferWithRatings.userRating
          }}</span>
        </v-avatar>
      </div>
      <div class="text-right">
        <h5>Cost to upgrade:</h5>
        <div class="text-h4">
          {{
            saveOrAdd === "save"
              ? `-$${Math.abs(upgradeCost)}`
              : `+$${upgradeCost}`
          }}
        </div>
      </div>
    </header>
    <v-row>
      <v-col>
        <v-card class="blue-grey lighten-5">
          <v-card-subtitle class="pb-0">
            <strong>Outbound Flight</strong>
          </v-card-subtitle>
          <v-card-title class="text-h4 pt-0 pb-0">
            {{ selectedOfferWithRatings.outbound.carrier.code }}-{{
              selectedOfferWithRatings.outbound.number
            }}
            <v-spacer></v-spacer>
            <v-avatar color="grey darken-1" size="48">
              <span class="white--text text-body-1">{{
                selectedOfferWithRatings.outbound.aircraft.cabin.userRating
              }}</span>
            </v-avatar>
          </v-card-title>
          <v-card-text>
            <div
              v-if="
                selectedOfferWithRatings.outbound.carrier.code !==
                selectedOfferWithRatings.outbound.operating.code
              "
              class="d-block mb-2"
            >
              Operated by {{ selectedOfferWithRatings.outbound.operating.code }}
            </div>
            <p class="mb-2">
              {{ formatDate(selectedOfferWithRatings.outbound.departure.at) }}
            </p>
            <v-chip small label class="grey darken-2" dark>{{
              selectedOfferWithRatings.outbound.aircraft.cabin.cabinClass
            }}</v-chip>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="blue-grey lighten-5">
          <v-card-subtitle class="pb-0">
            <strong>Return Flight</strong>
          </v-card-subtitle>
          <v-card-title class="text-h4 pt-0 pb-0">
            {{ selectedOfferWithRatings.inbound.carrier.code }}-{{
              selectedOfferWithRatings.inbound.number
            }}
            <v-spacer></v-spacer>
            <v-avatar color="grey darken-1" size="48">
              <span class="white--text text-body-1">{{
                selectedOfferWithRatings.inbound.aircraft.cabin.userRating
              }}</span>
            </v-avatar>
          </v-card-title>
          <v-card-text>
            <div
              v-if="
                selectedOfferWithRatings.inbound.carrier.code !==
                selectedOfferWithRatings.inbound.operating.code
              "
              class="d-block mb-2"
            >
              Operated by {{ selectedOfferWithRatings.inbound.operating.code }}
            </div>
            <p class="mb-2">
              {{ formatDate(selectedOfferWithRatings.inbound.departure.at) }}
            </p>
            <v-chip small label class="grey darken-2" dark>{{
              selectedOfferWithRatings.inbound.aircraft.cabin.cabinClass
            }}</v-chip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <h3 class="mt-8 mb-4">Booking options:</h3>
    <v-list class="d-block mt-4 blue-grey lighten-5" width="100%" elevation="2">
      <v-list-item v-for="(link, key) in offer.booking" :key="key">
        <v-list-item-content>
          <v-list-item-title
            v-text="
              `Book your Flights on
          ${link.provider}`
            "
          ></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn color="primary" large :href="link.url" target="_blank">
            Book Now
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { format, parseISO } from "date-fns";

export default {
  name: "BookingInfo",
  props: {
    baseline: {
      type: Object,
      default: () => {},
    },
    offer: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("offers", ["offersWithRatings", "selectedOfferWithRatings"]),
    baselineOffer() {
      return this.offersWithRatings.find((o) => o.isBaseline);
    },
    upgradeCost() {
      if (!this.selectedOfferWithRatings || !this.baselineOffer) {
        return null;
      }
      const cost =
        this.selectedOfferWithRatings.cost - this.baselineOffer.cost.amount;
      return Math.round(cost);
    },
    upgradeRating() {
      if (!this.selectedOfferWithRatings) {
        return null;
      }
      const cost =
        this.selectedOfferWithRatings.userRating -
        this.baselineOffer.userRating;
      return cost;
    },
    saveOrAdd() {
      if (this.upgradeCost) {
        return this.upgradeCost > 0 ? "add" : "save";
      }
      return null;
    },
    betterOrWorse() {
      if (this.upgradeRating) {
        return this.upgradeRating > 0 ? "better" : "worse";
      }
      return null;
    },
    alertType() {
      if (this.saveOrAdd === "add" && this.betterOrWorse == "worse") {
        return "error";
      }
      return "success";
    },
    alertIcon() {
      if (this.saveOrAdd === "add" && this.betterOrWorse == "worse") {
        return "mdi-alert-octagon-outline";
      }
      return "mdi-check";
    },
    upgradeMessage() {
      if (!this.selectedOfferWithRatings) {
        return null;
      }

      if (this.saveOrAdd === "save" && this.betterOrWorse == "better") {
        return `Congratulations! With this offer, you can save <strong>$${Math.round(
          Math.abs(this.upgradeCost)
        )}</strong> and get a <strong>${Math.abs(
          Number(this.upgradeRating.toFixed(2))
        )} ${
          this.upgradeRating > 1 ? "points" : "point"
        }</strong> more comfortable seat!`;
      }

      if (this.saveOrAdd === "save" && this.betterOrWorse == "worse") {
        return `Congratulations! With this offer, which is less comfortable, you can save <strong>$${Math.round(
          Math.abs(this.upgradeCost)
        )}</strong>!`;
      }

      if (this.saveOrAdd === "add" && this.betterOrWorse == "better") {
        return `Congratulations! With this offer, by paying <strong>$${Math.round(
          Math.abs(this.upgradeCost)
        )} more</strong>, you can get a <strong>${Math.abs(
          Number(this.upgradeRating.toFixed(2))
        )} ${
          this.upgradeRating > 1 ? "points" : "point"
        }</strong> more comfortable seat!`;
      }

      if (this.saveOrAdd === "add" && this.betterOrWorse == "worse") {
        return `This is <strong>not a better deal</strong> compared to your baseline offer.`;
      }

      return null;
    },
  },
  methods: {
    getBaselineName(offer) {
      return `${offer.outbound.carrier.code}-${offer.outbound.flight} / ${offer.inbound.carrier.code}-${offer.inbound.flight}`;
    },
    getOfferName(offer) {
      return `${offer.outbound.carrier.code}-${offer.outbound.number} / ${offer.inbound.carrier.code}-${offer.inbound.number}`;
    },
    formatDate(date) {
      return format(parseISO(date), "MMM d, Y p");
    },
  },
};
</script>

import axios from "axios";
import auth0Client from "./authClient";
import { nanoid } from "nanoid";
import store from "../store";
import qs from "qs";
import { getServerUrl, getCurrentServer } from "../servers";

const api = axios.create({
  baseURL: `${window.location.origin}/api/v1`,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer null`
  },
});

let cancelTokens = {
  flights: [],
  flight: [],
  offers: [],
  offersSignals: [],
  getMilesEarned: [],
  exportOffers: [],
};

// const sourceUrls = {
//   // flights: "http://159.69.207.61/api/",
//   // offers: 'https://upggradeengine-env.VITE_eba-utpezc53.ap-southeast-1.elasticbeanstalk.com'
//   flights:
//     window.origin.indexOf("regression") !== -1 ||
//     window.origin.indexOf("localhost") !== -1
//       ? import.meta.env.VITE_UE_OFFERS_TEST_URL
//       : window.origin.indexOf("staging") !== -1
//       ? import.meta.env.VITE_UE_OFFERS_STAGING_URL
//       : import.meta.env.VITE_UE_OFFERS_PROD_URL,
//   offers:
//     window.origin.indexOf("regression") !== -1 ||
//     window.origin.indexOf("localhost") !== -1
//       ? import.meta.env.VITE_UE_OFFERS_TEST_URL
//       : window.origin.indexOf("staging") !== -1
//       ? import.meta.env.VITE_UE_OFFERS_STAGING_URL
//       : import.meta.env.VITE_UE_OFFERS_PROD_URL,
// };

const sourceUrl = getServerUrl();

export default {
  setCancelToken(type, token) {
    cancelTokens[type].push(token);
  },

  abortAll(type) {
    if (typeof cancelTokens[type] === "undefined") {
      return;
    }
    cancelTokens[type].forEach((token) => {
      token.cancel("Aborted");
    });
  },

  isAborted(error) {
    console.log("axios.isCancel", axios.isCancel(error));
    return axios.isCancel(error);
  },

  async getUser() {
    const token = await auth0Client.getApiToken();
    return api.get(`/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getRatingProfiles() {
    const token = await auth0Client.getApiToken();
    return api.get(`/rating-profiles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async updateUser(metadata) {
    const token = await auth0Client.getApiToken();
    return api.post(`/me`, metadata, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async clearUserRoles() {
    const token = await auth0Client.getApiToken();
    return api.delete(`/me/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async updateUserRole(role) {
    const token = await auth0Client.getApiToken();
    return api.post(
      `/me/roles`,
      {
        roles: [role],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  async createAccount(params) {
    const token = await auth0Client.getApiToken();
    return api.post("/me/account", params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async addBillingInfo(tempToken) {
    const token = await auth0Client.getApiToken();

    const billingInfoUpdate = {
      // firstName: payload.firstName,
      // lastName: payload.lastName,
      token: tempToken,
    };

    return api.post("/me/account/billing", billingInfoUpdate, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async addSubscription(plan) {
    const token = await auth0Client.getApiToken();

    let subscriptionReq = {
      plan: plan,
    };

    return api.post("/me/subscription", subscriptionReq, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getFlights(data) {
    const token = await auth0Client.getApiToken();
    const endpoint = `${sourceUrl}flights`;
    let params = {
      DepartureDate: data.departureDate,
      ReturnDate: data.returnDate || null,
      OutboundDate: data.outboundDate || null,
      OriginLocation: data.origin,
      DestinationLocation: data.destination,
      Class: data.flightClass,
      Airline: data.airline,
      Context: data.userContext,
      TransactionId: nanoid(),
    };

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    this.setCancelToken("flights", source);

    console.log("getFlights", data, params);

    store.dispatch("logs/setLog", {
      type: "request",
      origin: "/flights",
      payload: `${endpoint}?${qs.stringify(params)}`,
    });

    return api.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    });
  },

  async getFlight(uids, userContext) {
    const token = await auth0Client.getApiToken();
    let endpoint = `${sourceUrl}flight`;
    const server = getCurrentServer();
    let params = uids.map((id) => `uids=${id}`);

    if (userContext) {
      params.push(`Context=${userContext}`);
    }

    params.push(`TransactionId=${nanoid()}`);

    endpoint = `${endpoint}?${params.join("&")}`;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    this.setCancelToken("flight", source);

    console.log("getFlight", endpoint);

    store.dispatch("logs/setLog", {
      type: "request",
      origin: "/flight",
      payload: endpoint,
    });

    let headers = {
      Authorization: `Bearer ${token}`,
    };

    // workaround to get the right Postman mock responses
    if (server === "mock") {
      if (uids.length > 0) {
        headers["x-mock-response-name"] = "Get multiple flight data";
      } else {
        const mapping = {
          "b125ddca-0b50-4c3c-a5a1-6cddd5dddfb1": "Get departure flight data",
          "198bb584-8ead-4e11-be87-e9996d4ab1fa": "Get Return Flight data",
        };

        headers["x-mock-response-name"] = mapping[uids[0]];
      }
    }

    return api.get(endpoint, {
      headers,
    });
  },

  // async getFlight(uids) {
  //   const token = await auth0Client.getApiToken();
  //   const parsedIds = uids.map(i => `uid[]=${i}`).join("&");
  //   const endpoint = `http://159.69.207.61/api/flight?${parsedIds}`;

  //   return api.get(endpoint, {
  //     // params: {
  //     //   uid: uids.join(",")
  //     // },
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   });
  // },

  async getOffers(params) {
    const token = await auth0Client.getApiToken();

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    this.setCancelToken("offers", source);
    const uid = nanoid();

    // const endpoint = "/offers";
    const baseURL = `${sourceUrl}offers/v1/Offers`;

    const parsed = Object.keys(params)
      .map((k) => {
        const p = params[k];
        if (Array.isArray(p)) {
          return p.map((v) => `${k}=${v}`).join("&");
        } else {
          return `${k}=${p}`;
        }
      })
      .join("&");

    const endpoint = `${baseURL}?${parsed}&transactionId=${uid}`;

    store.dispatch("logs/setLog", {
      type: "request",
      origin: "/offers",
      payload: endpoint,
    });

    return api.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      // httpsAgent: new https.Agent({
      //   rejectUnauthorized: false
      // }),
      cancelToken: source.token,
    });
  },

  async getOffersSignals(params) {
    console.log("getOffersSignals", params);
    const token = await auth0Client.getApiToken();
    // const baseURL = "/offers/signals";
    const baseURL = `${sourceUrl}offers/v1/Signals`;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    this.setCancelToken("offersSignals", source);

    // add transactionId parameter
    const uid = nanoid();

    const parsed = Object.keys(params)
      .map((k) => {
        const p = params[k];
        if (Array.isArray(p)) {
          return p.map((v) => `${k}=${v}`).join("&");
        } else {
          return `${k}=${p}`;
        }
      })
      .join("&");

    const endpoint = `${baseURL}?${parsed}&transactionId=${uid}`;

    store.dispatch("logs/setLog", {
      type: "request",
      origin: "/signals",
      payload: endpoint,
    });

    return api.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      cancelToken: source.token,
    });
  },

  async getOffer(uids, context) {
    const token = await auth0Client.getApiToken();
    const baseURL = `${sourceUrl}offers/v1/Offer`;
    const uid = nanoid();

    let params;

    if (Array.isArray(uids)) {
      params = uids.map((i) => `uids=${i}`).join("&");
    } else {
      params = `uids=${uids}`;
    }

    const endpoint = `${baseURL}?${params}&context=${context}&transactionId=${uid}`;

    store.dispatch("logs/setLog", {
      type: "request",
      origin: "/offer",
      payload: endpoint,
    });

    return api.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      // httpsAgent: new https.Agent({
      //   rejectUnauthorized: false,
      // }),
    });
  },

  async getOfferBookingLink(params) {
    const token = await auth0Client.getApiToken();
    const endpoint = "/offer/booking";

    return api.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getKbArchive() {
    // const token = await auth0Client.getApiToken();
    const endpoint = "https://ue-kb.firstclassflyer.com/wp-json/ue/v1/kb";

    return api.get(endpoint, {
      headers: {
        "X-API-KEY": import.meta.env.VITE_KB_API_TOKEN,
        // Authorization: `Bearer ${token}`,
      },
    });
  },

  async getKbArticle(id) {
    // const token = await auth0Client.getApiToken();
    const endpoint = `https://ue-kb.firstclassflyer.com/wp-json/ue/v1/kb?ids=${id}`;

    return api.get(endpoint, {
      headers: {
        "X-API-KEY": import.meta.env.VITE_KB_API_TOKEN,
        // Authorization: `Bearer ${token}`,
      },
    });
  },

  async getAirlines() {
    const token = await auth0Client.getApiToken();
    const endpoint = "/airlines";

    return api.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getAirlinesPolicies(iata) {
    const token = await auth0Client.getApiToken();
    const endpoint = "/airlines/policies";

    return api.get(endpoint, {
      params: {
        airline: iata,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getAirlinesPlans(iata) {
    const token = await auth0Client.getApiToken();
    const endpoint = "/airlines/plans";

    return api.get(endpoint, {
      params: {
        airline: iata,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getCities(s) {
    const token = await auth0Client.getApiToken();
    const endpoint = "/locations";

    return api.get(endpoint, {
      params: {
        iata: s,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getSeats(params) {
    const token = await auth0Client.getApiToken();
    let endpoint = "/seats";

    if (params && typeof params.noCache !== "undefined" && params.noCache) {
      endpoint += "?noCache";
    }

    return api.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getCabinRules() {
    const token = await auth0Client.getApiToken();
    const endpoint = "/cabin-rules";

    return api.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async getMilesEarned(body) {
    // console.log("getMilesEarned", body);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    this.setCancelToken("getMilesEarned", source);
    const token = await auth0Client.getApiToken();

    const endpoint = "/offers/earnings";

    store.dispatch("logs/setLog", {
      type: "request",
      origin: "/offers/earnings",
      payload: endpoint,
    });

    return api.post(endpoint, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    });
  },
};

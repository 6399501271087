<template>
  <div>
    <v-expansion-panels
      v-if="Array.isArray(parsedInstructions)"
      v-model="panel"
    >
      <v-expansion-panel
        v-for="(row, i) in parsedInstructions"
        :key="`row-${i}`"
      >
        <v-expansion-panel-header> Step {{ i + 1 }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="row"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      v-if="!Array.isArray(parsedInstructions)"
      v-html="parsedInstructions"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TicketInstructions",
  props: {
    instructions: {
      type: [Array, String],
      default: "",
    },
  },
  data() {
    return {
      panel: 0,
    };
  },
  computed: {
    parsedInstructions() {
      if (this.instructions && this.instructions.indexOf("\n-") !== -1) {
        return this.instructions
          .replace(/(\r\n|\r|\n)/g, "<br>")
          .split("<br>")
          .filter((r) => r !== "")
          .map((r) => r.replace("- ", ""));
      }
      return this.instructions;
    },
  },
};
</script>

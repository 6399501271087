<template>
  <tr
    :class="[
      item.isBaseline ? 'yellow lighten-4 active' : '',
      item.shouldHide ? 'red lighten-5' : '',
    ]"
  >
    <td v-for="col in offersColumnsKeys" :key="`col-${col}-${currentId}`">
      <template v-if="col === 'select'">
        <slot name="row-selector" :item="item">
          <!-- <input
                  v-if="!item.isBaseline"
                  v-model="shortlist"
                  type="checkbox"
                  :value="item.id"
                /> -->
        </slot>
      </template>
      <template v-if="col === 'flights'">
        <v-chip
          v-if="!item.isBaseline && item.shouldHide"
          label
          color="red"
          dark
          small
          class="mt-2"
          >HIDDEN WHEN APPLY FILTERS IS ON</v-chip
        >
        <div class="d-flex my-2">
          <div class="outbound">
            <h4 class="text-h6">
              {{ item.outbound.carrier.code }}-{{ item.outbound.flight }}
            </h4>
            <h5 class="text-caption mb-1 mt-n1">
              Operated by {{ item.outbound.operating.code }}
            </h5>
            <v-chip color="blue lighten-4" small>{{
              formatClass(item.outbound.class)
            }}</v-chip>
          </div>
          <div class="divider mx-4 mt-1">
            <v-icon small style="transform: rotate(90deg)">mdi-airplane</v-icon>
          </div>
          <div class="inbound">
            <h4 class="text-h6">
              {{ item.inbound.carrier.code }}-{{ item.inbound.flight }}
            </h4>
            <h5 class="text-caption mb-1 mt-n1">
              Operated by {{ item.inbound.operating.code }}
            </h5>
            <v-chip color="blue lighten-4" small>{{
              formatClass(item.inbound.class)
            }}</v-chip>
          </div>
        </div>
        <div
          v-if="item.codeshared && showGrouped"
          class="codeshared text-caption my-4"
          style="max-width: 200px"
        >
          {{ getCodesharedString(item.codeshared) }}
        </div>
      </template>
      <template v-if="col === 'outbound.departure.at'">
        <div class="text-subtitle-1 font-weight-bold">
          {{ item.outbound.departure.code }} to
          {{ item.outbound.arrival.code }}
        </div>
        {{ formatDate(item.outbound.departure.at) }}<br />
        <div class="text-caption mt-2">
          {{ formatDuration(item.outbound.duration) }}
        </div>
      </template>
      <template v-if="col === 'inbound.departure.at'">
        <div class="text-subtitle-1 font-weight-bold">
          {{ item.inbound.departure.code }} to
          {{ item.inbound.arrival.code }}
        </div>
        {{ formatDate(item.inbound.arrival.at) }}<br />
        <div class="text-caption mt-2">
          {{ formatDuration(item.inbound.duration) }}
        </div>
      </template>
      <template v-if="col === 'rating'">
        <v-chip>
          {{ item.rating }}
        </v-chip>
      </template>
      <template v-if="col === 'userRating'">
        <v-chip>
          {{ item.userRating }}
        </v-chip>
      </template>
      <template v-if="col === 'cabinRating'">
        <v-chip>
          {{ item.cabinRating.toFixed(2) }}
        </v-chip>
      </template>
      <template v-if="col === 'cost.amount'">
        <template
          v-if="
            item.isBaseline &&
            offerPricePaid &&
            currentBaseline.cost.amount !== offerPricePaid
          "
        >
          <span class="red--text text-decoration-line-through"
            >${{ Math.round(currentBaseline.cost.amount) }}</span
          >
        </template>
        ${{ Math.round(item.cost.amount) }}
      </template>
      <template v-if="col === 'costPerHour'"
        >${{ item.costPerHour }}/h</template
      >
      <template v-if="col === 'offerValue'"
        >{{ Math.round(item.offerValue) }}/pt</template
      >
      <template v-if="col === 'upgradeCost'">
        <span
          v-if="!item.isBaseline"
          :class="
            item.upgradeCost < 0
              ? 'green--text text--darken-2'
              : 'red--text text--darken-2'
          "
          v-text="
            item.upgradeCost < 0
              ? `-$${Math.abs(Math.round(item.upgradeCost))} LESS`
              : `$${Math.round(item.upgradeCost)} MORE`
          "
        ></span>
      </template>
      <template v-if="col === 'quadrant'">
        <span v-if="!item.isBaseline">
          {{ quadrantName }}
        </span>
      </template>
      <template v-if="col === 'upgradeBenefit.total'">
        <span
          v-if="item.upgradeBenefit && !item.isBaseline"
          :class="
            item.upgradeBenefit.total < 0
              ? 'red--text text--darken-2'
              : 'green--text text--darken-2'
          "
          v-text="
            item.upgradeBenefit.total < 0
              ? `${Math.round(item.upgradeBenefit.percent)}% WORSE`
              : `${Math.round(item.upgradeBenefit.percent)}% BETTER`
          "
        ></span>
      </template>
      <template v-if="col === 'actions'">
        <slot name="actions" :item="item">
          <!-- <v-btn
                v-if="!item.isBaseline"
                color="white"
                dense
                small
                @click.stop="setBaseline(item.id)"
                >Set Baseline</v-btn
              >
              <v-chip
                v-if="item.isBaseline"
                label
                small
                color="light-blue lighten-4 mb-1"
                >Baseline</v-chip
              >
              <v-btn
                color="primary"
                dense
                small
                elevation="2"
                @click.stop="viewOfferDetails(item.id)"
                >Details</v-btn
              > -->
        </slot>
      </template>
    </td>
  </tr>
</template>

<script>
import { format, parseISO } from "date-fns";
import { formatClassName } from "../modules/helpers";
import { mapState } from "vuex";
import { nanoid } from "nanoid";

// import { mapState } from "vuex";

export default {
  name: "OffersPickerRow",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState("offers", ["offerPricePaid", "currentBaseline"]),
    ...mapState("filters", ["quadrants"]),
    ...mapState("flags", ["offersColumns"]),
    currentId() {
      return nanoid();
    },
    offersColumnsKeys() {
      return this.offersColumns.filter((c) => c.display).map((c) => c.key);
    },
    quadrantName() {
      if (!this.item) {
        return null;
      }
      const option = this.quadrants.options.find(
        (o) => o.value === this.item.quadrant
      );
      return option ? option.label : this.item.quadrant;
    },
  },
  methods: {
    formatDate(date) {
      return format(parseISO(date), "MMM d, Y p");
    },
    formatDuration(duration) {
      if (!duration) {
        return;
      }
      const durationRaw = duration
        .replace("PT", "")
        .replace("M", "")
        .split("H");

      return `${durationRaw[0]}h ${durationRaw[1]}min`;
    },
    getCodesharedString(codeshared) {
      let string = codeshared.map((c) => {
        let partnerString = c.name
          .split(" ")
          .map(
            (str) =>
              str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase()
          )
          .join(" ");
        if (c.diff < 0) {
          partnerString += ` (-$${Math.abs(Math.round(c.diff))})`;
        }
        if (c.diff > 0) {
          partnerString += ` (+$${Math.abs(Math.round(c.diff))})`;
        }

        return partnerString;
      });

      return `Ticket also sold by ${
        string.slice(0, -1).join(", ") + " & " + string.slice(-1)
      }`;
    },
    formatClass(className) {
      return formatClassName(className);
    },
  },
};
</script>

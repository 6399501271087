<template>
  <v-breadcrumbs :items="parsedSteps">
    <template #item="{ item }">
      <v-breadcrumbs-item
        :to="{
          path: item.path,
          query: query
        }"
        :disabled="item.isDisabled"
      >
        {{ item.label }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BreadCrumbs",
  props: {
    current: {
      default: null
    }
  },
  computed: {
    ...mapState(["steps"]),
    ...mapState("route", ["query", "path"]),
    stepKeys() {
      return this.steps.map(s => s.path);
    },
    currentStepIndex() {
      return this.stepKeys.indexOf(this.path);
    },
    parsedSteps() {
      const queryKeys = Object.keys(this.query);
      return this.steps.map(step => {
        let parsed = { ...step };

        const missingParams = step.requiredParams.reduce((prev, current) => {
          if (!queryKeys.includes(current)) {
            prev.push(current);
          }
          return prev;
        }, []);

        parsed.isDisabled =
          this.stepKeys.indexOf(parsed.path) > this.currentStepIndex &&
          missingParams.length > 0;

        return parsed;
      });
    }
  }
};
</script>

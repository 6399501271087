<template>
  <v-row>
    <v-col>
      <v-autocomplete
        v-model="origin"
        :items="origins"
        item-text="name"
        item-value="iata"
        :label="originLabel"
        clearable
        hide-details
        filled
      ></v-autocomplete>
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="destination"
        :items="destinations"
        item-text="name"
        item-value="iata"
        :label="destinationLabel"
        clearable
        hide-details
        filled
      ></v-autocomplete>
    </v-col>
    <v-col>
      <v-menu
        ref="outbound"
        v-model="departureDateString"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="departureDate"
            label="Departure Date"
            readonly
            v-bind="attrs"
            filled
            hide-details
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="departureDate"
          @input="departureDateString = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        ref="inbound"
        v-model="returnDateString"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="returnDate"
            label="Return Date"
            readonly
            v-bind="attrs"
            filled
            hide-details
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="returnDate"
          @input="returnDateString = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="airline"
        :items="airlines.options"
        item-text="label"
        item-value="value"
        label="Airline"
        filled
        hide-details
      ></v-autocomplete>
    </v-col>
    <v-col>
      <v-select
        v-model="flightClass"
        :items="classesOfService"
        item-text="name"
        item-value="value"
        label="Flight Class"
        filled
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { format } from "date-fns";
import { mapState, mapActions } from "vuex";

export default {
  name: "BaselineSelector",
  // props: {
  //   step: {
  //     default: "departure"
  //   }
  // },
  data() {
    return {
      // origin: this.$store.state.origin || "JFK",
      originLabel: "Search your origin",
      // destination: this.$store.state.destination || null,
      destinationLabel: "Search your destination",
      // departureDate:
      //   this.$route.query.departureDate || format(new Date(), "Y-MM-d"),
      departureDateString: null,
      // returnDate: this.$route.query.returnDate || format(new Date(), "Y-MM-d"),
      returnDateString: null,
      // flightClass: this.$route.query.flightClass || null
      routerFlag: false,
    };
  },
  computed: {
    ...mapState("flights", [
      "origins",
      "destinations",
      // "airlines",
      "classesOfService",
    ]),
    ...mapState("filters", ["airlines"]),
    origin: {
      get() {
        return this.$store.state.origin;
      },
      set(value) {
        this.$store.commit("SET_ORIGIN", value);
        if (this.routerFlag) {
          this.pushToQuery("origin");
        }
      },
    },
    destination: {
      get() {
        return this.$store.state.destination;
      },
      set(value) {
        this.$store.commit("SET_DESTINATION", value);
        if (this.routerFlag) {
          this.pushToQuery("destination");
        }
      },
    },
    departureDate: {
      get() {
        return this.$store.state.departureDate || format(new Date(), "Y-MM-d");
      },
      set(value) {
        this.$store.commit("SET_DEPARTURE_DATE", value);

        const returnDateStamp = new Date(this.returnDate).getTime();
        const returnDateArray = value.split("-");
        let departureDate = new Date(
          returnDateArray[0],
          returnDateArray[1] - 1,
          returnDateArray[2]
        );
        let returnDate = new Date(this.returnDate);

        if (returnDateStamp <= departureDate) {
          returnDate = departureDate.setDate(departureDate.getDate() + 7);
          this.$store.commit("SET_RETURN_DATE", format(returnDate, "Y-MM-dd"));
        }

        if (this.routerFlag) {
          this.pushToQuery("departureDate");
          if (returnDateStamp <= departureDate) {
            this.pushToQuery("returnDate");
          }
        }
      },
    },
    returnDate: {
      get() {
        return this.$store.state.returnDate || format(new Date(), "Y-MM-d");
      },
      set(value) {
        this.$store.commit("SET_RETURN_DATE", value);
        if (this.routerFlag) {
          this.pushToQuery("returnDate");
        }
      },
    },
    airline: {
      get() {
        return this.$store.state.airline;
      },
      set(value) {
        this.$store.commit("SET_AIRLINE", value);
        if (this.routerFlag) {
          this.pushToQuery("airline");
        }
      },
    },
    flightClass: {
      get() {
        return this.$store.state.flightClass;
      },
      set(value) {
        this.$store.commit("SET_FLIGHT_CLASS", value);
        if (this.routerFlag) {
          this.pushToQuery("flightClass");
        }
      },
    },
  },
  mounted() {
    if (this.$route.path === "/search" && !this.$route.query.origin) {
      this.origin = "MIA";
      this.pushToQuery("origin");
    }

    this.updateParams();
  },
  methods: {
    // setSelected() {
    //   this.$emit("selected", {
    //     origin: this.origin,
    //     destination: this.destination,
    //     departureDate: this.departureDate,
    //     returnDate: this.returnDate,
    //     class: this.flightClass
    //   });
    // },
    pushToQuery(param) {
      // console.log("pushToQuery");
      let query = { ...this.$route.query };
      query[param] = this[param];
      // console.log("query", query);
      this.$router.push({ path: "/search", query: query });
    },
    updateParams() {
      [
        "origin",
        "destination",
        "departureDate",
        "returnDate",
        "airline",
        "flightClass",
      ].forEach((p) => {
        if (typeof this.$route.query[p] !== "undefined") {
          this[p] = this.$route.query[p];
        }
      });
      this.routerFlag = true;
    },
    ...mapActions("flights", ["getFlights"]),
  },
};
</script>

<template>
  <v-card>
    <template v-if="flightMedia.length > 0">
      <flight-card-carousel :slides="flightMedia"></flight-card-carousel>
      <!-- <v-img
        class="white--text align-end"
        height="200px"
        :src="flightCabin.pictures[0]"
        gradient="to bottom, rgba(10,10,10,.2), rgba(10,10,10,.7)"
      >
      </v-img> -->
    </template>
    <div class="d-flex align-center px-4 py-2">
      <div>
        <div class="text-h4">{{ flight.carrier.code }} {{ flight.number }}</div>
        <small
          v-if="flight.carrier.code !== flight.operating.code"
          class="d-block mb-2"
          >Operated by {{ flight.operating.code }}</small
        >
        <template v-if="isUpgraded">
          <v-tooltip top color="#111">
            <template #activator="{ on }">
              <div class="d-flex align-center">
                <v-chip badge small color="green" dark v-on="on">{{
                  flightClass
                }}</v-chip>
                <small class="ml-2 green--text font-weight-bold text-uppercase"
                  >Upgraded</small
                >
              </div>
            </template>
            <div class="py-2" style="max-width: 250px">
              <h4 class="mb-2">
                Upgraded from {{ flight.aircraft.cabin.cabinClass }} to
                {{ flightClass }}
              </h4>
              <p>
                Due to your {{ upgradeInfo.plan }} membership, you should get a
                {{ upgradeInfo.benefit.type }}.
              </p>
              <ul>
                <li>
                  <strong>Timeframe:</strong>
                  {{ upgradeInfo.benefit.timeframe }}
                </li>
                <li>
                  <strong>Airline Benefit Definition:</strong>
                  {{ upgradeInfo.benefit.airlineDefinition }}
                </li>
              </ul>
            </div>
          </v-tooltip>
        </template>
        <template v-else>
          <v-chip badge small>{{ flightClass }}</v-chip>
        </template>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex">
        <div class="d-flex flex-column align-center text-center">
          <h4 class="text-caption">System Rating</h4>
          <v-avatar size="40" color="grey lighten-2" class="text-body-2">{{
            flightRating.system
          }}</v-avatar>
        </div>
        <div class="d-flex flex-column align-center text-center ml-2">
          <h4 class="text-caption">Your Rating</h4>
          <v-avatar size="40" color="grey lighten-2" class="text-body-2">{{
            flightRating.user
          }}</v-avatar>
        </div>
        <div class="d-flex flex-column align-center text-center ml-2">
          <h4 class="text-caption">Cabin Rating</h4>
          <v-avatar size="40" color="grey lighten-2" class="text-body-2">{{
            flightCabin.cabinRating.toFixed(2)
          }}</v-avatar>
        </div>
      </div>
    </div>
    <div v-if="flightAirline" class="d-flex px-6 py-3">
      <div>
        <v-avatar v-if="flightAirline.logo" size="32">
          <img :src="flightAirline.logo" />
        </v-avatar>
        <div>
          <v-chip v-if="flightCabin.isPlaceholder" x-small>Placeholder</v-chip>
        </div>
      </div>
      <div class="text-subtitle-1 ml-4">
        <div>{{ flightAirline.label }}</div>
        <v-btn
          color="primary"
          small
          @click="getAirlinePolicy(flightAirline.value)"
          >Airline Policies</v-btn
        >
      </div>
    </div>
    <v-card-text>
      <v-row>
        <v-col>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th>Departure</th>
                  <th>Aircraft</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ flightDate.date }} - {{ flightDate.time }}</td>
                  <td>
                    <div
                      v-if="flight.operating && flight.operating.code !== ''"
                    >
                      {{ flight.operating.code }}-{{ flight.aircraft.code }}
                    </div>
                    <div v-else>
                      {{ flight.carrier.code }}-{{ flight.aircraft.code }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex align-center mb-2">
            <h4>Amenity data:</h4>
            <v-spacer></v-spacer>
            <v-chip v-if="isRandomValues" color="red" dark small>random</v-chip>
          </div>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th>Attribute</th>
                  <th>Value</th>
                  <th>Rating</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(amenity, k) in flightAmenities"
                  :key="`departure-amenity-${k}`"
                >
                  <td>
                    <strong>{{ amenity.name }}</strong>
                  </td>
                  <td>
                    <template v-if="Array.isArray(amenity.value)">
                      {{ getUnique(amenity.value).join(" / ") }}
                      {{ amenity.unit }}
                    </template>
                    <template v-else>
                      {{ amenity.value }} {{ amenity.unit }}
                    </template>
                  </td>
                  <td>{{ amenity.rating }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { format, parseISO } from "date-fns";
import { mapActions, mapState } from "vuex";
import FlightCardCarousel from "./FlightCardCarousel";
import { formatClassName, cabinCodeToClass } from "../modules/helpers";

export default {
  name: "FlightCard",
  components: {
    FlightCardCarousel,
  },
  props: {
    flight: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(["attributeWeights", "attributeLabels"]),
    ...mapState("filters", ["airlines"]),
    allowedAmenities() {
      return Object.keys(this.attributeLabels);
    },
    flightClass() {
      if (!this.flight) {
        return null;
      }

      if (this.isUpgraded) {
        const [, , , code] = this.flightCabin.upgradedTo.split("-");

        console.log(this.flightCabin.upgradedTo, code);
        const newClass = cabinCodeToClass(code);
        return newClass;
      }

      return this.flight.aircraft.cabin.cabinClass;
      // return formatClassName(this.flight.aircraft.cabin.cabinClass);
    },
    flightRoute() {
      if (!this.flight) {
        return null;
      }
      return `${this.flight.departure.code}-${this.flight.arrival.code}`;
    },
    flightDate() {
      if (!this.flight) {
        return null;
      }
      return {
        date: format(parseISO(this.flight.departure.at), "MMM d, Y"),
        time: format(parseISO(this.flight.departure.at), "p"),
      };
    },
    flightCabin() {
      if (!this.flight) {
        return null;
      }

      return this.flight.aircraft.cabin;
    },
    isUpgraded() {
      if (!this.flightCabin) {
        return false;
      }

      return (
        typeof this.flightCabin.upgradedTo !== "undefined" &&
        this.flightCabin.upgradedTo !== null &&
        this.flightCabin.upgradedTo !== ""
      );
    },
    upgradeInfo() {
      if (!this.isUpgraded) {
        return null;
      }
      return this.flightCabin.upgradeInfo;
    },
    isRandomValues() {
      return this.flightCabin.random;
    },
    flightAirline() {
      if (!this.airlines) {
        return null;
      }

      return this.airlines.options.find(
        (a) => this.flight.carrier.code === a.value
      );
    },
    flightRating() {
      if (!this.flight) {
        return null;
      }

      return {
        system: this.flightCabin.rating,
        user: this.flightCabin.userRating,
      };
    },
    flightAmenities() {
      const order = Object.keys(this.attributeWeights.departure),
        amenities = [...this.flightCabin.amenities];

      amenities.sort((a, b) => {
        return order.indexOf(a.key) - order.indexOf(b.key);
      });

      return amenities.filter((a) => this.allowedAmenities.includes(a.key));
    },
    flightMedia() {
      if (!this.flightCabin) {
        return [];
      }

      let media = [];

      if (this.flightCabin.pictures && this.flightCabin.pictures.length > 0) {
        this.flightCabin.pictures.forEach((p) => {
          media.push({
            type: "img",
            src: p,
          });
        });
      }
      if (this.flightCabin.video) {
        media.push({
          type: "video",
          src: this.flightCabin.video,
        });
      }

      return media;
    },
  },
  methods: {
    getUnique(a) {
      return [...new Set(a)];
    },
    ...mapActions("policies", ["getAirlinePolicy"]),
  },
};
</script>

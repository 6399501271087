<template>
  <div>
    <div
      v-if="loading"
      class="d-flex align-items justify-center pa-10"
      style="height: 100%; width: 100%"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="80"
      ></v-progress-circular>
    </div>
    <template v-if="!loading && currentPolicy">
      <v-toolbar color="blue lighten-5" flat>
        <v-btn icon @click="setOfferDetailsView('details.formatted')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title
          >Change your ticket booked on
          {{ currentPolicy.name }}</v-toolbar-title
        >
        <template #extension>
          <v-tabs v-model="currentTab" align-with-title slider-color="white">
            <v-tab key="change.policy">Change Policy</v-tab>
            <v-tab
              v-if="currentPolicy.instructions.change"
              key="change.instructions"
              >Change Instructions</v-tab
            >
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="currentTab" class="pa-4">
        <v-tab-item key="change.policy">
          <ticket-change-policy
            :current-policy="currentPolicy"
          ></ticket-change-policy>
        </v-tab-item>
        <v-tab-item
          v-if="currentPolicy.instructions.change"
          key="change.instructions"
        >
          <ticket-instructions
            :instructions="currentPolicy.instructions.change"
          ></ticket-instructions>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import TicketChangePolicy from "./TicketChangePolicy";
import TicketInstructions from "./TicketInstructions";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "TicketCancelInfo",
  components: {
    TicketChangePolicy,
    TicketInstructions,
  },
  data() {
    return {
      tabs: ["change.policy", "change.instructions"],
    };
  },
  computed: {
    ...mapState("offers", ["offerDialogView", "currentSelectedOffer"]),
    ...mapState("policies", ["loading", "currentPolicy"]),
    ...mapGetters("offers", ["selectedOfferWithRatings"]),
    currentTab: {
      get() {
        return this.tabs.indexOf(this.offerDialogView);
      },
      set(key) {
        this.setOfferDetailsView(this.tabs[key]);
      },
    },
  },
  mounted() {
    this.getAirlinePolicy(this.currentSelectedOffer.outbound.carrier.code);
  },
  methods: {
    ...mapActions("offers", ["setOfferDetailsView"]),
    ...mapActions("policies", ["getAirlinePolicy"]),
  },
};
</script>

<template>
  <v-container fluid class="px-10 py-4">
    <v-row>
      <v-col class="d-flex align-center">
        <h1 class="text-h4 mb-6">Step 2: Configure your rating profiles</h1>
        <v-spacer></v-spacer>
        <v-btn x-large color="primary" @click="goToOffers">Get Offers</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-card elevation="2">
          <rating-profiles></rating-profiles>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-checkbox
          v-model="ticketIsBooked"
          label="I already booked this ticket"
        ></v-checkbox>
        <v-text-field
          v-show="ticketIsBooked"
          v-model="pricePaid"
          label="If you already have a ticket, how much did you paid?"
          prefix="$"
          filled
          hide-details
          type="number"
          class="mb-4"
        ></v-text-field>
        <v-row>
          <v-col v-if="baselineTrip" md="6">
            <h3 class="text-h5">Your Departure Flight</h3>
            <flight-card :flight="baselineTrip.outbound"></flight-card>
          </v-col>
          <v-col v-if="baselineTrip" md="6">
            <h3 class="text-h5">Your Return Flight</h3>
            <flight-card :flight="baselineTrip.inbound"></flight-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { format, parseISO } from "date-fns";
import { mapState, mapGetters, mapActions } from "vuex";

import RatingProfiles from "./RatingProfiles";
import FlightCard from "./FlightCard";

export default {
  name: "RatingProfilesStep",
  components: {
    RatingProfiles,
    FlightCard,
  },
  computed: {
    ...mapState(["flightClass", "attributeWeights", "attributeLabels"]),
    ...mapState("route", ["query"]),
    ...mapGetters("flights", ["baselineTrip"]),
    ...mapState("offers", ["offerPricePaid"]),
    ticketIsBooked: {
      get() {
        return this.offerPricePaid !== null;
      },
      set(val) {
        const value = val
          ? this.offerPricePaid
            ? this.offerPricePaid
            : 0
          : null;
        this.setOfferPricePaid(value);
      },
    },
    pricePaid: {
      get() {
        return this.offerPricePaid ? Number(this.offerPricePaid) : 0;
      },
      set(val) {
        this.setOfferPricePaid(val);
      },
    },
  },
  methods: {
    ...mapActions("offers", ["getOffers", "setOfferPricePaid"]),
    goToOffers() {
      this.$router.push({
        path: "/results",
        query: { ...this.query },
      });
      // this.getOffers();
    },
  },
};
</script>

<template>
  <v-container class="px-10 py-8">
    <h1 class="text-h4 mb-6">Seats Archive</h1>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      :size="80"
    ></v-progress-circular>
    <template v-if="!loading && groupedSeats">
      <v-row>
        <v-col cols="6">
          <v-row class="mb-4">
            <v-col>
              <v-autocomplete
                v-model="selectedAirlines"
                :items="filters.airlines"
                item-text="label"
                label="Airlines"
                multiple
                filled
                hide-details
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="cabinClasses"
                :items="filters.cabinClasses"
                item-text="label"
                label="Cabin Classes"
                multiple
                filled
                hide-details
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="selectedAircraft"
                :items="filters.aircraft"
                item-text="label"
                label="Aircraft"
                multiple
                filled
                hide-details
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <div
            v-for="(airline, iata) in groupedSeats"
            :key="iata"
            class="mb-10"
          >
            <h3 class="mb-4">{{ airline.label }}</h3>
            <v-expansion-panels
              v-if="
                airline.cabinClasses &&
                Object.keys(airline.cabinClasses).length > 0
              "
            >
              <v-expansion-panel
                v-for="(cabinClass, code) in airline.cabinClasses"
                :key="`${iata}-${code}`"
              >
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <h4>{{ cabinClass.label }}</h4>
                    <div class="ml-4 grey--text text--darken-1">
                      {{ cabinClass.seats.length }} Seats
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="seatHeaders"
                    :items="cabinClass.seats"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template #[`item.action`]="{ item }">
                      <v-btn color="primary" small :to="`/seats/${item.id}`"
                        >Details</v-btn
                      >
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
        <v-col cols="6">
          <seat-details v-if="params.id"></seat-details>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SeatDetails from "./SeatDetails";

export default {
  name: "SeatsArchive",
  components: {
    SeatDetails,
  },
  data() {
    return {
      seatHeaders: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Aircraft",
          value: "aircraft.name",
          sortable: true,
        },
        {
          text: "Aircraft Version",
          value: "aircraft.version",
          sortable: true,
        },
        {
          text: "Seat Rating",
          value: "ratings.system",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          align: "end",
        },
      ],
    };
  },
  computed: {
    ...mapState("seats", ["loading", "filterBy"]),
    ...mapState("route", ["params"]),
    ...mapGetters("seats", ["filteredSeats", "groupedSeats", "filters"]),
    selectedAirlines: {
      get() {
        const selected = this.filterBy.airlines;

        return this.filters.airlines.filter((f) => selected.includes(f.value));
      },
      set(val) {
        return this.setSeatFilter({
          key: "airlines",
          value: val,
        });
      },
    },
    cabinClasses: {
      get() {
        const selected = this.filterBy.cabinClasses;

        return this.filters.cabinClasses.filter((f) =>
          selected.includes(f.value)
        );
      },
      set(val) {
        return this.setSeatFilter({
          key: "cabinClasses",
          value: val,
        });
      },
    },
    selectedAircraft: {
      get() {
        const selected = this.filterBy.aircraft;

        return this.filters.aircraft.filter((f) => selected.includes(f.value));
      },
      set(val) {
        return this.setSeatFilter({
          key: "aircraft",
          value: val,
        });
      },
    },
  },
  mounted() {
    this.getSeats();
  },
  methods: {
    ...mapActions("seats", ["getSeats", "setSeatFilter"]),
  },
};
</script>

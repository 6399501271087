import api from "../api";

const state = {
  cache: {},
  currentPolicy: null,
  loading: false,
  policiesDialog: false,
};

const mutations = {
  SET_CACHE: (state, policy) => {
    state.cache[policy.iata] = policy;
  },
  SET_CURRENT_POLICY: (state, policy) => {
    state.currentPolicy = policy;
  },
  SET_DIALOG: (state, isOpen) => {
    state.policiesDialog = isOpen;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
};

const actions = {
  async getAirlinePolicy({ commit, state }, iata) {
    if (typeof state.cache[iata] !== "undefined") {
      commit("SET_CURRENT_POLICY", state.cache[iata]);
      return;
    }

    commit("SET_LOADING", true);

    try {
      const policies = await api.getAirlinesPolicies(iata);
      commit("SET_CACHE", policies.data);
      commit("SET_CURRENT_POLICY", state.cache[iata]);
    } catch (err) {
      commit("SET_ERROR", err);
    }
    commit("SET_LOADING", false);
  },
  openPoliciesDialog({ commit, dispatch }, iata) {
    commit("SET_DIALOG", true);
    dispatch("getAirlinePolicy", iata);
  },
  closePoliciesDialog({ commit }) {
    commit("SET_DIALOG", false);
    commit("SET_CURRENT_POLICY", null);
  },
};

export default {
  state,
  mutations,
  actions,
};

<template>
  <div>
    <h2
      v-if="!query.departureFlight && currentFlights.length > 0"
      class="text-h5 mb-2"
    >
      Choose an Outbound Flight
    </h2>
    <h2
      v-if="query.departureFlight && currentFlights.length > 0"
      class="text-h5 mb-2"
    >
      Choose a Return Flight
    </h2>
    <v-data-table
      v-if="parsedFlights.length > 0 && !error"
      :headers="headers"
      :items="parsedFlights"
      class="elevation-1"
    >
      <template #[`item.rating`]="{ item }">
        <v-chip>
          {{ item.rating }}
        </v-chip>
      </template>
      <template #[`item.userRating`]="{ item }">
        <v-chip>
          {{ item.userRating }}
        </v-chip>
      </template>
      <template #[`item.flightId`]="{ item }">
        <v-btn
          color="primary"
          elevation="2"
          dense
          @click="setFlight(item.flightId)"
        >
          Select
        </v-btn>
      </template>
      <template #[`item.departureString`]="{ item }">
        <span v-html="item.departureString"></span>
      </template>
      <template #[`item.arrivalString`]="{ item }">
        <span v-html="item.arrivalString"></span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import { mapState, mapActions, mapGetters } from "vuex";
import camelCase from "camelcase";

export default {
  name: "FlightPicker",
  data() {
    return {
      headers: [
        {
          text: "Flight",
          sortable: false,
          value: "flightCode",
        },
        {
          text: "Class",
          sortable: false,
          value: "class",
        },
        {
          text: "Departure",
          sortable: true,
          value: "departureString",
        },
        {
          text: "Arrival",
          sortable: true,
          value: "arrivalString",
        },
        {
          text: "",
          sortable: false,
          value: "flightId",
          align: "end",
        },
      ],
    };
  },
  computed: {
    parsedFlights() {
      if (this.currentFlights.length === 0) {
        return [];
      }

      return this.currentFlights.map((f) => {
        const flightClass = f.cabinClass;
        let flight = {
          flightId: f.id,
          departureString: `${f.departure.code}<br>${format(
            parseISO(f.departure.at),
            "MMM d, Y p"
          )}`,
          arrivalString: `${f.arrival.code}<br>${format(
            parseISO(f.arrival.at),
            "MMM d, Y p"
          )}`,
          flightCode: `${f.carrier.code}-${f.number}`,
          // rating: f.aircraft.cabins[this.flightClass.toUpperCase()].rating,
          // userRating:
          //   f.aircraft.cabins[this.flightClass.toUpperCase()].userRating,
          price: `$${f.cost}`,
          class: flightClass,
        };

        // f.aircraft.cabins[flightClass].amenities.forEach(a => {
        //   flight[camelCase(a.name)] = `${
        //     Array.isArray(a.value) ? a.value.join(" / ") : a.value
        //   }`;

        //   if (typeof a.unit !== "undefined") {
        //     flight[camelCase(a.name)] += ` ${a.unit}`;
        //   }
        // });

        return flight;
      });
    },
    step() {
      return this.query.departureFlight ? "return" : "departure";
    },
    ...mapState(["flightClass"]),
    ...mapState("flights", ["flights", "error"]),
    ...mapState("route", ["query"]),
    ...mapGetters("flights", ["currentFlights"]),
  },
  methods: {
    setFlight(id) {
      let query = {
        ...this.query,
      };
      if (this.step === "departure") {
        this.selectFlight({
          id: id,
          direction: "departure",
        });

        this.$store.commit("SET_STEP", "return");

        query["departureFlight"] = id;
        this.$router.push({
          path: "/search",
          query: query,
        });
        this.getFlights();
      } else {
        this.selectFlight({
          id: id,
          direction: "return",
        });

        query["returnFlight"] = id;

        this.$router.push({
          path: "/ratings",
          query: query,
        });
        // this.getOffers();
      }
    },
    ...mapActions("flights", ["getFlights", "selectFlight"]),
    ...mapActions("offers", ["getOffers"]),
  },
};
</script>

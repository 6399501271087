<template>
  <div>
    <baseline-picker></baseline-picker>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaselinePicker from "./BaselinePicker";
// import Paywall from "./Paywall";
// import JoinForm from "./JoinForm";

export default {
  name: "Home",
  components: {
    BaselinePicker,
    // Paywall,
    // JoinForm
  },
  computed: {
    ...mapState("session", ["isAuthenticated", "authLoading", "user"]),
  },
};
</script>
